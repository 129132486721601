<footer class="ot-footer">
    <div class="container">
        <h2 class="sr-only">OpenText footer</h2>
        <div class="ot-footer__company">
            <div class="ot-footer__copyright-powered">
                <p class="ot-footer__copyright mt-0 mb-2"><a href="https://www.opentext.com/about/copyright-information"
                       target="_blank">{{ "copyrightLabel" | translate }}</a> {{ "copyrightSentnce" | translate }} <span
                          class="text-nowrap">{{ "allRightsReserved" | translate }}</span></p>
            </div>
            <div class="ot-footer__legal-social">
                <div class="ot-footer__legal">
                    <ul class="list-unstyled mb-2">
                        <li><a href="https://www.opentext.com/about/privacy"
                               target="_blank"> {{ "privacyPolicy" | translate }}</a></li>
                        <li><a href="https://www.opentext.com/about/cookie-policy"
                               target="_blank"> {{ "cookiePolicy" | translate }}</a></li>
                        <li><a class="ot-sdk-show-settings">{{ "cookiePreferences" | translate }}</a></li>
                    </ul>
                </div>
                <ul class="ot-footer__social social list-inline mb-2">
                    <li class="list-inline-item">
                        <a class="social-icon social-icon-linkedin"
                           href="https://www.linkedin.com/company/opentext"
                           target="_blank">
                            <svg width="32"
                                 height="32"
                                 viewBox="0 0 36 36"
                                 fill="none"
                                 role="img"
                                 aria-hidden="true"
                                 focusable="false">
                                <path fill="currentColor"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M13.2755 12.7927V24.809H9.23871V12.7927H13.2755ZM13.5413 9.07547C13.5152 7.89735 12.6647 7 11.2837 7C9.90265 7 9 7.89735 9 9.07547C9 10.2294 9.87655 11.1526 11.2315 11.1526H11.2576C12.6647 11.1526 13.5413 10.2294 13.5413 9.07547ZM23.1862 12.5105C25.842 12.5105 27.8338 14.2284 27.8338 17.919V24.809H23.797V18.3803C23.797 16.7653 23.2133 15.6636 21.7511 15.6636C20.6358 15.6636 19.9719 16.4063 19.68 17.1239C19.5737 17.381 19.5476 17.7393 19.5476 18.0983V24.8092H15.5099C15.5099 24.8092 15.563 13.9206 15.5099 12.7929H19.5476V14.495C20.083 13.6769 21.0427 12.5105 23.1862 12.5105Z">
                                </path>
                            </svg>
                            <span class="sr-only">{{ "otLinkedIn" | translate }}</span>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="social-icon social-icon-twitter"
                           href="https://twitter.com/OpenText"
                           target="_blank">
                            <svg width="32"
                                 height="32"
                                 viewBox="0 0 36 36"
                                 fill="none"
                                 role="img"
                                 aria-hidden="true"
                                 focusable="false">
                                <path fill="currentColor"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.6512 15.3661L17.6124 14.7555C17.4971 13.1728 18.509 11.7273 20.1115 11.1665C20.7009 10.9671 21.7009 10.9423 22.354 11.1165C22.6105 11.1914 23.0976 11.4406 23.4435 11.6649L24.0717 12.0761L24.7636 11.8643C25.1483 11.7522 25.6612 11.5653 25.8918 11.4406C26.1095 11.3286 26.3014 11.2661 26.3014 11.3034C26.3014 11.5155 25.8272 12.2381 25.4306 12.6369C24.8918 13.1977 25.0459 13.2475 26.1354 12.8739C26.7885 12.6618 26.8014 12.6618 26.6732 12.8988C26.5966 13.0232 26.199 13.4596 25.7765 13.8584C25.0588 14.5436 25.0201 14.6186 25.0201 15.1916C25.0201 16.0765 24.5847 17.921 24.1483 18.9304C23.3411 20.8246 21.6115 22.7811 19.8808 23.7656C17.4454 25.1489 14.2028 25.4979 11.4732 24.6879C10.5636 24.4136 9 23.7158 9 23.5911C9 23.5538 9.47416 23.5038 10.0507 23.4913C11.2555 23.4664 12.4603 23.1426 13.4851 22.5693L14.178 22.1705L13.3828 21.9087C12.2555 21.5348 11.2426 20.675 10.9861 19.865C10.9096 19.6032 10.9354 19.5907 11.6531 19.5907L12.3967 19.5783L11.7684 19.2918C11.0249 18.9304 10.3459 18.3198 10.0129 17.6965C9.76939 17.2479 9.46124 16.1138 9.5507 16.0267C9.57655 15.9894 9.84593 16.064 10.1531 16.1639C11.0378 16.4753 11.1531 16.4006 10.6402 15.8771C9.67893 14.93 9.3847 13.5218 9.84593 12.1884L10.0636 11.5902L10.9096 12.4002C12.6402 14.0328 14.678 15.0049 17.01 15.2914L17.6512 15.3661Z">
                                </path>
                            </svg>
                            <span class="sr-only">{{ "otTwitter" | translate }}</span>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="social-icon social-icon-youtube"
                           href="https://www.youtube.com/user/opentextcorp"
                           target="_blank">
                            <svg width="32"
                                 height="32"
                                 viewBox="0 0 36 36"
                                 fill="none"
                                 role="img"
                                 aria-hidden="true"
                                 focusable="false">
                                <path fill="currentColor"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M27.8 14.1C27.8 14.1 27.604 12.692 27.005 12.072C26.319 11.339 25.559 11.263 25.13 11.221L25 11.207C22.203 11 18.005 11 18.005 11H17.995C17.995 11 13.797 11 10.999 11.207L10.872 11.22C10.442 11.263 9.682 11.338 8.995 12.072C8.395 12.692 8.2 14.101 8.2 14.101C8.2 14.101 8 15.755 8 17.409V18.959C8 20.613 8.2 22.267 8.2 22.267C8.2 22.267 8.395 23.675 8.995 24.295C9.627 24.971 10.421 25.069 10.929 25.131H10.93C11.034 25.144 11.124 25.155 11.2 25.169C12.8 25.326 18 25.375 18 25.375C18 25.375 22.203 25.369 25.001 25.162L25.131 25.148C25.56 25.105 26.32 25.029 27.005 24.295C27.605 23.675 27.8 22.267 27.8 22.267C27.8 22.267 28 20.613 28 18.959V17.409C28 15.755 27.8 14.101 27.8 14.101V14.1ZM15.934 15.096L15.935 20.838L21.338 17.978L15.934 15.096V15.096Z">
                                </path>
                            </svg>
                            <span class="sr-only">{{ "otYouTube" | translate }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>