<div class="container container-fluid">
    <div class="sub-header pt-2 mb-2">
        <div class="close-icon">
            <span><mat-icon class="icon-change">highlight_off</mat-icon></span>
            <span class="ml-3">{{'cartPopUpHeader' | translate}}</span>
        </div>
        <span class="close"> <app-button-group [buttonConfig]="buttonConfig"
                              class="btn-focus"
                              (emitClickEvent)="onButtonClick()">
            </app-button-group></span>
    </div>
    <!--<br /> <a class="empty_cart"
           (click)="redirectTo()">{{'availablepaymentoptionsArticle' | translate}}</a>-->
    <p class="sub-text">{{'cartPopUpMessage' | translate}}.</p>
</div>