<div class="container container-fluid container-lg container-md container-sm container-xl flx-clmn">

    <div class="wrap-main-1">

        <div class="wrap-main-inner">
            <a id="logoLink"
                class="wrap-main-inner-1"
                [href]="orcHome"
                title="{{'onlineRenewalCenter' | translate}} {{'home' | translate}}">
                <img src="../../assets/images/orc-logo.png"
                        alt="openText"
                        class="logo mt-3">
                <p class="custom-cls-header-title-lbl">{{'onlineRenewalCenter' | translate}}</p>
            </a>
        </div>

        <div class="wrap-main-inner" id="mainMenu">
            <div class="site-menu-menu">
                <ul class="menu-list-cls">
                    <li *ngFor="let item of headerMenuList; let i = index"
                        title="{{item.title | translate}}"
                        [ngClass]="selectedIndex == i ? 'menu-active-cls' : ''">
                        <p (click)="menuClick(item.name, i)">{{item.label | translate}}</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="wrap-main-inner" id="global-search-btn" 
            *ngIf="isLoggedIn && searchResult"
            (click)="showSearchbar()">
                <mat-icon class="custom-cls-wraper-4">search</mat-icon>
        </div>

        <div class="wrap-main-inner" id="loginMenu">
            
            <div mat-icon-button
                    [matMenuTriggerFor]="menu">
                <span class="wrap-main-inner-1 personal-menu">
                    <mat-icon class="custom-cls-wraper-6"
                                [class]="isImpersonating ? 'material-symbols-outlined' : ''">
                        {{isImpersonating ? 'supervisor_account' : 'person_outline'}}
                    </mat-icon>
                    <span class="wrap-icon-btn-cont">{{profileFrontLabel | translate}}
                    </span>
                </span>
            </div>

            <mat-menu #menu="matMenu"
                        yPosition="below"
                        xPosition="before"
                        [backdropClass]="isLoggedIn ? 'sg-vertical-sub-menu' : 'sg-vertical-sub-menu'">
                <span *ngIf="isLoggedIn">
                    <button mat-menu-item
                            *ngFor="let item of profileMenu"
                            [disabled]="item.isDisable"
                            (click)="profileMenuOptionSelect(item.name)">
                        <span>{{item.label | translate}}
                            {{item.label === 'stopImpersonating' ? varMenuItemLabel : ''}}</span>
                    </button>
                </span>
                <span *ngIf="!isLoggedIn">
                    <button mat-menu-item
                            *ngFor="let item of profileMenuPreLogin"
                            (click)="profileMenuOptionSelect(item.name)">
                        <span>{{item.label| translate}}</span>
                    </button>
                </span>
            </mat-menu>

            <ng-container *ngIf="isLoggedIn">
                <ng-container *ngFor="let button of postLogin; let i=index">
                    <app-button-group class="wrap-main-inner-1"
                                        (emitClickEvent)="onButtonClick($event)"
                                        [buttonConfig]="button">
                    </app-button-group>
                </ng-container>
            </ng-container>

        </div>
    </div>
</div>

<div class="container-fluid custom-cls-wraper-7"
     *ngIf="showSearch">
    <div class="container">
        <div [formGroup]="headForm"
             class="example-form"
             id="header-sro-dropdown">
            <app-form-fields class="custom-cls-wraper-8"
                             (searchInputValue)="getSearchInputValue($event)"
                             [isLoading]="isShowSpinner"
                             [formControlName]="'autoComplete'"
                             [fieldConfig]="autoCompleteField"
                             [stateGroups]="searchObject"
                             (selectedDropdown)="selectedDropdown($event)"></app-form-fields>
        </div>
    </div>
</div>

<div class="container container-fluid container-lg container-md container-sm container-xl flx-clmn"
     *ngIf="isLoggedIn">
    <app-breadcrumb class="mt-2 mb-2"></app-breadcrumb>

    <div *ngIf="showNotificationList.length > 0">
        <app-notification-banner *ngFor="let item of showNotificationList"
                                 class="width100"
                                 [bannerInfo]="item"
                                 (onButtonClick)="onNotificationButtonClick($event)"
                                 (onCloseClick)="onNotificatonCloseClick($event)">
        </app-notification-banner>
    </div>
    <div class="loader"
         *ngIf="(notificatioLoader && (displayNotificationHOME || displayNotificationPending || displayNotificationActive || displayNotificationHistory))">
        <mat-progress-spinner color="primary"
                              mode="indeterminate"
                              diameter="50">
        </mat-progress-spinner>
    </div>
</div>