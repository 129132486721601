import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs';
import { RenewalsService } from "src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service";
import { invokePendingSummaryAPI, pendingSummaryFetchAPISuccess } from './pending-summary.actions';
@Injectable()
export class PendingSummaryEffect {
    constructor(
        private actions$: Actions,
        private renewalService: RenewalsService,
        private store: Store
    ) { }

    loadAllSummaryList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(invokePendingSummaryAPI),
            // withLatestFrom(this.store.pipe(select(selectPendingSummaryList))),
            mergeMap((p) => {
                return this.renewalService
                    .summaryList(p.params)
                    .pipe(map((data) => pendingSummaryFetchAPISuccess({
                        pendingSummaryAll: data

                    })
                    ));
            })
        )
    );
}
