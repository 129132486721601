import { Component, Type } from "@angular/core";

export interface IComponent {
    componentName?: Type<Component>;
    data?: any; // data is any based on requirement
}

export class ComponentModel implements IComponent {
    constructor(public componentName?: any,
        public data?: any) { }

}