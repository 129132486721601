<div class="add-to-cart-page">
    <div class="container">
        <section class="section section-top section-trim-bottom">
            <div class="">
                <div class="row">
                    <div class="col">
                        <header class="section-header section-header-lg ml-0 mr-0">
                            <h1 class="section-header-title">
                                <!--Page title goes here-->
                                {{  "shoppingCart" | translate }}
                            </h1>
                        </header>
                    </div>
                </div>
            </div>
        </section>
        <div class="add-to-cart-page-table"
             *ngIf="cartList.length > 0 && !emptyCart">
            <app-custom-table [tableColumns]="cartSummaryTableColumns"
                              (delete)="rowDelete($event)"
                              (redirectTo)="getDetailsByRcId($event)"
                              [isLoading]="isLoading"
                              [tableData]="cartList"></app-custom-table>
            <!-- <br />
            <app-custom-table [tableColumns]="initialTableAddon"
                              [tableData]="consolidatedValues">
            </app-custom-table> -->
        </div>
        <div class="add-to-cart-page-btn"
             *ngIf="cartList.length > 0 && !emptyCart">
            <ng-container *ngFor="let btn of proceedCheckoutBtn">
                <app-button-group (emitClickEvent)="proceedCheckout($event)"
                                  [buttonConfig]="btn"></app-button-group>
            </ng-container>
        </div>
        <div class="contract-details-contact mt-2"
             *ngIf="emptyCart">
            <div>
                <p class="text-center formatted">{{ 'emptyCart' | translate }}</p>
            </div>
            <p class="mb-0 text-center">
                {{ 'emptyCartMessage1' | translate }}
                <a (click)="menuClick()"
                   class="empty_cart">
                    {{ 'pendingSummaryPage' | translate }}</a>
                {{'emptyCartMessage2' | translate}}
        </div>
    </div>
</div>
<div *ngIf="isLoading && !deleteAction">
    <app-spinner-overlay></app-spinner-overlay>
</div>