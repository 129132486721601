import { createAction, props } from "@ngrx/store";
import { Master, UserProfile } from "./user.state";

export const UpdateUserProfile = createAction(
    '[User Profile] Update User Profile'
);

export const UpdateUserProfileSuccess = createAction(
    '[User Profile] User Profile Success',
    props<{ userProfile: UserProfile }>()
);

export const masterGet = createAction(
    '[Master Get] API'
);

export const masterGetSuccess = createAction(
    '[Master Get] API Success',
    props<{ masters: Master }>()
);
