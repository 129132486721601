<div class="impersonate-user">
    <div class="impersonate-user-header">
        <h3 class="h4 heading-regular mb-4 mb-md-6 mb-lg-8">{{'impersonateUser' | translate}}</h3>
        <p>{{'impersonateUserInstruction' | translate}}</p>
    </div>
    <div class="impersonate-user-body mt-3">
        <label>{{'impersonateUserSelectTitle' | translate}} <span class="mandatory-symbol">* </span>
        </label>
        <div [formGroup]="impersonateUserForm">
            <app-form-fields
                (searchInputValue)="getSearchInputValue($event)"
                [formControlName]="'impersonateUser'"
                [fieldConfig]="impersonateUserConfig"
                [stateGroups]="contactList"
                (keypress)="keyPress($event)"
                (selectedDropdown)="selectedDropdown($event)">
            </app-form-fields>
        </div>
    </div>
    <div class="impersonate-user-footer mt-3">
        <ng-container *ngFor="let btn of impersonateUserPopupBtnGroup">
            <app-button-group 
                [buttonConfig]="btn"
				[isDisabled]="btn.usecase === 'submit' && isImpersonateUserSelected ? false : btn.usecase === 'cancel' ? false : true"
                (emitClickEvent)="selectImpersonateUser($event)">
            </app-button-group>
        </ng-container>
    </div>
</div>

<div *ngIf="isLoading">
    <app-spinner-overlay></app-spinner-overlay>
</div>