import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonGroupInterface } from '../interface/button-group.interface';
import { FormFieldsInterface } from '../interface/form-fields.interface';


@Component({
    template: ''
})

export class RequestAssistanceConstantData {
    submitTaxExemption: string = 'submit-tax-exemption';
    uploadPO: string = 'upload-PO';
    requestAssistance: string = 'request-assistance';
    allowedFileExtension: string[] = ["csv", "pdf", "doc", "xls", "jpeg", "jpg", "gif", "png"];
    invalidFileError: string = this.translate.instant("invalidFileError");
    maxFileSize: number = 5;
    poAmountMaxDigits: number = 10;
    poAmountMaxDecimals: number = 5;
    removeScheduleFlag = 1;
    removeScheduledBill: string = 'remove-scheduling-assistance';
    requestAssistanceOptionsLength = 5;
    firstNameConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('firstName'),
        cssClass: "po-amount gray-out",
        placeHolder: "First_Name",
        type: "textbox",
        disabled: true,
        required: true,
        errorMessage: this.translate.instant('firstName') + this.translate.instant("emptyMessage"),
    };
    lastNameConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('lastName'),
        cssClass: "po-amount gray-out",
        placeHolder: "Last_Name",
        type: "textbox",
        disabled: true,
        required: true,
        errorMessage: this.translate.instant('lastName') + this.translate.instant("emptyMessage"),
    };
    companytNameConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('companyName'),
        cssClass: "po-amount gray-out",
        placeHolder: "CompanyABC",
        type: "textbox",
        disabled: true,
        // required: true,
        // errorMessage: this.translate.instant('companyName') + this.translate.instant("emptyMessage"),
    };
    emailConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('emailAddress'),
        cssClass: "po-amount gray-out",
        placeHolder: "FirstLast@opentext.com",
        type: "email",
        disabled: true,
        required: true,
        errorMessage: this.translate.instant('emailAddress') + this.translate.instant("emptyMessage"),
    };


    renewalContractConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('renewalContract'),
        cssClass: "po-amount gray-out",
        placeHolder: "",
        type: "textbox",
        disabled: true,
        required: true,
        errorMessage: this.translate.instant('renewalContract') + this.translate.instant("emptyMessage"),
    };
    poNumberConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('poNumber'),
        cssClass: "po-amount",
        placeHolder: "",
        type: "textbox",
        required: true,
        errorMessage: this.translate.instant('poNumber') + this.translate.instant("emptyMessage"),
    };

    poAmountConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('poAmount'),
        cssClass: "po-amount",
        placeHolder: "",
        type: "textbox",
        isNumber: true,
        maxLength: "20",
        required: true,
        errorMessage: this.translate.instant('poAmount') + this.translate.instant("emptyMessage"),
    };

    poCurrencyConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('poCurrency'),
        cssClass: "po-amount gray-out",
        placeHolder: "",
        type: "textbox",
        required: true,
        disabled: true,
        errorMessage: this.translate.instant('poCurrency') + this.translate.instant("emptyMessage"),
    };
    uploadReasonConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('reason'),
        cssClass: "po-amount gray-out",
        placeHolder: "",
        type: "textbox",
        errorMessage: this.translate.instant('reason') + this.translate.instant("emptyMessage"),
        required: true,
        disabled: true,
    };
    reasonConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('reason'),
        cssClass: "po-amount",
        placeHolder: this.translate.instant('pleaseSelect'),
        type: "select",
        defaultValue: "",
        inputDisabled: false,
        errorMessage: this.translate.instant('reason') + this.translate.instant("emptyMessage"),
        required: true,
        id: '',
        options: [
            {
                label: this.translate.instant('contactAccountRepresentative'),
                value: this.translate.instant('contactAccountRepresentative')
            },
            {
                label: this.translate.instant('cannotFindMyAddress'),
                value: this.translate.instant('cannotFindMyAddress')
            },
            {
                label: this.translate.instant('cannotFinadAContact'),
                value: this.translate.instant('cannotFinadAContact')
            },
            {
                label: this.translate.instant('cannotFindRenewalContract'),
                value: this.translate.instant('cannotFindRenewalContract')
            },
            {
                label: this.translate.instant('haveGeneralEnquiry'),
                value: this.translate.instant('haveGeneralEnquiry')
            },
            {
                label: this.translate.instant('removeScheduleBilling'),
                value: this.translate.instant('removeScheduleBilling')
            }
        ]
    };
    subjectConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('subject'),
        cssClass: "po-amount gray-out",
        placeHolder: "",
        type: "textbox",
        disabled: true,
        required: true,
        errorMessage: this.translate.instant('subject') + this.translate.instant("emptyMessage"),
    };
    descriptionConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('description'),
        cssClass: "po-amount",
        placeHolder: "",
        type: "textarea",
        maxLength: "500"
    };
    uploadConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('attachAdditionalDoc'),
        cssClass: "po-amount",
        placeHolder: this.translate.instant('uploadfileName'),
        type: "fileUpload",
        required: true,
        errorMessage: this.translate.instant("attachDocument"),
    };
    requestAssistancePopupBtnGroup: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("cancel"),
            type: "button",
            classStyles: "bordered",
            usecase: "cancel",
            disabled: false,
            id: "cancel-request-assistance-btn"
        },
        {
            label: this.translate.instant("submit"),
            type: "button",
            classStyles: "solid",
            usecase: "submit",
            disabled: false,
            id: "save-request-assistance-btn"
        }

    ];

    constructor(public translate: TranslateService) { }

}
