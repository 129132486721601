<div class="customer-reference">
    <div class="customer-reference-header">
        <h3 class="h4 heading-regular mb-4 mb-md-6 mb-lg-8">{{'ChangeCustomerReference' | translate}}</h3>
    </div>
    <div class="customer-reference-body mt-3">
        <label>{{'customerRef' | translate}}</label>
        <div [formGroup]="customerRefForm">
            <app-form-fields [formControlName]="'customerReference'"
                             [fieldConfig]="customerReferenceConfig">
            </app-form-fields>
        </div>
    </div>
    <div class="customer-reference-footer mt-3">
        <ng-container *ngFor="let btn of customerRefPopupBtnGroup">
            <app-button-group [buttonConfig]="btn"
                              (emitClickEvent)="updateCustomerReference($event)">
            </app-button-group>
        </ng-container>
    </div>
</div>

<div *ngIf="isLoading">
    <app-spinner-overlay></app-spinner-overlay>
</div>