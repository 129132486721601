import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from 'src/app/shared/services/base-api.service';
import { MiddlewareUrls } from '../../pending/constants/middleware-urls';
import { AccountAddressesResponse, AccountsSummaryResponse, AddRenewalContactRequest, AddRenewalContactResponse, RemoveRenewalContactRequest, RemoveRenewalContactResponse, UpdateChangeAddressRequest, UpdateChangeAddressResponse, UpdatePrimaryContactRequest, UpdatePrimaryContactResponse, primaryContactRequest, primaryContactResponse } from '../interface/accounts';

@Injectable({
    providedIn: 'root'
})
export class AccountsService extends BaseApiService {

    constructor(http: HttpClient) {
        super(http);
    }

    getAccounts(): Observable<AccountsSummaryResponse> {
        return this.getApiCall(MiddlewareUrls.getAccounts);
    }

    getAccountAddress(param: any): Observable<AccountAddressesResponse> {
        return this.getApiCall(MiddlewareUrls.getAccountAddress, param);
    }

    updateAccountAddress(param: UpdateChangeAddressRequest): Observable<UpdateChangeAddressResponse> {
        return this.callPostRestApi(MiddlewareUrls.updateAccountAddress, param);
    }

    getAccountContacts(accountNumber: string, renewalUniqueID: string): Observable<primaryContactResponse> {
        const queryParams: primaryContactRequest = {
            accountNumber: accountNumber,
            renewalUniqueID: renewalUniqueID
        };
        return this.getApiCall(MiddlewareUrls.getAccountContacts, queryParams);
    }

    updatePrimaryContact(params: UpdatePrimaryContactRequest): Observable<UpdatePrimaryContactResponse> {
        return this.callPostRestApi(MiddlewareUrls.updatePrimaryContact, params);
    }
    addRenewalContact(params: AddRenewalContactRequest): Observable<AddRenewalContactResponse> {
        return this.callPostRestApi(MiddlewareUrls.addRenewalContact, params);
    }
    removeRenewalContact(params: RemoveRenewalContactRequest): Observable<RemoveRenewalContactResponse> {
        return this.callPostRestApi(MiddlewareUrls.removeRenewalContact, params);
    }

    searchInputMethod(event: any, inputObject: any[]) {
        return inputObject.filter(e => e.label.toLowerCase().
            includes(event.toLowerCase() as string));
    }

}
