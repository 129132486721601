import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, fromEvent, takeUntil } from 'rxjs';
import { LanguageConstants } from './core/constants/language.constants';
import { RoutingUrlConstants } from './core/constants/routing-url.constants';
import { AuthenticationService } from './core/services/auth.service';
import { CommonService } from './shared/services/common.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent {

    title = 'orc-portal';

    isLoggedIn = false;

    isAuthenticated$: Observable<boolean>;

    language = '';

    defaultLangCode = 'en-US';
    userActivity: any;
    userInactive: Subject<any> = new Subject();
    private unsubscriber: Subject<void> = new Subject<void>();

    constructor(
        public translateService: TranslateService,
        private commonService: CommonService,
        private authService: AuthenticationService,
        private router: Router
    ) {

        this.translateService.setDefaultLang(this.defaultLangCode);
        this.translateService.use(this.defaultLangCode);

        this.isAuthenticated$ = this.authService.isAuthenticated$;
        this.authService?.isUserProfile$.subscribe(e => {
            if (e?.language) {
                this.language = this.getlanguage(e?.language);
                this.translateService.use(this.language);
            }
        });
        const userObj = sessionStorage.getItem("id_token_claims_obj")!;
        if (userObj) {
            const languageCode = JSON.parse(userObj);
            this.language = this.commonService.isImpersonating()
                ? this.getlanguage(this.commonService.getImpersonatedUserData()['lang'])
                : this.getlanguage(languageCode?.language);
            this.translateService.use(this.language);
        }
        else {
            const preLoginLanguage = this.getNavigatorLanguage();
            let browserLanguage = this.defaultLangCode;
            LanguageConstants.filter((a) => {
                a.code == preLoginLanguage ? browserLanguage = a.code : "en-US";
            });
            this.translateService.use(browserLanguage);
        }

    }
    getNavigatorLanguage() {
        if (navigator.language && navigator.language.length > 2) {
            return this.getlanguage(navigator.language.substring(0, 2));
        } else {
            return this.getlanguage(navigator.language);
        }
    }
    changeLanguage(language: string): void {
        this.authService.isAuthenticated$.subscribe(loggedIn => {
            this.isLoggedIn = loggedIn;
        });
        this.translateService.use(language);
    }

    ngOnInit() {
        this.authService.isAuthenticated$.subscribe(loggedIn => {
            this.isLoggedIn = loggedIn;
        });

        if (this.isLoggedIn && this.authService.hasValidToken()) {
            this.setTimeout();
            this.userInactive.subscribe(() => {
                this.authService.logout();
            });
        }
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && !this.isLoggedIn) {
                if (event.url == RoutingUrlConstants.ORC_LANDING) {
                    this.preventBrowserBackButton();
                }
            }
        });
    }
    preventBrowserBackButton() {
        history.pushState(null, '');
        fromEvent(window, 'popstate').pipe(
            takeUntil(this.unsubscriber)
        ).subscribe((_) => {
            history.pushState(null, '');
            console.info("Back Button Fired: You can't make changes or go back at this time.");
        });

    }
    ngOnDestroy(): void {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
    setTimeout() {
        this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1500000);
    }

    keyPress(event: KeyboardEvent): void {
        clearTimeout(this.userActivity);
        this.setTimeout();
    }

    @HostListener('window:mousemove') refreshUserState() {
        clearTimeout(this.userActivity);
        this.setTimeout();
    }

    getlanguage(e: string) {
        switch (e) {
            case "en":
                return "en-US";
            case "es":
                return "es-ES";
            case "de":
                return "de-GE";
            case "fr":
                return "fr-FR";
            case "ja":
                return "ja-JP";
            case "pt":
                return "pt-BR";
            default:
                return "en-US";
        }
    }
}
