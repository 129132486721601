import { TranslateService } from '@ngx-translate/core';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';

export class TersmConditionsConstants {

    constructor(public translate: TranslateService) { }

    termsComponentButtonGroup: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("cancel"),
            type: "button",
            classStyles: "bordered",
            usecase: "cancel",
            disabled: false,
            id: "cancel-add-on-btn"
        },
        {
            label: this.translate.instant("acceptTermsCondition"),
            type: "button",
            classStyles: "solid",
            usecase: "submit",
            disabled: false,
            id: "accept-terms-condition-btn"
        }

    ];

}