import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appInputRestriction]'
})

export class InputRestrictionDirective {
    regexStr = '^[^\s]+(\s+[^\s]+)*$';

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.el.nativeElement.selectionStart === 0 && event.key === ' ') {
            event.preventDefault();
        }

        if (!RegExp(this.regexStr).test(event.key) && event.key != 's') {
            event.preventDefault();
        }
    }
}