import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { CustomerReferenceRequest } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/pending';
import { RenewalsService } from 'src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service';
import { SharedComponentConstantData } from '../../constants/shared-data.constants';

@Component({
    selector: 'app-update-customer-reference',
    templateUrl: './update-customer-reference.component.html',
    styleUrls: ['./update-customer-reference.component.sass']
})
export class UpdateCustomerReferenceComponent extends SharedComponentConstantData implements OnInit {

    data: any;
    isLoading = false;
    customerRefForm = this.fb.group({
        customerReference: ['']
    })

    constructor(
        private fb: FormBuilder,
        private rs: RenewalsService,
        public dialogRef: MatDialogRef<any>,
        translate: TranslateService
    ) {
        super(translate);
    }

    ngOnInit() {
        this.customerRefForm.patchValue({
            customerReference: this.data.customerReference
        });
    }

    updateCustomerReference(e: string) {
        this.isLoading = true;
        const customerReference = this.customerRefForm.get('customerReference')?.value!;
        const customRef: CustomerReferenceRequest = {
            renewalUniqueID: [this.data.assureRenewalUniqueID],
            customerReference: customerReference
        }

        if (e == 'submit') {
            this.rs.updateCustomerReference(customRef).pipe(tap((e) => {
                const res = { customerReference: e.customerReference, rc: this.data }
                this.dialogRef.close(res);
            })).subscribe(() => this.isLoading = false);
        }
        else {
            this.dialogRef.close(e);
        }
    }
}
