import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FooterLinkList } from "../interface/footer.interface";

@Injectable()
export class FooterConstants {

    constructor(private translate: TranslateService) { }

    footerLinks: FooterLinkList[] = [
        {
            category: this.translate.instant("selfService"),
            listLinks: [
                {
                    label: this.translate.instant("productSolutions"),
                    link: "https://www.opentext.com/products/listing",
                    isdisable: "",
                },
                {
                    label: this.translate.instant("knowledgeBase"),
                    link: "https://support.opentext.com/csm?id=csm_knowledge",
                    isdisable: "",
                },
                {
                    label: this.translate.instant("serviceCatalog"),
                    link: "https://support.opentext.com/csm?id=ot_csm_sc_category",
                    isdisable: ""
                },
                {
                    label: this.translate.instant("forums"),
                    link: "https://support.opentext.com/community",
                    isdisable: ""
                },
                {
                    label: this.translate.instant("cookiePreferences"),
                    link: "",
                    isdisable: "is-disable",
                }
            ]
        },
        {
            category: this.translate.instant("services"),
            listLinks: [
                {
                    label: this.translate.instant("consultingServices"),
                    link: "https://www.opentext.com/services/consulting",
                    isdisable: ""
                },
                {
                    label: this.translate.instant("managedServices"),
                    link: "https://www.opentext.com/services/managed-services",
                    isdisable: ""
                },
                {
                    label: this.translate.instant("learningServices"),
                    link: "https://www.opentext.com/learning-services",
                    isdisable: ""
                }
            ]
        },
        {
            category: this.translate.instant("account"),
            listLinks: [
                {
                    label: this.translate.instant("viewAccountServices"),
                    link: "https://ui.otiam.opentext.com/#/myApplications",
                    isdisable: "",
                },
                {
                    label: this.translate.instant("payABill"),
                    link: "https://epay.opentext.com/",
                    isdisable: "",
                },
                {
                    label: this.translate.instant("myPreferences"),
                    link: "https://support.opentext.com/csm?id=csm_profile",
                    isdisable: "",
                }
            ]
        },
        {
            category: this.translate.instant("partners"),
            listLinks: [
                {
                    label: this.translate.instant("partnerPortal"),
                    link: "https://partners.opentext.com/",
                    isdisable: "",
                },
                {
                    label: this.translate.instant("dealDesk"),
                    link: "https://partnerdealreg.opentext.com/",
                    isdisable: "",
                },
            ],
        },
        {
            category: this.translate.instant("sites"),
            listLinks: [
                {
                    label: this.translate.instant("openTextBlog"),
                    link: "https://blogs.opentext.com/",
                    isdisable: ""
                },
                {
                    label: this.translate.instant("openTextcom"),
                    link: "https://www.opentext.com/",
                    isdisable: ""
                },
                {
                    label: this.translate.instant("sendFeedback"),
                    link: "https://support.opentext.com/csm?id=my_surveys",
                    isdisable: "",
                },
                {
                    label: this.translate.instant("accessibility"),
                    link: "https://www.opentext.com/about/copyright-information/accessibility",
                    isdisable: "",
                }
            ]
        }
    ];
}
