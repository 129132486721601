import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HeaderFooterDataConstants } from 'src/app/core/constants/header-footer.constants';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { CartData, CartRenewals, CheckOutRequestParam, cartResult } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/pending';
import { initialTableAddon } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/table-columns';
import { RenewalsService } from 'src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { TableColumn } from 'src/app/shared/components/table/TableColumn';
import { dateFormatConfig } from 'src/app/shared/constants/date-constants';
import { PopupService } from 'src/app/shared/services/popup.service';
import { cartConstants } from '../../constants/cart.constant';
import { CartService } from '../../services/cart.service';
import { CartModel } from './model/cart.model';

@Component({
    selector: 'app-add-to-cart',
    templateUrl: './add-to-cart.component.html',
    styleUrls: ['./add-to-cart.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class AddToCartComponent extends cartConstants implements OnInit {
    cartSummaryTableColumns: TableColumn[];
    cartList: cartResult[] = [];
    cartResult: cartResult[] = [];
    dateFormat = dateFormatConfig.longDate;
    sumofContractAmount!: number | string;
    currencyValue = '';
    estimatedTax!: number | string;
    proceedCheckoutBtn = this.addToCart_Checkout;
    initialTableAddon: TableColumn[];
    consolidatedValues!: CartModel[];
    cartData!: CartRenewals;
    emptyCart: boolean = false;
    isLoading = false;
    deleteAction = false;
    renewalUniqueID: Array<string> = [];
    constructor(
        private translate: TranslateService,
        private router: Router,
        private renewalService: RenewalsService,
        private cartService: CartService,
        private location: Location,
        private headerFooter: HeaderFooterDataConstants,
        private _snakBar: SnackBarService,
        private popupService: PopupService,


    ) {
        super(translate);
        this.initialTableAddon = initialTableAddon;
        this.cartSummaryTableColumns = this.cartSummaryInfo;
    }

    ngOnInit() {
        localStorage.removeItem('shoppingCart1');
        this.getCartRenewals();
    }

    getCartRenewals() {
        this.isLoading = true;
        this.deleteAction = false;
        this.cartService.getCartRenewals().subscribe((e) => {
            if (e.getCartRenewals.isSuccess) {
                this.commonMethodForCartData(e.getCartRenewals);
            }
        });
    }

    proceedCheckout(e: string) {
        switch (e) {
            case "cancel":
                return this.location.back();
            case "proceedToCheckout":
                return this.checkoutCartItems();
            default:
                return;

        }
    }
    checkoutCartItems() {
        const cartInfo: any = this.cartList;
        if (this.cartService.validateCartData(undefined, cartInfo, 'cart')) {
            this.isLoading = true;
            this.deleteAction = true;
            localStorage.setItem('shoppingCart1', 'cart');
            this.router.navigate([RoutingUrlConstants.ORC_CONFIRM_PLACE_ORDER]);
            this.isLoading = false;
        }
    }

    rowDelete(e: any) {
        this.isLoading = true;
        this.deleteAction = true;
        const checkOutParams: CheckOutRequestParam = {
            renewalUniqueID: [e.assureRenewalUniqueID]
        };
        this.renewalService.removeFromCart(checkOutParams).subscribe(
            e => {
                if (e.removeFromCart.isSuccess) {
                    this.commonMethodForCartData(e.removeFromCart);
                }
                e.removeFromCart.statusCode ? this._snakBar.showSnackBar(e.removeFromCart.statusCode) : '';
                this.isLoading = false;
                this.deleteAction = false;
            }
        );

    }

    commonMethodForCartData(e: CartData) {
        this.cartList.length = 0;
        this.cartResult = e.result!;
        this.headerFooter.postLoginBtnCnfg[0].badget = e.count;
        localStorage.setItem('cartCount', String(e.count));
        if (this.cartResult.length !== 0) {
            this.emptyCart = false;
            this.currencyValue = this.cartResult[0].currency!;
            this.renewalUniqueID = this.cartResult.map((a: any) => a.assureRenewalUniqueID!);
            this.cartResult?.forEach(e => {
                this.cartList.push(e)
            });
            this.cartList = [...this.cartList];
        } else {
            this.emptyCart = true;
        }
        this.isLoading = false;
    }
    getDetailsByRcId(data?: any) {
        localStorage.setItem("contract", data.rcNumber);
        localStorage.setItem("renewalCategory", data.rm);
        this.router.navigate(
            [RoutingUrlConstants.ORC_PENDING_DETAILS + '/' + data.rcNumber]
        );
    }
    menuClick() {
        this.router.navigate([RoutingUrlConstants.ORC_PENDING_SUMMARY]);
    }


}
