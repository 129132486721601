<div class="change-address">
    <div class="change-address-header">
        <h3 class="h4 heading-regular mb-4 mb-md-6 mb-lg-8">{{'requestAddressChange' | translate}}</h3>
    </div>
    <div class="change-address-body mt-3">
        <div [formGroup]="addressChangeForm">
            <label for="mat-select-18">{{'requestAssistanceInstruction' | translate}}</label>
            <app-form-fields [formControlName]="'changeAddress'"
                             (selectedDropdown)="selectedOptionDropdown($event)"
                             [fieldConfig]="changeAddressConfig">
            </app-form-fields>
            <ng-container *ngIf="isDropdownVisible || selectAddress">
                <label for="avilableAddressConfig">{{'avilableAddress' | translate}}</label>
                <app-form-fields [fieldConfig]="avilableAddressConfig"
                                 (searchInputValue)="searchInputMethod($event)"
                                 [formControlName]="'availableAddress'"
                                 (openDropdown)="openDropdown($event)"
                                 (selectedDropdown)="selectedDropdown($event)"
                                 [stateGroups]="searchObject">
                </app-form-fields>
            </ng-container>
        </div>
    </div>
    <div class="change-address-footer mt-3">
        <ng-container *ngFor="let btn of customerRefPopupBtnGroup">
            <app-button-group [buttonConfig]="btn"
                              (emitClickEvent)="changeAddress($event)">
            </app-button-group>
        </ng-container>
    </div>
</div>

<div *ngIf="isLoading">
    <app-spinner-overlay></app-spinner-overlay>
</div>