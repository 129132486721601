export enum UniqueID {
    Pending = 1,
    Active = 2,
    History = 3,
    Annual = 1,
    AutoInvoice = 2,
    ViewDetailsButton = 1,
    RequestAssianceButton = 2,
    RenewTodayButton = 3,
    All = 0,
    HeaderPage = 4,
    ConfirmPage = 5,
    DetailPage = 6
}