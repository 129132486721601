import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { Observable, filter, switchMap, tap } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { RoutingUrlConstants } from '../constants/routing-url.constants';


@Injectable()
export class AuthGuard  {
  constructor(private oauthService: OAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (this.oauthService.hasValidIdToken()) {
      return Promise.resolve(true);
    }

    return this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(_ => {
        return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
      })
      .then(valid => {
        if (!valid) {
          this.router.navigateByUrl(RoutingUrlConstants.ORC_LANDING);
        }
        return valid;
      });
  }
}