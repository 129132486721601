import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RoutingUrlConstants } from "src/app/core/constants/routing-url.constants";
import { BreadCrumbConstants } from "../../constants/breadcrumb.constants";

@Component({
    selector: "app-breadcrumb",
    templateUrl: "./breadcrumb.component.html",
    styleUrls: ["./breadcrumb.component.sass"],
})
export class BreadcrumbComponent implements OnInit {
    routingUrlList: object | any = RoutingUrlConstants;
    currentRoutePath: string | any;
    crumbList: Array<any> = [];
    rm: string | null = null;
    rc: string | null = null;
    breadCrumbList: Array<any> = [];
    urlListObjectKeys: Array<any> = [];
    typeOfCrumb: string = '';
    lastItem: any;
    breadCrumbLabel: any;
    homeLabel = this.breadCrumb.breadCrumbConstants.ORC_HOME;
    payParam: string = '';
    pageNotFound: boolean = false;
    constructor(
        private router: Router,
        public translate: TranslateService,
        private breadCrumb: BreadCrumbConstants,
        private route: ActivatedRoute
    ) {

        this.route.queryParams.subscribe((params) => {
            this.payParam = params['methodType'];
        });
        this.router.events.subscribe((event) => {
            if (event instanceof ActivationEnd) {
                event.snapshot.data['info'] ? this.pageNotFound = true : this.pageNotFound = false;
            }
            if (event instanceof NavigationEnd && event.url != "/") {
                this.breadCrumbList = [{ label: this.breadCrumb.breadCrumbConstants.ORC_HOME, route: RoutingUrlConstants.ORC_HOME }];
                event.url.includes('#') ? event.url = event.url.slice(0, event.url.indexOf('#')) : '';
                this.currentRoutePath = event.url.split("?")[0];
                event.url.split("/")[4] == 'details' ? this.rc = this.currentRoutePath.split('/').pop() :
                    this.rc = localStorage.getItem('rc') || ''; // to display rcId in url as breadcrumb
                this.currentRoutePath = this.currentRoutePath.split("/RC")[0];
                this.urlListObjectKeys = Object.keys(this.routingUrlList).map(
                    (key: string | number) => ({
                        type: key,
                        value: this.routingUrlList[key],
                    })
                );
                this.crumbList = this.urlListObjectKeys.filter((list: { value: string }) => {
                    return list.value == this.currentRoutePath;
                });
                this.typeOfCrumb = this.crumbList[0]["type"]!;
                this.breadCrumbLabel = breadCrumb.breadCrumbConstants[this.typeOfCrumb];

                switch (this.typeOfCrumb) {
                    case "ORC_PENDING_DETAILS":
                        this.summaryMethod('ORC_PENDING_SUMMARY');
                        this.detailsMethod(this.typeOfCrumb);
                        break;
                    case "ORC_ACTIVE_DETAILS":
                        this.summaryMethod('ORC_ACTIVE_SUMMARY');
                        this.detailsMethod(this.typeOfCrumb);
                        break;
                    case "ORC_HISTORY_DETAILS":
                        this.summaryMethod('ORC_HISTORY_SUMMARY');
                        this.detailsMethod(this.typeOfCrumb);
                        break;
                }
                if ((this.typeOfCrumb == 'ORC_CONFIRM_PLACE_ORDER')) {
                    this.confirmPlaceOrder(this.typeOfCrumb);
                }
                if (this.typeOfCrumb == 'ORC_PENDING_SUMMARY') {
                    this.summaryMethod(this.typeOfCrumb);
                }
                if (this.typeOfCrumb == 'ORC_PAYMENT') {
                    localStorage.getItem('shoppingCart') == 'summary' ? this.summaryMethod('ORC_PENDING_SUMMARY') : '';
                    this.confirmPlaceOrder('ORC_CONFIRM_PLACE_ORDER');
                    //this.detailsMethod(this.typeOfCrumb);
                } else if (this.typeOfCrumb == 'ORC_CONFIRM_ORDER') {
                    this.commonPushMethod('ORC_CONFIRM_ORDER');
                }
                else if (this.typeOfCrumb.includes('ORC_HELP_ARTICLE')) {
                    this.commonPushMethod('ORC_HELP');
                }
                else if (this.typeOfCrumb.includes('ORC_CART')) {
                    localStorage.getItem('shoppingCart') == 'detail' ?
                        (this.summaryMethod('ORC_PENDING_SUMMARY'), this.detailsMethod(this.typeOfCrumb), this.commonPushMethod('ORC_CART'))
                        : localStorage.getItem('shoppingCart') == 'summary' ? (this.summaryMethod('ORC_PENDING_SUMMARY'), this.commonPushMethod('ORC_CART'))
                            : localStorage.getItem('shoppingCart1') == 'cart' ? this.cartMethod('ORC_CART') : '';
                }
                const a: any = {
                    label: breadCrumb.breadCrumbConstants[this.typeOfCrumb],
                    route: this.urlListObjectKeys.find((a: { type: any }) => {
                        return a.type == this.typeOfCrumb;
                    }).value,
                }
                //this.typeOfCrumb != 'ORC_PENDING_DETAILS' ? this.breadCrumbList = [...this.breadCrumbList, a] : '';
                switch (this.typeOfCrumb) {
                    case 'ORC_ACTIVE_DETAILS':
                    case 'ORC_HISTORY_DETAILS':
                    case 'ORC_PENDING_DETAILS':
                        break;
                    default:
                        this.breadCrumbList = [...this.breadCrumbList, a];
                        break;
                }
                this.breadCrumbList = this.breadCrumbList.reduce(
                    (acc: any[], crumb: { label: any }) => {
                        !acc.find((u) => u.label === crumb.label)
                            ? acc.push(crumb)
                            : undefined;
                        return acc;
                    },
                    []
                );
                this.lastItem = this.breadCrumbList[this.breadCrumbList.length - 1];
            }
        });
    }
    ngOnInit() { }
    ngAfterViewInit() { }
    cartMethod(type?: string) {
        const a = {
            label: this.breadCrumb.breadCrumbConstants[type!],
            route:
                this.urlListObjectKeys.find((a: { type: any }) => {
                    return a.type == type;
                }).value,
        };
        this.breadCrumbList = [...this.breadCrumbList, a];
        this.breadCrumbLabel = this.rc;
    }
    summaryMethod(type?: string) {
        const a = {
            label: this.breadCrumb.breadCrumbConstants[type!],
            route:
                this.urlListObjectKeys.find((a: { type: any }) => {
                    return a.type == type;
                }).value,
        };
        this.breadCrumbList = [...this.breadCrumbList, a];
        this.breadCrumbLabel = this.rc;
    }
    detailsMethod(type: string) {
        this.breadCrumbLabel = this.rc;
        const a: any = {
            label: this.rc,
            route: this.urlListObjectKeys.find((a: { type: any }) => {
                return a.type == type;
            }).value + "/" +
                this.rc
        }
        if (this.rc && (!localStorage.getItem("elbLink") || localStorage.getItem("editPay"))) {
            this.breadCrumbList = [...this.breadCrumbList, a];
        }
    }
    confirmPlaceOrder(type: string) {
        if (this.rc && localStorage.getItem('shoppingCart1') != 'cart') {
            this.summaryMethod('ORC_PENDING_SUMMARY');
            this.detailsMethod('ORC_PENDING_DETAILS');
            this.breadCrumbLabel = this.rc;
            this.commonPushMethod(type);
        }
        else if (localStorage.getItem('shoppingCart1') == 'cart' &&
            (localStorage.getItem('shoppingCart') != 'summary' && localStorage.getItem('shoppingCart') != 'detail')) {
            this.commonPushMethod('ORC_CART');
            this.commonPushMethod(type);
        }
        else if (localStorage.getItem('shoppingCart') == 'summary') {
            localStorage.getItem('shoppingCart1') != 'cart'
                ? (this.summaryMethod('ORC_PENDING_SUMMARY'))
                : localStorage.getItem('shoppingCart1') == 'cart'
                    ? (this.summaryMethod('ORC_PENDING_SUMMARY'), this.commonPushMethod('ORC_CART'))
                    : '';
            this.commonPushMethod(type);
        }
        else if (localStorage.getItem('shoppingCart') == 'detail') {
            localStorage.getItem('shoppingCart1') != 'cart'
                ? (this.summaryMethod('ORC_PENDING_SUMMARY'), this.detailsMethod('ORC_PENDING_DETAILS'))
                : localStorage.getItem('shoppingCart1') == 'cart'
                    ? (this.summaryMethod('ORC_PENDING_SUMMARY'), this.detailsMethod('ORC_PENDING_DETAILS'), this.commonPushMethod('ORC_CART'))
                    : '';
            this.commonPushMethod(type);
        }
        else {
            this.summaryMethod('ORC_PENDING_SUMMARY');
            this.detailsMethod('ORC_PENDING_DETAILS');
            this.commonPushMethod(type);
        }

    }
    commonPushMethod(type: string) {
        const obj: any = {
            label: this.breadCrumb.breadCrumbConstants[type],
            route: this.urlListObjectKeys.find((a: { type: any }) => {
                return a.type == type;
            }).value,
        }
        localStorage.getItem("elbLink") || localStorage.getItem("editPay") ? this.breadCrumbList = [...this.breadCrumbList]
            : this.breadCrumbList = [...this.breadCrumbList, obj];

    }
}
