import { Action, createAction, props } from '@ngrx/store';
import { PendingRenewalsSummary, PendingSummary } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/pending';
import { renewalParamRequest } from './pending-summary.state';

export const invokePendingSummaryAPI = createAction(
    '[Pending Summary API] Invoke Pending Summary Fetch API',
    props<{ params: renewalParamRequest }>()
);

export const pendingSummaryFetchAPISuccess = createAction(
    '[Pending Summary API] Fetch API Success',
    props<{ pendingSummaryAll: PendingRenewalsSummary }>()
);

export enum CheckListActionType {
    CHECKLIST_ITEM = '[CHECK LIST SUMMARY] pending summary '
}

export class CheckListSummaryAction implements Action {
    type = CheckListActionType.CHECKLIST_ITEM;
    constructor(public payload: { Summary: PendingSummary[] }) { }
}

