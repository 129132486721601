<section class="section section-light-down section-top section-trim-bottom mb-6">
    <div class="container container-fluid container-lg container-md container-sm container-xl">
        <div class="row row-cols-1 row-cols-lg-2">
            <div class="col">
                <div class="section-header section-header-lg">
                    <h1 class="section-header-title">{{ "renewalReport" | translate }}</h1>
                    <p class="section-header-subtitle">{{"renewalReportDesc" | translate}}</p>
                    <p class="section-header-subtitle">
                        <a class="more more-bold" href="orc/help/reporting">{{ "renewalReportLearnMore" | translate }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row mb-3">
        <div class="col">
            <div class="section-header section-header-lg">
                <div class="step-label">{{"reportStep1" | translate}}</div>
                <h3 class="h4 header-title">{{"pickRenewalType" | translate}}</h3>
                <p class="header-subtitle">{{"pickRenewalTypeDesc" | translate}}</p>
                <div class="renewal-type-chkbox">
                    <mat-checkbox [value]="selectAll"
                                  [checked]="isSelectedAll"
                                  (change)="toggleCheckboxAll($event)">
                        {{"renewalTypeAll" | translate}}
                    </mat-checkbox>
                    <div *ngFor="let item of renewalTypesList">
                        <mat-checkbox [value]="item.value "
                                      [checked]="item.isChecked"
                                      [(ngModel)]="item.isChecked"
                                      (change)="getRenewalCheckbox($event, 'rType')">
                            {{item.key | translate}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <div class="section-header section-header-lg">
                <div class="step-label">{{"reportStep2" | translate}}</div>
                <h3 class="h4 header-title">{{"pickReportType" | translate}}</h3>
                <p class="header-subtitle">{{"pickReportTypeDesc" | translate}}</p>
                <mat-radio-group aria-label="Select an option"
                                 [(ngModel)]="selectReportType">
                    <div *ngFor="let item of reportTypeList"
                         class="renewal-type-radio">
                        <mat-radio-button [checked]="item.value == 'summary'"
                                          [value]="item.value">
                            {{item.key | translate}}
                        </mat-radio-button>
                        <mat-icon class="status-info"
                                  [matTooltip]="item.value == 'summary' ? summaryTooltip : detailTooltip"
                                  [matTooltipPosition]="'right'">info_outline</mat-icon>
                    </div>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <div class="section-header section-header-lg">
                <div class="step-label">{{"reportStep3" | translate}}</div>
                <h3 class="h4 header-title">{{"applyFilter" | translate}}</h3>
                <p class="header-subtitle">{{"applyFilterDesc" | translate}}</p>
            </div>
            <div class="row row-gap">
                <div class="col col-sm-12 col-md-6  col-lg-6">
                    <div class="card card-primary-bottom shadow">
                        <div id="supportDates"
                             class="card-body">
                            <h3 class="card-title-sm">{{"filterSupportDates" | translate}}</h3>
                            <p class="card-text">{{"filterSupportDatesDesc" | translate}}</p>
                            <mat-checkbox (change)="enableStartDatePicker($event,'startDate')"
                                          class="renewal-type-radio"
                                          [checked]="startDateChecked">
                                {{"startDate" | translate}}
                            </mat-checkbox>
                            <mat-form-field class="example-form-field">
                                <mat-label>{{"dateRange" | translate}}</mat-label>
                                <mat-date-range-input [rangePicker]="picker"
                                                      [formGroup]="filterForm"
                                                      [disabled]="disabledStartDate">
                                    <input matStartDate
                                           matInput
                                           readonly
                                           [formControlName]="'startDateFrom'"
                                           aria-label="Start date from">
                                    <input matEndDate
                                           matInput
                                           readonly
                                           [formControlName]="'startDateTo'"
                                           aria-label="Start date to">
                                </mat-date-range-input>
                                <mat-hint>{{reportDateFormat}}</mat-hint>
                                <mat-datepicker-toggle matIconSuffix
                                                       [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                            <mat-checkbox (change)="enableStartDatePicker($event,'endDate')"
                                          class="renewal-type-radio"
                                          [checked]="endDateChecked">
                                {{"endDate" | translate}}
                            </mat-checkbox>
                            <mat-form-field class="example-form-field">
                                <mat-label>{{"dateRange" | translate}}</mat-label>
                                <mat-date-range-input [rangePicker]="picker1"
                                                      [formGroup]="endDateForm"
                                                      [disabled]="disabledEndDate">
                                    <input matStartDate
                                           matInput
                                           readonly
                                           [formControlName]="'endDateFrom'"
                                           aria-label="End date from">
                                    <input matEndDate
                                           matInput
                                           readonly
                                           [formControlName]="'endDateTo'"
                                           aria-label="End date to">
                                </mat-date-range-input>
                                <mat-hint>{{reportDateFormat}}</mat-hint>
                                <mat-datepicker-toggle matIconSuffix
                                                       [for]="picker1"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker1></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="card card-primary-bottom shadow required-height">
                        <div id="accountGroups"
                             class="card-body">
                            <h3 class="card-title-sm">{{"accountGroups" | translate}}</h3>
                            <p class="card-text">{{"accountGroupDesc" | translate}}</p>
                            <div [formGroup]="filterForm">
                                <div class="entitlement">
                                    <app-form-fields [formControlName]="'entitilementGroup'"
                                                     class="app-form-fields"
                                                     [fieldConfig]="extentitlementConfig"></app-form-fields>
                                </div>
                                <div class="billToShipTo">
                                    <app-form-fields [formControlName]="'billToShipTo'"
                                                     class="app-form-fields"
                                                     [fieldConfig]="extBillToShipToConfig"></app-form-fields>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="card card-primary-bottom shadow required-height">
                        <div id="contractNumber"
                             class="card-body">
                            <h3 class="card-title-sm">{{"filterContractNumber" | translate}}</h3>
                            <p class="card-text">{{"filterContractNumberDesc" | translate}}</p>
                            <div [formGroup]="filterForm">
                                <app-form-fields [formControlName]="'contractData'"
                                                 class="app-form-fields"
                                                 [fieldConfig]="extContractConfig"></app-form-fields>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="card card-primary-bottom shadow required-height">
                        <div id="renewalMethod"
                             class="card-body">
                            <h3 class="card-title-sm">{{"renewalMethod" | translate}}</h3>
                            <p class="card-text">{{"filterRenewalDesc" | translate}}</p>
                            <div *ngFor="let item of reportMethodList"
                                 class="renewal-type-radio">
                                <mat-checkbox [value]="item.value"
                                              [checked]="item.isChecked"
                                              [(ngModel)]="item.isChecked"
                                              (change)="getRenewalCheckbox($event,'rMethod')">
                                    {{item.key  | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
            <app-button-group (emitClickEvent)="onButtonClick()"
                              class="create-report-btn"
                              [buttonConfig]="createReportConfig"></app-button-group>
        </div>
    </div>
</div>