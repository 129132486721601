import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PendingSummaryModule } from '../core/state/pending-summary/pending-summary.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { CardComponent } from './components/card/card.component';
import { ChangeAddressComponent } from './components/change-address/change-address.component';
import { FormFieldsComponent } from './components/form-fields/form-fields.component';

import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { CustomErrorComponent } from './components/customerror/custom-error.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { RequestAssistanceComponent } from './components/request-assistance/request-assistance.component';
import { SnackbarComponent } from './components/snack-bar/snack-bar.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { SummaryComponent } from './components/summary/summary.component';
import { TableComponent } from './components/table/table.component';
import { UpdateCustomerReferenceComponent } from './components/update-customer-reference/update-customer-reference.component';
import { BreadCrumbConstants } from './constants/breadcrumb.constants';
import { RequestAssistanceConstantData } from './constants/request-assistance.constants';
import { CurrencyFormatDirective } from './directives/currency-format.directive';
import { DisableControlDirective } from './directives/disableControl.directive';
import { InputRestrictionDirective } from './directives/input-restriction';
import { MaterialUiModule } from './modules/material-ui.module';
import { FormatCurrencyPipe } from './pipes/currency-pipe/currency.pipe';
import { DataPropertyGetterPipe } from './pipes/data-property-getter-pipe/data-property-getter.pipe';
import { LocalizedDatePipe } from './pipes/localized-date-pipe/localized-date.pipe';
import { ImpersonateUserComponent } from './components/impersonate-user/impersonate-user.component';
@NgModule({
    declarations: [
        CardComponent,
        SubHeaderComponent,
        SummaryComponent,
        TableComponent,
        DataPropertyGetterPipe,
        FormatCurrencyPipe,
        ButtonGroupComponent,
        NotificationBannerComponent,
        FormFieldsComponent,
        DisableControlDirective,
        BreadcrumbComponent,
        RequestAssistanceComponent,
        UpdateCustomerReferenceComponent,
        ChangeAddressComponent,
        SpinnerOverlayComponent,
        InputRestrictionDirective,
        SnackbarComponent,
        CustomErrorComponent,
        LocalizedDatePipe,
        CurrencyFormatDirective,
        ImpersonateUserComponent,
    ],
    imports: [
        ScrollingModule,
        CommonModule,
        FontAwesomeModule,
        MaterialUiModule,
        PendingSummaryModule,
        MatTooltipModule,
        MatBadgeModule,
        TranslateModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatDialogModule,
        FormsModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "en-us" },
        RequestAssistanceConstantData,
        BreadCrumbConstants,
        DatePipe,
        DecimalPipe,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 2500,
            }
        }
    ],
    exports: [
        FontAwesomeModule,
        CardComponent,
        SubHeaderComponent,
        TableComponent,
        SummaryComponent,
        MaterialUiModule,
        ButtonGroupComponent,
        NotificationBannerComponent,
        TranslateModule,
        FormatCurrencyPipe,
        FormFieldsComponent,
        ReactiveFormsModule,
        MatAutocompleteModule,
        BreadcrumbComponent,
        RequestAssistanceComponent,
        SnackbarComponent,
        SpinnerOverlayComponent,
        CustomErrorComponent,
        LocalizedDatePipe,
        ImpersonateUserComponent,
    ]
})
export class SharedModule { }
