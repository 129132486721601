import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    template: ''
})
export class BreadCrumbConstants {
    breadCrumbConstants: any = {
        ORC_HOME: 'home',
        ORC_LANDING: 'landing',
        ORC_PENDING_SUMMARY: 'upcomingSupportRenewals',
        ORC_ACTIVE_SUMMARY: 'activeSupportRenewals',
        ORC_HISTORY_SUMMARY: 'supportRenewalHistory',
        ORC_PENDING_DETAILS: 'details',
        ORC_ACTIVE_DETAILS: 'details',
        ORC_HISTORY_DETAILS: 'details',
        ORC_CONFIRM_ORDER: 'confirmOrder',
        ORC_CONFIRM_PLACE_ORDER: 'confirmPlaceOrder',
        ORC_PAYMENT: 'payment',
        ORC_CART: 'shoppingCart',
        ORC_HELP: 'help',
        ORC_REPORT: 'reporting',
        ORC_HELP_ARTICLE: 'pendingArticle',
        ORC_HELP_ARTICLE_ONE: 'activerticle',
        ORC_HELP_ARTICLE_TWO: 'historyArticle',
        ORC_HELP_ARTICLE_THREE: 'autoInvoiceArticle',
        ORC_HELP_ARTICLE_FOUR: 'languageArticle',
        ORC_HELP_ARTICLE_FIVE: 'addressesArticle',
        ORC_HELP_ARTICLE_SIX: 'cutomerRefArticle',
        ORC_HELP_ARTICLE_SEVEN: 'activityLogArticle',
        ORC_HELP_ARTICLE_EIGHT: 'acceptingPendingSupportRenewalArticle',
        ORC_HELP_ARTICLE_NINE: 'paymentMethodArticle',
        ORC_HELP_ARTICLE_TEN: 'availablepaymentoptionsArticle',
        ORC_HELP_ARTICLE_ELEVEN: 'termsConditionsArticle',
        ORC_HELP_ARTICLE_TWELVE: 'additionalSupportOptionsArticle',
        ORC_HELP_ARTICLE_THIRTEEN: 'technicalFeedbackArticle',
        ORC_HELP_ARTICLE_FOURTEEN: 'contactUsHeading',
        ORC_HELP_ARTICLE_FIFTEEN: 'genericFaqsArticle',
        ORC_HELP_ARTICLE_SIXTEEN: 'notificationsArticle',
        ORC_HELP_ARTICLE_SEVENTEEN: 'renewalStatusesArticle',
        ORC_HELP_ARTICLE_EIGHTTEEN: 'confirmPlaceOrderArticle',
        ORC_HELP_ARTICLE_NINETEEN: 'howTosArticle',
        ORC_HELP_ARTICLE_TWENTY: 'reportingArticle',
        ORC_HELP_ARTICLE_TWENTY_ONE: 'scheduleBillingArticle',
        ORC_HELP_ARTICLE_TWENTY_TWO: 'contactManagement',
    }
    constructor(private translate: TranslateService) {

    }
}
