import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { AuthenticationService } from 'src/app/core/services/auth.service';


@Component({
    selector: 'app-login-callback',
    template: ''
})
export class SignInRedirectCallbackComponent implements OnInit {
    constructor( private readonly _authService: AuthenticationService, private readonly _router: Router
    ) {}

    ngOnInit() {        
        this._authService.isAuthenticated$.subscribe(loggedIn => {
            if(!loggedIn){
                this._authService.login(RoutingUrlConstants.ORC_LANDING);
            }else{
                this._router.navigate([RoutingUrlConstants.ORC_HOME], {replaceUrl: true});
            }
            
        });       
    }
}
