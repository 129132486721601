import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { pendingConstantsLbl } from 'src/app/modules/orc-renewals/sub-modules/pending/constants/pending-constant';
import { RenewalsService } from 'src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service';
import { dateFormatConfig } from '../../constants/date-constants';
import { SharedComponentConstantData } from '../../constants/shared-data.constants';
import { UniqueID } from '../../enums/unique-id.enum';
import { ButtonGroupInterface } from '../../interface/button-group.interface';
import { ComponentModel } from '../../interface/popup.interface';
import { CommonService } from '../../services/common.service';
import { PdfDownloadService } from '../../services/download-pdf.service';
import { DownloadService } from '../../services/download.service';
import { PopupService } from '../../services/popup.service';
import { RequestAssistanceComponent } from '../request-assistance/request-assistance.component';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { TableColumn, TableSort, TableStatus } from './TableColumn';

@Component({
    selector: 'app-custom-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class TableComponent extends SharedComponentConstantData implements OnInit, AfterViewInit {

    dateFormat = dateFormatConfig.dateFormat;
    mediumDateFormat = dateFormatConfig.mediumDate;
    dateTimeFormatMM = dateFormatConfig.dateTimeFormatMM
    longDateFormat = dateFormatConfig.longDate;
    pendingConstantsLbl = pendingConstantsLbl;
    extendedSupportRecommended = this.extnSpprtRecmend;
    learnMorePrimeProtect = this.learnMorePrime;
    learnMoreExtendedAddon = this.learnMoreExtend;
    learnMoreTechConnectAddOn = this.learnMoreTechConnect;
    currentDate = this.formatDate(new Date());
    renewalUniqueID = '';


    /**
     * buttonItem_one - indicates Eligible Auto Pay button info
     */
    buttonItem_one: ButtonGroupInterface = this.buttonItem_eligibleAutoPay;
    /**
     * buttonItem_two - indicates Eligible Extended Support info
     */
    buttonItem_two: ButtonGroupInterface = this.buttonItem_eligibleExtendedSupport;
    /**
     * buttonItem_three - Purchase Extended Support info
     */
    buttonItem_three: ButtonGroupInterface = this.buttonItem_purchaseExtendedSupport;

    /**
     * additionaSupport_button - Eligible for Additional Support
     */
    additionaSupport_button: ButtonGroupInterface = this.buttonItem_eligibleForAdditionalSupport;


    requestAssistance = this.buttonItem_requestAssistance;

    /**
    * buttonItem_three - Purchase Extended Support info
    */
    buttonItem_del: ButtonGroupInterface = this.buttonItem_delete;

    buttonItem_invoice: ButtonGroupInterface = this.buttonItem_inc;

    tableDataSource: any = new MatTableDataSource<any>([]);   // TODO to be checked the lint errors.
    emptyData = new MatTableDataSource([{ empty: "row" }]);
    displayedColumns!: string[];
    @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) matSort: MatSort = new MatSort;

    @Input() isPageable = false;
    @Input() isSortable = false;
    @Input() isFilterable = false;
    @Input() tableColumns: TableColumn[] = [];
    @Input() rowActionIcon!: string;
    @Input() paginationSizes: number[] = [5, 10, 15];
    @Input() defaultPageSize = this.paginationSizes[1];
    @Input() status!: TableStatus[];
    @Input() tableUseType: string = '';
    @Input() secondHeader: string = '';
    @Input() validateKey: boolean = false;
    @Input() learnMore: string = '';
    @Input() isLoading = false;
    @Input() sortBy: Sort = {
        active: '',
        direction: ''
    };
    @Input() totalRecords: number = 0;
    @Input() showFirstLastButtons: boolean = false;
    @Output() redirectTo: EventEmitter<any> = new EventEmitter();
    @Output() sort: EventEmitter<TableSort> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();
    @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowCheck: EventEmitter<any> = new EventEmitter<any>();
    @Output() wizardOpen: EventEmitter<any> = new EventEmitter<any>();
    @Output() page: EventEmitter<any> = new EventEmitter();
    @Output() checkoutSchedule: EventEmitter<TableSort> = new EventEmitter();
    rowData: any = [];
    multicheck: boolean = false;
    selectAllData: any = [];
    arrReadonly: any[] = [];
    selectAllCheck: any[] = [];
    isEmployee!: boolean;
    @Input() isChecked = false;
    clickedElementID!: number | null;
    // this property needs to have a setter, to dynamically get changes from parent component
    @Input() set tableData(data: any[]) {
        this.setTableDataSource(data);
    }

    footerColumns: any = [];
    @Input() checkLine!: number
    @Input() isSearchEnabled = false;
    uniqueIdEnum = UniqueID;
    cartItem = 'empty';
    shoppingCart = RoutingUrlConstants.ORC_CART;
    isImpersonating = false;
    constructor(
        translate: TranslateService,
        private _MatPaginatorIntl: MatPaginatorIntl,
        private downloadService: DownloadService,
        private commonService: CommonService,
        private popupService: PopupService,
        private _snakBar: SnackBarService,
        private router: Router,
        private pdfDownload: PdfDownloadService,
        private renewalService: RenewalsService

    ) {
        super(translate);
    }

    ngOnChanges() {
        if (this.isChecked) {
            this.selectAllCheck = [];
            this.rowData = [];
            this.matPaginator.pageIndex = 0;
        }
        if (this.selectAllCheck.length > 0) {
            this.tableDataSource.data.map((e: any) => {
                this.selectAllCheck.map(ele => {
                    if (ele.assureRenewalUniqueID == e.assureRenewalUniqueID) {
                        e.isSelected = ele.isSelected;
                    }
                })
            })
        }
    }

    ngOnInit(): void {

        this.footerColumns = ['description', 'tariff'];

        const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.dataKey);
        const sortByField = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.sortByField)
            .filter(e => e !== undefined).toString();
        const sortDirection = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.sortDirection)
            .filter(e => e !== undefined).toString() as SortDirection;

        if (this.rowActionIcon) {
            this.displayedColumns = [this.rowActionIcon, ...columnNames];
        }
        else {
            this.displayedColumns = columnNames;
        }
        // const sortState: Sort = { active: this.sortBy.active, direction: this.sortBy.direction };
        // this.matSort.active = sortState.active;
        // this.matSort.direction = sortState.direction;
        // this.matSort.sortChange.emit(sortState);
        this._MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('rows');
        this._MatPaginatorIntl.previousPageLabel = this.translate.instant('prevPage');
        this._MatPaginatorIntl.nextPageLabel = this.translate.instant('nextPage');
        this.isEmployee = this.commonService.isEmployee()!;
        this.isImpersonating = this.commonService.isImpersonating();
    }

    formatDate(date: any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    // we need this, in order to make pagination work with *ngIf
    ngAfterViewInit(): void {
        this.tableDataSource.paginator = this.matPaginator;
        this.tableDataSource.sort = this.matSort;
        // this.matSort.sortChange.subscribe(() => (this.matPaginator.pageIndex = 0));
    }

    setTableDataSource(data: any) {
        this.tableDataSource = new MatTableDataSource(data);
        this.tableDataSource.sort = this.matSort;
        //   this.selectAllCheck = this.tableDataSource.data;
        if (this.isSearchEnabled) {
            this.tableDataSource.paginator = this.matPaginator;
            this.matPaginator.firstPage();
        }
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }

    sortTable(sortParameters: TableSort) {
        sortParameters.active = this.tableColumns.find(c => c.dataKey === sortParameters.active)?.dataKey;
        this.sort.emit(sortParameters);
    }

    emitRowAction(row: any, str?: any) {
        let inputObj = { ...row, selectedButtonVal: str, purchaseBtn: this.buttonItem_three };
        this.rowAction.emit(inputObj);
        this.renewalUniqueID = row.assureRenewalUniqueID;
        localStorage.setItem('rc', row.renewalContractNumber);
        localStorage.setItem("renewalUniqueID", row.assureRenewalUniqueID);

    }

    buttonClickEvent(event: any, element?: any) {
        localStorage.setItem("renewalUniqueID", this.renewalUniqueID);
        this.openPopupDialog(element);
    }

    openPopupDialog(element?: any) {
        const requestAssistance = {
            type: 'renewal-request-assistance',
            renewalCategory: pendingConstantsLbl.active,
            company_name: element.companyName!
        }
        const popupData: ComponentModel = {
            componentName: RequestAssistanceComponent,
            data: requestAssistance
        };
        this.popupService.dialogWidth = "large";
        this.popupService.openPopup(popupData);
        this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
            response?.resType.requestAssistance.statusCode ?
                this._snakBar.showSnackBar(response.resType.requestAssistance.statusCode) : '';

        });
    }

    pageChange(e: PageEvent) {
        if (!this.isSearchEnabled) this.page.emit(e);
    }

    rowDelete(row: any) {
        this.delete.emit(row);
    }

    openPopupWizard(row: any) {
        row.activity == 'Change Request' ? this.wizardOpen.emit(true) : undefined;
    }

    updateCustomerReference(ele: any) {
        this.wizardOpen.emit(ele);
    }

    handleCheckbox(row: any, e: any, type: string, useType?: any) {

        if (type == 'rowCheck') {
            if (!this.multicheck) {
                row.isSelected = e.checked;
                if (this.tableUseType == 'detailsAddon') {
                    this.rowData = [row];
                } else {
                    if (e.checked) {
                        this.rowData.push(row);
                        this.selectAllCheck.push(row);
                    }
                    else {
                        const el = this.rowData.find((e: { assureRenewalUniqueID: any; }) =>
                            e.assureRenewalUniqueID === row.assureRenewalUniqueID)
                        if (el) {
                            let list = this.rowData.indexOf(el);
                            this.rowData.splice(list, 1);
                        }

                        const e = this.selectAllCheck.find((e: { assureRenewalUniqueID: any; }) =>
                            e.assureRenewalUniqueID === row.assureRenewalUniqueID)
                        if (e) {
                            let list = this.selectAllCheck.indexOf(el);
                            this.selectAllCheck.splice(list, 1);
                        }
                    }

                }
            }
            else {
                if (!e.checked) {
                    this.selectAllData = [...this.tableDataSource.data.filter((a: { isSelected: any; }) => a.isSelected)];
                    const el = this.selectAllData.find((e: { assureRenewalUniqueID: any; }) => e.assureRenewalUniqueID === row.assureRenewalUniqueID)
                    let list = this.selectAllData.indexOf(el);
                    this.selectAllData.splice(list, 1);
                    this.rowData = this.selectAllData;
                    if (this.selectAllCheck.length > 0) {
                        this.selectAllCheck = this.selectAllCheck.filter((a: any) => a.assureRenewalUniqueID !== row.assureRenewalUniqueID)
                    }

                    else {
                        this.selectAllCheck = this.selectAllData;
                    }

                } else {
                    this.rowData.push(row);
                    this.selectAllCheck.push(row);
                    this.selectAllCheck = [...this.selectAllCheck.reduce((map, obj) => map.set(obj.assureRenewalUniqueID, obj), new Map()).values()];
                }
            }
            this.rowCheck.emit(this.rowData);
        } else {
            this.multicheck = true;
            this.tableDataSource.data = this.tableDataSource.data.map((item: any) => {

                if (item.renewalStatusId != 4 && item.renewalStatusId != 6) {
                    return (
                        {
                            ...item,
                            isSelected: e.checked
                        })
                }

                else {
                    return (
                        {
                            ...item,
                            isSelected: false
                        })
                }
            });

            if (!e.checked) {
                this.rowData = [];
                this.selectAllCheck = this.selectAllCheck.filter((a: any) =>
                    !this.tableDataSource.data.find((b: any) => b.assureRenewalUniqueID === a.assureRenewalUniqueID)
                )
                this.rowCheck.emit([]);

            } else {
                this.selectAllCheck.push(...this.tableDataSource.data.filter((e: { isDisabled: any; }) => !e.isDisabled))
                this.selectAllCheck = [...this.selectAllCheck.reduce((map, obj) => map.set(obj.assureRenewalUniqueID, obj), new Map()).values()];
                this.rowCheck.emit(this.tableDataSource.data);
            };
        }
    }

    routeToNavigation(row: any, id?: any, type?: string) {
        if (id === this.clickedElementID) {
            this.clickedElementID = null;
        } else {
            this.clickedElementID = id;
        }
        type != "activity" ? this.redirectTo.emit(row) : '';
    }

    isAllSelected() {
        this.arrReadonly = this.tableDataSource.data.filter((e: { isDisabled: any; }) => !e.isDisabled);
        return this.arrReadonly.every((item: { isSelected: any; }) => item.isSelected);
    }

    isAnySelected() {
        return this.tableDataSource.data.some((item: { isSelected: any; }) => item.isSelected)
    }

    get noDataFound() {
        return this.tableDataSource.data.length === 0
    }

    isReadOnlyRc(e: any) {
        e.renewalStatusId == 5 ? this.cartItem = 'cart' : this.cartItem = 'empty';
        return e.renewalStatusId == 4 || e.renewalStatusId == 6;
    }

    downloadInvoice(e: any) {
        this.isLoading = true;
        const renewalUniqueID = e.assureRenewalUniqueID;
        const invoiceNumber = e.invoiceNumber;
        const renewalCategoryId = e.renewalCategoryId!;
        this.renewalService
            .downloadInvoice(renewalUniqueID, renewalCategoryId)
            .subscribe({
                next: (data) => {
                    this.isLoading = false;
                    !data.invoice.isSuccess ? this._snakBar.showSnackBar(data.invoice.statusCode) : '';
                    (data.invoice.isSuccess && data.invoice.statusCode == 200 && data.invoice.result != null) ? this.pdfDownload.downloadPdf(data.invoice.result, invoiceNumber) : this._snakBar.showSnackBar('');
                },
                error: (err) => { this.isLoading = false }
            })
    }

    isReadOnlyIgnoreCart(e: any) {
        return e.renewalStatusId == 4 || e.renewalStatusId == 6;
    }

    downloadAttachment(fileURL: string, fileName: string) {
        this.downloadService
            .download(fileURL)
            .subscribe(blob => {
                const a = document.createElement('a')
                const objectUrl = URL.createObjectURL(blob)
                a.href = objectUrl
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);
            })
    }
    getDetailsByRcId(rm?: string, rcNumber?: string, uniqueId?: string) {
        const data = {
            rcNumber: rcNumber,
            uniqueId: uniqueId,
            rm: rm
        }
        this.redirectTo.emit(data);
    }
    routeToCart() {
        this.router.navigate([this.shoppingCart]);
    }
    routeToURL(type: string, event?: any) {
        type == 'cart' ? this.router.navigate([this.shoppingCart]) :
            type == 'order' ? (this.checkoutSchedule.emit(event)) : '';
    }
}
