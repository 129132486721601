import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedComponentConstantData } from "../../constants/shared-data.constants";
import { ButtonGroupInterface } from "../../interface/button-group.interface";
import { NotificationBannerInterface } from "../../interface/notification-banner.interface";

@Component({
    selector: "app-notification-banner",
    templateUrl: "./notification-banner.component.html",
    styleUrls: ["./notification-banner.component.sass"],
    encapsulation: ViewEncapsulation.None
})
export class NotificationBannerComponent extends SharedComponentConstantData {
    @Input() bannerInfo: NotificationBannerInterface = this.bannerInformation;
    @Output() onButtonClick: EventEmitter<any> = new EventEmitter();
    @Output() onCloseClick: EventEmitter<any> = new EventEmitter();
    showBanner: boolean = true;
    constructor(translate: TranslateService) { super(translate) }
    closeButtonConfig: ButtonGroupInterface = this.closeBtnConfig;

    onNotificationButtonClick(event: any) {
        this.onButtonClick.emit(event);
    }
    onNotificationButtonCloseClick(event: any) {
        event == "close-banner"
            ? (this.showBanner = false)
            : (this.showBanner = true);
        this.onCloseClick.emit(event);
    }
}
