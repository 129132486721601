import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OAuthErrorEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { otAuthConfig } from '../constants/oidc.constants';
import { RoutingUrlConstants } from '../constants/routing-url.constants';
import { masterGet } from '../state/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    isUserProfile$ = new Subject<any>();
    isProfileValidate = false;
    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
        this.isAuthenticated$,
        this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));

    public navigateToLoginPage() {
        // TODO: Remember current URL
        this.router.navigateByUrl(RoutingUrlConstants.ORC_LANDING);
    }

    constructor(
        private oauthService: OAuthService,
        private oauthStorage: OAuthStorage,
        private router: Router,
        private store: Store
    ) {
        this.oauthService.configure(otAuthConfig);
        this.oauthService.setStorage(sessionStorage);
        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error('OAuthErrorEvent Object:', event);
            } else {
                console.warn('OAuthEvent Object:', event);
            }
        });
        window.addEventListener('storage', (event) => {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }
            console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
            this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

            if (!this.hasValidToken || this.identityClaims == null) {
                this.clearStorage();
                this.navigateToLoginPage();
            }
        });
        this.oauthService.events
            .subscribe(_ => {
               this.hasValidToken()? this.isAuthenticatedSubject$.next(true):this.isAuthenticatedSubject$.next(false);
                
            });
        this.hasValidToken()? this.isAuthenticatedSubject$.next(true):this.isAuthenticatedSubject$.next(false);
        this.oauthService.events
            .pipe(filter((e) => ["token_received"].includes(e.type)))
            .subscribe((e) => {
                if (this.oauthStorage.getItem("id_token_claims_obj")!) {
                    if (!this.isProfileValidate) {
                        this.store.dispatch(masterGet());
                        this.isUserProfile$.next(JSON.stringify(this.identityClaims));
                    }
                    else {
                        this.isUserProfile$.next(false);
                    }

                }
            });

        this.oauthService.events.pipe(filter(e => e.type == 'token_expires'))
            .subscribe(e => {
                this.isProfileValidate = true;
                this.oauthService.refreshToken();
            });
        this.oauthService.events
            .pipe(filter(e => ['session_terminated', 'session_error', 'token_revoke_error'].includes(e.type)))
            .subscribe(e => {
                this.isProfileValidate = true;
                this.isUserProfile$.next(false);
                this.clearStorage();
                this.navigateToLoginPage();
            });

    }

    public runInitialLoginSequence(): Promise<void> {
        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
        }
        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        //return this.oauthService.loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        //   .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        return this.oauthService.tryLogin()

            .then(() => {
                if (this.hasValidToken()) {

                    return Promise.resolve();
                }
                return Promise.resolve();
            })

            .then(() => {
                this.isDoneLoadingSubject$.next(true);
                // Check for the strings 'undefined' and 'null' just to be sure. Our current
                // login(...) should never have this, but in case someone ever calls
                // initImplicitFlow(undefined | null) this could happen.
                if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
                    let stateUrl = this.oauthService.state;
                    if (!stateUrl.startsWith('/')) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    if (this.hasValidToken() && this.getEmail()! && stateUrl === RoutingUrlConstants.ORC_LANDING || stateUrl === "/") {
                        this.router.navigateByUrl(RoutingUrlConstants.ORC_HOME);
                    }
                    else {
                        if (this.hasValidToken()) {
                            this.router.navigateByUrl(stateUrl);
                        }

                    }
                }
            })
            .catch(() => this.isDoneLoadingSubject$.next(true));
    }

    public login(targetUrl?: string) {
        this.oauthService.initLoginFlow(targetUrl || this.router.url);

    }

    logout() {
        
        return this.oauthService.revokeTokenAndLogout().then(() => {
            if(!this.hasValidToken()){
                this.clearStorage();
                this.isAuthenticatedSubject$.next(false);
                this.isUserProfile$.next(false);
            }else{
                this.refreshToken;
            }
        })

    }
    hasValidToken() { return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken; }
    public get accessToken() { return this.oauthService.getAccessToken(); }
    refreshToken() { return this.oauthService.refreshToken();}
    public get getRefreshToken() { return this.oauthService.getRefreshToken(); }
    public get identityClaims() { return this.oauthService.getIdentityClaims(); }
    public get idToken() { return this.oauthService.getIdToken(); }
    public get logoutUrl() { return this.oauthService.logoutUrl; }
    public clearStorage() {
        window.localStorage.clear();
        window.sessionStorage.clear();
    }

    public get userInfo() { return this.oauthService.loadUserProfile(); }
    getEmail(): string {
        return this.identityClaims
            ? (this.identityClaims as any)['preferred_username']
            : '-';
    }
    getLanguage(): string {
        return this.identityClaims
            ? (this.identityClaims as any)['language']
            : '-';
    }
    getCountry(): string {
        return this.identityClaims
            ? (this.identityClaims as any)['country']
            : '-';
    }
    getName(): string {
        return this.identityClaims
            ? (this.identityClaims as any)['name']
            : '-';
    }
    isEmployee() {
        const userObj = this.identityClaims;
        return this.identityClaims
            && (this.identityClaims as any)['userType'] == "employee" ? true : false;
    }
}
