import { DatePipe } from "@angular/common";
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { FormFieldsInterface } from "../../interface/form-fields.interface";
import { LocalizedDatePipe } from "../../pipes/localized-date-pipe/localized-date.pipe";
import { ControlValueAccessorConnector } from "./form-fields.connector";
@Component({
    selector: "app-form-fields",
    templateUrl: "./form-fields.component.html",
    styleUrls: ["./form-fields.component.sass"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormFieldsComponent,
            multi: true
        },
        DatePipe,
        LocalizedDatePipe
    ]
})
export class FormFieldsComponent extends ControlValueAccessorConnector implements AfterViewChecked {
    /**
     * stateForm holds the searchValue in form
     */
    stateForm = this._formBuilder.group({
        stateGroup: '',
        selectFilter: ['']
    });

    @ViewChild('selectFilter') selectFilter!: ElementRef;

    /**
     * stateGroups holds the search Group object passed from parent component
     */
    @Input() stateGroups: any[] = [];

    selectedOptionObj: any;
    @Input() isLoading = false;
    /**
     * searchTextboxVale on input type
     */
    searchTextboxValue: any;
    autoSearch: any[] = [];
    stateGroupOptions: Observable<any[]> | any;
    stateGroup: Observable<any[]> | any;
    @Output() searchInputValue: EventEmitter<any> = new EventEmitter();
    @Output() selectedDropdown: EventEmitter<any> = new EventEmitter();
    @Output() openDropdown: EventEmitter<any> = new EventEmitter();
    @Output() getSearchData: EventEmitter<any> = new EventEmitter();
    @Input() fieldConfig: FormFieldsInterface = {
        label: "Textbox Label",
        cssClass: "custom-cls",
        maxLength: "10",
        minLength: "5",
        type: "textbox",
        id: "",
        disabled: true,
        required: false
    };
    base64File = null;
    filename = null;
    fileSize!: any

    private _stateGroups: any[] = [];

    @Output() fileObject: EventEmitter<any> = new EventEmitter();
    @Output() amountValue: EventEmitter<any> = new EventEmitter();
    noDataFound: boolean = false;
    @Input() cntCode: any;
    @Input() lngCode: any;
    constructor(
        injector: Injector,
        private datePipe: DatePipe,
        private localozedDatePiep: LocalizedDatePipe,
        private cdRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private translate: TranslateService,) {
        super(injector);
    }

    ngAfterViewChecked(): void {
        this._stateGroups = this.stateGroups;
        this.cdRef.detectChanges();
    }

    ngOnChanges() {
        if (this.stateGroups[0]?.letter === 'Impersonation') {
            this.noDataFound = false;
        } else {
            this.autoSearch = this._filterGroup(this.searchTextboxValue);
            (this.searchTextboxValue && this.searchTextboxValue.length > 2) && this.autoSearch.length < 1 ?
                this.noDataFound = true : this.noDataFound = false;
        }
    }

    onDropdownSelect() {
        this.selectFilter.nativeElement.focus();
        this.selectFilter.nativeElement.value = '';
        this.openDropdown.emit(this.selectFilter.nativeElement.value);
    }

    onSelectOption(event: any) {
        let selectedValue = event.value;
        this.selectedOptionObj = this.stateGroups.find((x) => x.value == selectedValue);
    }

    get noResultLbl() {
        return this._stateGroups.length === 0;
    }

    /**
     * Handles the key down event with MatSelect.
     * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
     * @param {KeyboardEvent} event
     */
    _handleKeydown(event: KeyboardEvent) {
        if ((event.key && event.key.length === 1)) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }

    }

    ngOnInit() {
        // this.stateGroupOptions = this.stateForm.get('stateGroup')!.valueChanges.pipe(
        //     startWith(''),
        //     map(value => this._filterGroup(value || ''))
        // );
        // this.stateGroups = this._filterGroup(this.searchTextboxValue)
        this._stateGroups = this.stateGroups;
    }

    private _filterGroup(value: string) {
        if (value?.length >= 3) {
            return this.stateGroups
                .map(group => ({ letter: group.letter, names: _filter(group.names, value) }))
                .filter(group => group.names.length > 0);
        }
        else {
            return this.autoSearch = [];
        }
    }

    onFileSelect(e: any): void {
        try {
            const file = e.target.files[0];
            this.fileSize = (file.size / (1024 * 1024));
            const fReader = new FileReader()
            fReader.readAsDataURL(file)
            fReader.onloadend = (_event: any) => {
                this.filename = file.name;
                this.base64File = _event.target.result;
                const fileDetails = {
                    name: this.filename,
                    base64: this.base64File,
                    size: this.fileSize
                }
                this.fileObject.emit(fileDetails)
                e.target.value = ''
            }

        } catch (error) {
            this.filename = null;
            this.base64File = null;
        }
    }

    removeFile() {
        this.fileObject.emit(null);
    }

    clearInput() {
        this.control.setValue("");
    }

    onBlurMethod(event: any) {
        this.searchTextboxValue = event.target.value.trim();
        if (this.searchTextboxValue == '' || this.searchTextboxValue < 3)
            this.autoSearch = [];
        this.searchInputValue.emit(this.searchTextboxValue);

    }

    onOptionSelection(group: string, value: any) {
        const selectObj = {
            type: group,
            value: value
        }
        this.selectedDropdown.emit(selectObj);
    }

    optionSelection(group: any) {
        this.selectedDropdown.emit(group);
    }

    selectionChange(value: string) {
        this.selectedDropdown.emit(value);
    }

    searchResults() {
        this.getSearchData.emit(this.searchTextboxValue);
    }

    getOptionLabel(record: any, fieldId: any = '') {
        if (record.label) {
            if (fieldId === 'impersonate-user') {
                return record.value;
            }
            return record.label;
        }
        return (record.renewalContractNumber +
            (record.customer ? ", " : "") +
            record.customer +
            (record.startDate ? ", " : "") +
            this.localozedDatePiep.transform(record.startDate, 'mediumDate', record.countryCode, record.languageCode) +
            (record.endDate ? " - " : "") +
            this.localozedDatePiep.transform(record.endDate, 'mediumDate', record.countryCode, record.languageCode) +
            (record.contractNumber ? ", " : '') +
            record.contractNumber +
            (record.invoiceNumber ? ", " : "") +
            record.invoiceNumber +
            (record.customerReference ? ", " : "") +
            record.customerReference);

    }

    formatCurrency_TaxableValue(event: any) {
        this.amountValue.emit(event);
    }
    getTranslatedText(msg: any) {
        return this.translate.instant(msg);
    }
}

export const _filter = (opt: any[], value: any): any[] => {
    const filterValue = value?.toLowerCase();
    return opt?.filter(item =>
        (item.renewalContractNumber ? item.renewalContractNumber.toLowerCase().includes(filterValue) : false) ||
        (item.contractNumber ? item.contractNumber.toLowerCase().includes(filterValue) : false) ||
        (item.customerReference ? item.customerReference.toLowerCase().includes(filterValue) : false) ||
        (item.invoiceNumber ? item.invoiceNumber.toLowerCase().includes(filterValue) : false) ||
        (item.label ? item.label.toLowerCase().includes(filterValue) : false)
    );

};