import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class OTCookieService {
  constructor(private cookieService: CookieService) {}
  /**
   * NGX Cookie Storage Service Call
   */

  /**
   * This method is to set the cookie with specific Key values in Cookie Storage Service
   * @param a Cookie key
   * @param b Cookie value , it could be any kind of value object | array | boolean | NULL | undefined
   */
  setCookie(a: any, b: any) {
    this.cookieService.set(a, b);
  }

  /**
   * This method is to delete the specific cookie from Cookie Storage Service.
   * @param a Delete specific cookie with cookie key value
   */
  deleteCookie(a: any) {
    this.cookieService.delete(a);
  }

  /**
   * This method will check the specific cookie isExist in Cookie Storage Service.
   * @param a Check the specific cookie is available in the Cookie Storage Service.
   */
  checkCookie(a: any) {
    this.cookieService.check(a);
  }

  /**
   * This method will delete all cookie at once.
   */
  deleteAll() {
    this.cookieService.deleteAll();
  }

  /**
   *
   * @param a Get specific cookie by key value.
   */
  getByCookie(a: any) {
    return this.cookieService.get(a);
  }

  /**
   * Get all cookies from Cookie Storage Service
   */
  getAllCookie() {
    return this.cookieService.getAll();
  }

  /**
   * Localization of NGX Cookie Storage Service
   */

  /**
   * This method is to set the cookie with specific Key values in Cookie Storage Service  - setCookie(a, b);
   * @param a Cookie key
   * @param b Cookie value , it could be any kind of value object | array | boolean | NULL | undefined
   */
  addCookie(a: any, b: any) {
    return this.setCookie(a, b);
  }

  /**
   * This method is to delete the specific cookie from Cookie Storage Service.
   * @param a Delete specific cookie with cookie key value
   */
  removeCookie(a: any) {
    return this.deleteCookie(a);
  }

  /**
   * This method will delete all cookie at once.
   */
  removeAllCookie() {
    return this.deleteAll();
  }

  /**
   * This method will check the specific cookie isExist in Cookie Storage Service.
   * @param a Check the specific cookie is available in the Cookie Storage Service.
   */
  check(a: any) {
    return this.checkCookie(a);
  }

  /**
   *
   * @param a Get specific cookie by key value.
   */

  fetchCookie(a: any) {
    return this.getByCookie(a);
  }

  /**
   * Get all cookies from Cookie Storage Service
   */
  fetchAllCookie() {
    return this.getAllCookie();
  }
}
