import { Component, Inject, ViewEncapsulation } from "@angular/core";
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef
} from "@angular/material/snack-bar";

@Component({
    selector: "app-basic-snackbar",
    templateUrl: "./snack-bar.component.html",
    styleUrls: ["./snack-bar.component.sass"],
    encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
    constructor(
        public sbRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }
}
