// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    SERVER_URL:  "https://renew-dev.opentext.com/bff/api/",
    OT_IAM_MANAGE_ACCOUNT: 'https://ui.qa.otiam.opentext.com/#/manageProfile',
    OT_IAM_SIGNUP: 'https://ui.qa.otiam.opentext.com/#/walkup',
    OT_IAM_LOGOUT_URL: 'https://login.qa.otiam.opentext.com/oidc/logout.do',
    OT_IAM_API_URL: 'https://api.us1.pp.covisint.com/',
    OT_IAM_ISSUER_URL: 'https://api.us1.pp.covisint.com/openid/v1/PP-IAM-QA',
    OT_IAM_CLIENT_ID: 'cDOHMb43yaKH7eyaHOK3eEve9FAb2HUC',
    OT_IAM_LOCAL_CLIENT_ID: 'AxCHlKYQlnXw4uyBkoCLBWWiB5ZvGshY',
    OT_IAM_SCOPE: 'openid profile email',
    OT_PAY_MY_BILL: 'https://epayqa.opentext.com',
    OT_EPAY_REFERRER: 'ORC-DEV'
};