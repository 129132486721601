import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { UniqueID } from 'src/app/shared/enums/unique-id.enum';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';
import { FormFieldsInterface } from 'src/app/shared/interface/form-fields.interface';
import { ComponentModel } from 'src/app/shared/interface/popup.interface';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ReportPopupComponent } from '../../orc-renewals/sub-modules/pending/components/popup/report-popup.component';
import { PendingSummary } from '../../orc-renewals/sub-modules/pending/interface/pending';
import { ReportConstant } from '../constants/report.constant';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/DD/yyyy'
    },
    display: {
        dateInput: 'MM/DD/yyyy',
        monthYearLabel: 'MMM-YYYY'
    },
};

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.sass'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        DatePipe,
    ],
})
export class GenerateReports extends ReportConstant implements OnInit {
    checked = [];
    renewalTypesList = this.renewalTypes;
    reportTypeList = this.reportTypes;
    reportMethodList = this.renewalMethod;
    extContractConfig: FormFieldsInterface = this.textContractConfig_field;
    extentitlementConfig: FormFieldsInterface = this.textentitlementConfig_field;
    extBillToShipToConfig: FormFieldsInterface = this.textBillToShipToConfig_field;
    createReportConfig: ButtonGroupInterface = this.createReportnBtnConfig;
    filterForm!: FormGroup;
    endDateForm!: FormGroup;
    selectReportType: any;
    summaryTooltip: string = this.translate.instant('summaryToolTip');
    detailTooltip: string = this.translate.instant('detailToopTip');
    disabledStartDate = true;
    disabledEndDate = true;
    endDateChecked = false;
    startDateChecked = false;
    tempRenewalTypeArray = new Set();
    temRenewalMethodArray = new Set();
    isSelectedAll: boolean = true;
    selectAll: string = '0';
    fromDate_start!: Date;
    toDate_start!: Date;
    fromDate_end!: Date;
    toDate_end!: Date;
    data: PendingSummary[] = [];
    reportDateFormat = this.dateFormat;
    enableAccountGroupForm: boolean = false;
    constructor(
        translate: TranslateService,
        public fb: FormBuilder,
        private popupService: PopupService,
        private datePipe: DatePipe,

    ) {
        super(translate);
    }

    ngOnInit() {
        this.selectReportType = this.summary;
        this.filterForm = this.fb.group({
            contractData: [''],
            entitilementGroup: [''],
            billToShipTo: [''],
            startDateFrom: [''],
            startDateTo: ['']
        });
        this.endDateForm = this.fb.group({
            endDateFrom: [''],
            endDateTo: ['']
        });
        this.renewalTypesList.forEach((e: any) => {
            this.tempRenewalTypeArray.add(e.value);
        });
        this.reportMethodList.forEach((e: any) => {
            this.temRenewalMethodArray.add(e.value);
        });
    }
    toggleCheckboxAll(event: MatCheckboxChange) {
        const checked = event.checked;
        this.renewalTypesList.forEach((item: any) => item.isChecked = checked);
        this.isSelectedAll = checked;
        checked ? this.selectAll = '0' : this.selectAll = 'all';
        this.isSelectedAll ? '' : this.tempRenewalTypeArray.clear();
        this.renewalTypesList.forEach((e: any) => {
            e.isChecked ? this.tempRenewalTypeArray.add(e.value) : '';
        });
        !event.checked ? this.showInformationModel('report', event.source.value) : '';
        this.resetForm();
    }
    getRenewalCheckbox(selectedOption: MatCheckboxChange, type: string, valueType?: string) {
        if (type == 'rType') {
            this.renewalTypesList.forEach((e: any) => {
                e.isChecked ? this.tempRenewalTypeArray.add(e.value) : '';
            });
            selectedOption.checked ?
                this.tempRenewalTypeArray.add(selectedOption.source.value!) :
                this.tempRenewalTypeArray.delete(selectedOption.source.value);
            this.tempRenewalTypeArray.size == this.renewalTypesList.length ?
                (this.isSelectedAll = true, this.selectAll = '0') : (this.isSelectedAll = false, this.selectAll = 'all');
            this.tempRenewalTypeArray.size < 1 ? this.showInformationModel('report', selectedOption.source.value, type) : '';
            this.resetForm();
        }
        else if (type == 'rMethod') {
            selectedOption.checked ?
                this.temRenewalMethodArray.add(selectedOption.source.value) :
                this.temRenewalMethodArray.delete(selectedOption.source.value);
            this.temRenewalMethodArray.size < 1 ? this.showInformationModel('method', selectedOption.source.value, type) : '';
        }
    }

    enableStartDatePicker(event: any, flag: string) {
        event.checked && flag == 'startDate' ? (this.disabledStartDate = false, this.startDateChecked = true) :
            !event.checked && flag == 'startDate' ?
                (this.filterForm.controls['startDateFrom'].setValue(''),
                    this.filterForm.controls['startDateTo'].setValue(''), this.disabledStartDate = true, this.startDateChecked = false) :
                event.checked && flag == 'endDate' ? (this.disabledEndDate = false, this.endDateChecked = true) :
                    !event.checked && flag == 'endDate' ? (this.endDateForm.controls['endDateFrom'].setValue(''),
                        this.endDateForm.controls['endDateTo'].setValue(''), this.disabledEndDate = true, this.endDateChecked = false) : '';
        event.checked && flag == 'startDate' ? this.setDateRanges(flag) :
            flag == 'startDate' ? (this.fromDate_start = new Date(), this.toDate_start = new Date()) :
                event.checked && flag == 'endDate' ? this.setDateRanges(flag) :
                    flag == 'endDate' ? (this.fromDate_end = new Date(), this.toDate_end = new Date()) : '';
    }
    // enableEndDatePicker(event: any, flag: string) {
    //     event.checked && flag == 'endDate' ? (this.disabledEndDate = false, this.endDateChecked = true) :
    //         !event.checked && flag == 'endDate' ? (this.endDateForm.controls['endDateFrom'].setValue(''),
    //             this.endDateForm.controls['endDateTo'].setValue(''), this.disabledEndDate = true, this.endDateChecked = false) : '';
    //     event.checked ? this.setDateRanges(flag) :
    //         (this.fromDate_end = new Date(), this.toDate_end = new Date());
    // }
    setDateRanges(flag: string) {
        flag == 'startDate' ?
            (this.fromDate_start = new Date(), this.toDate_start = new Date()) :
            flag == 'endDate' ?
                (this.fromDate_end = new Date(), this.toDate_end = new Date()) : '';
        if (this.selectAll == '0') {
            if (flag == 'startDate') {
                this.fromDate_start.setDate(this.fromDate_start.getDate() - this.dateRange);
                this.toDate_start.setDate(this.toDate_start.getDate() + this.dateRange);
                this.filterForm.controls['startDateFrom'].setValue(this.fromDate_start);
                this.filterForm.controls['startDateTo'].setValue(this.toDate_start);
            }
            else if (flag == 'endDate') {
                this.fromDate_end.setDate(this.fromDate_end.getDate() - this.dateRange);
                this.toDate_end.setDate(this.toDate_end.getDate() + this.dateRange);
                this.endDateForm.controls['endDateFrom'].setValue(this.fromDate_end);
                this.endDateForm.controls['endDateTo'].setValue(this.toDate_end);
            }

        }
        else {
            if (this.tempRenewalTypeArray.size == 1) {
                if (this.tempRenewalTypeArray.has(UniqueID.Pending)) {
                    if (flag == 'startDate') {
                        this.fromDate_start = new Date();
                        this.toDate_start.setDate(this.fromDate_start.getDate() + this.dateRange);
                        this.filterForm.controls['startDateFrom'].setValue(this.fromDate_start);
                        this.filterForm.controls['startDateTo'].setValue(this.toDate_start);
                    }
                    else if (flag == 'endDate') {
                        this.fromDate_end = new Date();
                        this.toDate_end.setDate(this.fromDate_end.getDate() + this.dateRange);
                        this.endDateForm.controls['endDateFrom'].setValue(this.fromDate_end);
                        this.endDateForm.controls['endDateTo'].setValue(this.toDate_end);
                    }
                }
                else if (this.tempRenewalTypeArray.has(UniqueID.Active) || this.tempRenewalTypeArray.has(UniqueID.History)) {
                    if (flag == 'startDate') {
                        this.fromDate_start.setDate(this.fromDate_start.getDate() - this.dateRange);
                        this.toDate_start.setDate(this.toDate_start.getDate());
                        this.filterForm.controls['startDateFrom'].setValue(this.fromDate_start);
                        this.filterForm.controls['startDateTo'].setValue(this.toDate_start);
                    }
                    else if (flag == 'endDate') {
                        this.fromDate_end.setDate(this.fromDate_end.getDate() - this.dateRange);
                        this.toDate_end.setDate(this.toDate_end.getDate());
                        this.endDateForm.controls['endDateFrom'].setValue(this.fromDate_end);
                        this.endDateForm.controls['endDateTo'].setValue(this.toDate_end);
                    }
                }
            }
            else if (this.tempRenewalTypeArray.size > 1) {
                if (this.tempRenewalTypeArray.has(UniqueID.Active) && this.tempRenewalTypeArray.has(UniqueID.History)) {
                    if (flag == 'startDate') {
                        this.fromDate_start.setDate(this.fromDate_start.getDate() - this.dateRange);
                        this.toDate_start.setDate(this.toDate_start.getDate());
                        this.filterForm.controls['startDateFrom'].setValue(this.fromDate_start);
                        this.filterForm.controls['startDateTo'].setValue(this.toDate_start);
                    }
                    else if (flag == 'endDate') {
                        this.fromDate_end.setDate(this.fromDate_end.getDate() - this.dateRange);
                        this.toDate_end.setDate(this.toDate_end.getDate());
                        this.endDateForm.controls['endDateFrom'].setValue(this.fromDate_end);
                        this.endDateForm.controls['endDateTo'].setValue(this.toDate_end);
                    }
                }
                else {
                    if (flag == 'startDate') {
                        this.fromDate_start.setDate(this.fromDate_start.getDate() - this.dateRange);
                        this.toDate_start.setDate(this.toDate_start.getDate() + this.dateRange);
                        this.filterForm.controls['startDateFrom'].setValue(this.fromDate_start);
                        this.filterForm.controls['startDateTo'].setValue(this.toDate_start);
                    }
                    else if (flag == 'endDate') {
                        this.fromDate_end.setDate(this.fromDate_end.getDate() - this.dateRange);
                        this.toDate_end.setDate(this.toDate_end.getDate() + this.dateRange);
                        this.endDateForm.controls['endDateFrom'].setValue(this.fromDate_end);
                        this.endDateForm.controls['endDateTo'].setValue(this.toDate_end);
                    }
                }
            }
        }
    }
    showInformationModel(popupData: any, checkedvalue?: any, type?: string) {
        type == 'rType' && checkedvalue ? this.tempRenewalTypeArray.clear() :
            type == 'rMethod' && checkedvalue ? this.temRenewalMethodArray.clear() : "";
        const dailogData: ComponentModel = {
            componentName: ReportPopupComponent,
            data: popupData
        };
        this.popupService.dialogWidth = 'small';
        this.popupService.openPopup(dailogData);
        this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
            if (popupData == 'report' && checkedvalue && checkedvalue == "0") {
                this.isSelectedAll = true;
                this.selectAll = "0";
                this.renewalTypesList.forEach((e: any, i: number) => {
                    this.renewalTypesList[i].isChecked = true;
                    this.tempRenewalTypeArray.add(e.value);
                })
            }
            else {
                if (popupData == 'report') {
                    checkedvalue ? this.tempRenewalTypeArray.add(checkedvalue) : '';
                    checkedvalue == UniqueID.Pending ?
                        this.renewalTypesList[0].isChecked = true :
                        checkedvalue == UniqueID.Active ?
                            this.renewalTypesList[1].isChecked = true :
                            checkedvalue == UniqueID.History ?
                                this.renewalTypesList[2].isChecked = true : '';
                }
                else if (popupData == 'method') {
                    checkedvalue ? this.temRenewalMethodArray.add(checkedvalue) : '';
                    checkedvalue == UniqueID.Annual ?
                        this.reportMethodList[0].isChecked = true :
                        checkedvalue == UniqueID.AutoInvoice ?
                            this.reportMethodList[1].isChecked = true : '';
                }

            }
        });
    }

    resetForm() {
        this.filterForm.reset();
        this.endDateForm.reset();
        this.startDateChecked = false;
        this.endDateChecked = false;
        this.disabledStartDate = true;
        this.disabledEndDate = true;
    }

    onButtonClick() {
        const payload = {
            renewalCategoryID: Array.from(this.tempRenewalTypeArray).filter(Number),
            reportType: this.selectReportType,
            startDateFrom: this.formatDate(this.gettDate(this.filterForm.get('startDateFrom')?.value, this.minDefaultDate)),
            startDateTo: this.formatDate(this.gettDate(this.filterForm.get('startDateTo')?.value, this.maxDefaultDate)),
            endDateFrom: this.formatDate(this.gettDate(this.endDateForm.get('endDateFrom')?.value, this.minDefaultDate)),
            endDateTo: this.formatDate(this.gettDate(this.endDateForm.get('endDateTo')?.value, this.maxDefaultDate)),
            contractNumber: this.filterForm.get('contractData')?.value,
            entitlementGroupNumber: this.filterForm.get('entitilementGroup')?.value,
            billToAccount: this.filterForm.get('billToShipTo')?.value,
            renewalMethodID: Array.from(this.temRenewalMethodArray)
        };
        this.showInformationModel(payload);
    }
    formatDate(value: any) {
        return value ? this.datePipe.transform(value, 'yyyy-MM-dd') : '';
    }
    gettDate(formDate: string, defaultDate: string) {
        return !formDate ? defaultDate : formDate;
    }

}
