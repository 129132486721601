import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MiddlewareUrls } from 'src/app/modules/orc-renewals/sub-modules/pending/constants/middleware-urls';
import { MastersResponse } from '../interface/master.interface';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root'
})
export class MasterService {

    constructor(private baseService: BaseApiService) { }

    getMasters(): Observable<MastersResponse> {
        return this.baseService.getApiCall(MiddlewareUrls.getMasters);
    }

}
