import { Component, Input } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.sass']
})
export class SummaryComponent {
    @Input() headerText: string | undefined;
    @Input() content: string | undefined;
    @Input() iconPrefix!: IconPrefix;
    @Input() iconName!: IconName;
}
