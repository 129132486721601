import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DefaultTableDataConstants } from 'src/app/modules/orc-renewals/sub-modules/shared/constants/default-table-data.constant';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';
import { RoutingUrlConstants } from './routing-url.constants';
@Injectable()
export class HeaderFooterDataConstants extends DefaultTableDataConstants {

    constructor(translate: TranslateService) {
        super(translate);
    }

    HELP_LINK = "https://support.opentext.com/csm";
    ACTIVE = "Active";
    PENDING = "Pending";
    SUMMARY = "Summary";
    showMaxNotifications = 3;
    product_img = "https://www.opentext.com/assets/images/products-solutions/business-network/opentext-image-lp-opentext-swift-service-bureau-en.png";
    tabsActiveCount = 3;
    displayLocationHOME = "Home";
    displayLocationPENDING = "PendingSummary";
    displayLocationACTIVE = "ActiveSummary";
    displayLocationHISTORY = "HistorySummary";
    renewTodayButton = this.translate.instant("renewTodayBtn");
    viewDetailsButton = this.translate.instant("viewDetailsBtn");
    requestAssitanceButton = this.translate.instant("requestAssitanceBtn");
    renewalTabTypes: any = {
        UPCOMING_SUPPORT_RENEWALS: 'upcoming-support-renewals',
        ACTIVE_SUPPORT_RENEWALS: 'active-support-renewals',
        SUPPORT_RENEWALS_HISTORY: 'support-renewals-history',
        SAVED_CART: 'home-saved-cart',
        USER_GROUPS: 'home-user-group',
        RUN_REPORT: 'home-run-report'
    };
    headerMenuItems = [
        {
            label: 'pendingRenewals',
            name: 'pending-renewals',
            routeUrl: RoutingUrlConstants.ORC_PENDING_SUMMARY,
            title: 'upcomingSupportRenewals'
        },
        {
            label: 'activeContracts',
            name: 'active-contracts',
            routeUrl: RoutingUrlConstants.ORC_ACTIVE_SUMMARY,
            title: 'activeSuppotRenewals'
        },
        {
            label: 'renewalHistory',
            name: 'renewal-history',
            routeUrl: RoutingUrlConstants.ORC_HISTORY_SUMMARY,
            title: 'supportRenewalHistory'
        },
        {
            label: 'reporting',
            name: 'reporting',
            routeUrl: RoutingUrlConstants.ORC_REPORT,
            title: 'reporting'
        }
    ];
    profileMenuList = [
        {
            label: 'manageAccount',
            name: 'manage-account',
            isDisable: false
        },
        {
            label: 'requestAssistance',
            name: 'request-assistance',
            isDisable: false
        },
        {
            label: 'help',
            name: 'help',
            isDisable: false
        },
        {
            label: 'payMyBill',
            name: 'pay-my-bill',
            isDisable: false
        },
        {
            label: 'signOut',
            name: 'sign-out',
            isDisable: false
        }
    ]
    impersonatorMenuItem = {
        label: "impersonateUser",
        name: "impersonate-user",
        isDisable: false
    };
    stopImpersonatingMenuItem = {
        label: "stopImpersonating",
        name: "stop-impersonating",
        isDisable: false
    };


    profileMenuListPre = [
        {
            label: 'signIn',
            name: 'sign-in'
        }
    ]
    postLoginBtnCnfg: ButtonGroupInterface[] = [
        // {
        //     label: this.translate.instant("manageAccount"),
        //     type: "button",
        //     classStyles: "solid",
        //     usecase: "manage-account",
        //     id: "header-manage-account-btn"
        // },
        // {
        //     label: this.translate.instant("signOut"),
        //     type: "button",
        //     classStyles: "solid",
        //     usecase: "signout",
        //     id: "header-sign-out-btn"
        // },
        {
            type: "justicon",
            classStyles: "bordered cart-icon",
            icon: "shopping_cart",
            usecase: "cart",
            id: "header-addtoCart-btn-icon",
            badget: 0,
            disabled: false
        }
    ];

    preLoginBtnCnfg: ButtonGroupInterface[] = [
        {
            label: "newUser",
            type: "button",
            classStyles: "solid",
            usecase: "new-user",
            id: "header-new-user-btn"
        },
        {
            label: "signIn",
            type: "button",
            classStyles: "solid",
            usecase: "signin",
            id: "header-sign-in-btn"
        }
    ];

    // header_dropdownBtnCnfg: Header_Dropdown_Interface[] = [
    //     {
    //         id: "header-upcoming-support-renewals-btn",
    //         value: this.renewalTabTypes.UPCOMING_SUPPORT_RENEWALS,
    //         label: this.translate.instant("upcomingSupportRenewals"),
    //         icon: "schedule"
    //     },
    //     {
    //         id: "header-upcoming-support-renewals-btn",
    //         value: this.renewalTabTypes.ACTIVE_SUPPORT_RENEWALS,
    //         label: this.translate.instant("activeSuppotRenewals"),
    //         icon: "refresh"
    //     },
    //     {
    //         id: "header-support-renewal-history-btn",
    //         value: this.renewalTabTypes.SUPPORT_RENEWALS_HISTORY,
    //         label: this.translate.instant("supportRenewalHistory"),
    //         icon: "replay"
    //     },
    //     {
    //         id: "header-saved-carts-btn",
    //         value: "saved-carts",
    //         label: this.translate.instant("savedCarts"),
    //         icon: "shopping_cart"
    //     },
    //     {
    //         id: "header-user-groups-btn",
    //         value: "user-groups",
    //         label: this.translate.instant("userGroups"),
    //         icon: "person"
    //     },
    //     {
    //         id: "header-create-report-btn",
    //         value: "create-report",
    //         label: this.translate.instant("createReport"),
    //         icon: "lab_profile"
    //     }
    // ];
}