import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from 'file-saver-es';
import { SharedComponentConstantData } from "../constants/shared-data.constants";
@Injectable({
    providedIn: 'root'
})
export class PdfDownloadService extends SharedComponentConstantData {

    constructor(private http: HttpClient,
        translate: TranslateService) { super(translate) }

    downloadPdf(data: any, filename: string) {
        const blob = this.b64tb(data);
        saveAs(blob, filename + this.fileExtension)
    }

    //this function used if data:application/pdf  present in repsonse
    b64toBlob(dataURI: string) {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: this.contentType });
    }

    //this function used if no data:application/pdf present in repsonse
    b64tb(dataURI: string) {
        var byteString = atob(dataURI);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: this.contentType });
    }
    //==============================Caching=====================================//
    // downloadPdfPOC(url: string, filename: string): any {
    //     console.log("Not in Store")
    //     return this.http.get(url, { responseType: 'blob' }).subscribe(
    //         async (response: any) => {
    //             var blob = new Blob([response], { type: 'application/pdf' });
    //             console.log("blob::", blob)
    //             saveAs(blob, filename);
    //             var reader = new FileReader();
    //             reader.readAsDataURL(response);
    //             reader.onloadend = function () {
    //                 const base64data = reader.result;
    //                 localStorage.setItem(url, String(base64data));
    //             }
    //         },
    //         e => { console.log("err->", e); }
    //     );

    // }
    // getFromStorage(key: string) {
    //     return localStorage.getItem(key);
    // }

}