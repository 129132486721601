import { registerLocaleData } from '@angular/common';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeDk from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeDECH from '@angular/common/locales/de-CH';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeSWISS from '@angular/common/locales/fr-CH';
import localeIt from "@angular/common/locales/it";
import localeItCh from '@angular/common/locales/it-CH';
import localeJa from '@angular/common/locales/ja';
import localeNorwegian from '@angular/common/locales/nb';
import localeNL from '@angular/common/locales/nl';
import localeNor from '@angular/common/locales/nn';
import localePtBr from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSwedish from '@angular/common/locales/sv';
import localeZh from '@angular/common/locales/zh';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './core/components/layout/layout.module';
import { AuthModule } from './core/modules/auth.module';
import { AuthenticationService } from './core/services/auth.service';
import { OTCookieService } from './core/services/cookie.service';
import { metaReducers, reducers } from './core/state';
import { UserEffects } from './core/state/user';
import { AddToCartComponent } from './modules/cart/components/add-to-cart/add-to-cart.component';
import { OrdersPageDefaultData } from './modules/orders/sub-modules/orders-page-default-data.constants';
import { GenerateReports } from './modules/report/components/report.component';
import { SnackBarService } from './shared/components/snack-bar/snack-bar.service';
import { FormatCurrencyPipe } from './shared/pipes/currency-pipe/currency.pipe';
import { SharedModule } from './shared/shared.module';
registerLocaleData(localeNor);
registerLocaleData(localeNorwegian);
registerLocaleData(localeSWISS);
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localePtBr);
registerLocaleData(localeJa);
registerLocaleData(localeDe);
registerLocaleData(localeNL);
registerLocaleData(localeSwedish);
registerLocaleData(localeIt);
registerLocaleData(localeZh);
registerLocaleData(localeCs);
registerLocaleData(localeDk);
registerLocaleData(localeDECH);
registerLocaleData(localeItCh);
registerLocaleData(localeFi);
registerLocaleData(localeRu);
export function createTranslateLoader(http: HttpBackend) {
    return new TranslateHttpLoader(new HttpClient(http), './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        AddToCartComponent,
        GenerateReports
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MatTooltipModule,
        SharedModule,
        MatIconModule,
        LayoutModule,
        FormsModule,
        AuthModule.forRoot(),
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([UserEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend],
            },
            defaultLanguage: 'en-US'
        }),
    ],
    providers: [
        OTCookieService,
        OrdersPageDefaultData,
        AuthenticationService,
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        TranslateService,
        SnackBarService,
        MatSnackBar,
        FormatCurrencyPipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
