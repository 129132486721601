import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';
import { FormFieldsInterface } from 'src/app/shared/interface/form-fields.interface';
import { NotificationBannerInterface } from 'src/app/shared/interface/notification-banner.interface';


@Component({
    template: ''
})

export class OrdersPageDefaultData {
    allowedFileExtension: string[] = ["csv", "pdf", "doc", "xls", "jpeg", "jpg", "gif", "png"];
    invalidFileError: string = this.translate.instant("invalidFileError");
    maxFileSize: number = 5;
    scheduleOrderDateFormat = 'yyyy-MM-dd';
    scheduleOrderDatePickerFormat = "MM/DD/YYYY"
    methodTypes = [
        {
            id: 0,
            label: this.translate.instant('annual')
        },
        {
            id: 1,
            label: this.translate.instant('autoPay')
        }
    ];

    paymentMethods = [
        {
            id: 1,
            label: this.translate.instant('invoice')
        },
        {
            id: 2,
            label: this.translate.instant('purchaseOrder')
        },
        {
            id: 3,
            label: this.translate.instant('payWithCreditCard')
        },
        {
            id: 4,
            label: this.translate.instant('wireTransfer')
        }
    ];
    paymentOptionRadio:any =[
        { key: this.translate.instant("paymentMethodToday"), value: false, checked: true,schedule: false },
    ]
    scheduleOptions: any = [
        { key: this.translate.instant("scheduleLaterDate"),value: true, checked: false, schedule: true },
    ];
    btn_scheduleOrder_config: ButtonGroupInterface = {
        label: this.translate.instant("scheduleOrder"),
        type: "button",
        classStyles: "",
        disabled: true,
        usecase: "placeOrder-btn",
        id: "confirm-place-order-btn",
    };

    btn_cancel_schedule_config: ButtonGroupInterface = {
        label: this.translate.instant("cancelScheduling"),
        type: "button",
        classStyles: "",
        usecase: "edit-btn",
        id: "remove-schedule-bill",
    }
    bannerInformation: NotificationBannerInterface = {
        type: "danger",
        message: this.translate.instant("warningNote"),
        showButton: false,
        button: {
            label: this.translate.instant("viewDetails"),
            type: "button",
            classStyles: "bordered",
            usecase: "notification-details",
            id: "notification-view-details-btn",
        },
    };
    scheduleBannerInformation: NotificationBannerInterface = {
        type: "danger",
        message: this.translate.instant("scheduleWarningNote"),
        usecase: "scheduleBanner",
        showButton: false,
        button: {
            label: this.translate.instant("viewDetails"),
            type: "button",
            classStyles: "bordered",
            usecase: "notification-details",
            id: "notification-view-details-btn",
        },
    };
    uploadbannerInformation: NotificationBannerInterface = {
        type: "success",
        message: '',
        showButton: false,
        button: {
            label: this.translate.instant("viewDetails"),
            type: "button",
            classStyles: "bordered",
            usecase: "notification-details",
            id: "notification-view-details-btn",
        },
    };
    button__edit_config: ButtonGroupInterface = {
        label: this.translate.instant("changePaymentMethod"),
        type: "button",
        classStyles: "",
        usecase: "edit-btn",
        id: "change_payment_method",
    };
    button_modify_order_config: ButtonGroupInterface = {
        label: this.translate.instant("modifyOrder"),
        type: "button",
        classStyles: "",
        isBtnHide: false,
        usecase: "placeOrder-btn",
        icon: "keyboard_backspace",
        id: "upload-po",
    };
    btn_placeOrder_config: ButtonGroupInterface = {
        label: this.translate.instant("placeOrder"),
        type: "button",
        classStyles: "",
        disabled: true,
        usecase: "placeOrder-btn",
        id: "confirm-place-order-btn",
    };
    btn_upload_PO: ButtonGroupInterface = {
        label: this.translate.instant("uploadPo"),
        type: "button",
        classStyles: "",
        usecase: "upload-po",
        id: "upload-po",
    };

    buttonConfig_order_data: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("cancel"),
            type: "button",
            classStyles: "bordered",
            usecase: "cancel",
            id: "order-cancel"
        },
        {
            label: this.translate.instant("saveChanges"),
            type: "button",
            classStyles: "solid",
            usecase: "saveChanges",
            disabled: true,
            id: "save-changes"
        }
    ];

    poAmountMaxDigits: number = 10;
    poAmountMaxDecimals: number = 5;
    poNumberConfig_field: FormFieldsInterface = {
        label: this.translate.instant('poNumber'),
        cssClass: "po-number",
        placeHolder: "PO_NUM",
        type: "textbox",
        required: true,
        errorMessage: this.translate.instant('poNumber') + this.translate.instant("emptyMessage"),
    };

    poAmountConfig_field: FormFieldsInterface = {
        label: this.translate.instant("poAmount"),
        cssClass: "po-amount",
        placeHolder: "PO_AMOUNT",
        type: "textbox",
        isNumber: true,
        maxLength: "20",
        required: true,
        errorMessage: this.translate.instant('poAmount') + this.translate.instant("emptyMessage"),
    };

    poCurrencyConfig_field: FormFieldsInterface = {
        label: this.translate.instant("poCurrency"),
        cssClass: "po-currency gray-out",
        placeHolder: "Select PO Currency",
        type: "textbox",
        disabled: true,
        //defaultValue: "CAD",
        // inputDisabled: true,
        // options: this.currencyList
    };
    uploadConfig_Field: FormFieldsInterface = {
        label: this.translate.instant('attachAdditionalDoc'),
        cssClass: "po-amount",
        placeHolder: this.translate.instant('uploadfileName'),
        type: "fileUpload",
        required: true,
        purchaseOrder: true,
        disabled: true,
        errorMessage: this.translate.instant("attachDocument"),
    };
    emailAddressConfig_field: FormFieldsInterface = {
        label: this.translate.instant("invoiceEmailAddess"),
        cssClass: "po-email-address",
        placeHolder: "first_last@company.com",
        type: "email",
        required: true,
        errorMessage: this.translate.instant('invoiceEmailAddess') + this.translate.instant("emptyMessage"),

    };

    textInvoiceConfig_field: FormFieldsInterface = {
        label: this.translate.instant("textForInvoice"),
        cssClass: "po-text-invoice",
        placeHolder: "Text",
        type: "textarea",
        maxLength: "500",
    };
    constructor(public translate: TranslateService) { }

}