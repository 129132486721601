import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MiddlewareUrls } from "src/app/modules/orc-renewals/sub-modules/pending/constants/middleware-urls";
import { BaseApiService } from "src/app/shared/services/base-api.service";
import { impersonateContactResponse, SearchTerm, impersonatedUserResponse, Username } from "src/app/shared/interface/impersonate-user.interface";

@Injectable({
    providedIn: "root",
})
export class ImpersonateUserService {
    constructor(private baseService: BaseApiService) { }

    getImpersonateContacts(params?: SearchTerm): Observable<impersonateContactResponse> {
        return this.baseService.getApiCall(MiddlewareUrls.getImpersonateContacts, params);
    }

    getImpersonatedUserInfo(params?: Username): Observable<impersonatedUserResponse> {
        return this.baseService.getApiCall(MiddlewareUrls.getUserInfo, params);
    }
}
