import { TableColumn } from "src/app/shared/components/table/TableColumn";

export const initialTableAddon: TableColumn[] = [
    {
        name: '',
        dataKey: 'total',
        position: 'left',
        isSortable: false,
        isCurrency: false
    },
    {
        name: '',
        dataKey: 'sum',
        position: 'left',
        isSortable: false,
        isCurrency: false
    },
    {
        name: 'Without add-on(s)',
        dataKey: 'addon',
        position: 'center',
        isSortable: false,
        isCurrency: false
    }
];

export interface btnList {
    name: string,
    icon: {
        name: string,
        prefix: string
    }
}