import { PendingRenewalsSummary, PendingSummary } from "src/app/modules/orc-renewals/sub-modules/pending/interface/pending";

export const initialPendingState: PendingRenewalsSummary = {
    renewalsSummary: {
        isSuccess: false,
        result: [],
        displayMessage: '',
        errorMessages: '',
    }
};

export const initialCheckListState: CheckListSummaryState = {
    checkListSummary: []

}

export interface CheckListSummaryState {
    checkListSummary: Array<PendingSummary>
}

export interface renewalParamRequest {
    SortColumn?: string;
    SortOrder?: string;
    renewalMethod?: string;
    renewalMethodId?: number;
    renewalCategory?: string;
    renewalCategoryId?: number;
    pageIndex?: number;
    pageSize?: number;
}
