<ng-container>

    <!-- Filter -->
    <ng-container *ngIf="isFilterable"
                  [matColumnDef]="this.rowActionIcon">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput
                   (keyup)="applyFilter($event)"
                   placeholder="filter">
        </mat-form-field>
    </ng-container>

    <!-- Table -->
    <div class="custom-table-mat">
        <div class="overlay-backdrop"
             *ngIf="isLoading">
            <mat-progress-spinner color="primary"
                                  mode="indeterminate"
                                  diameter="50">
            </mat-progress-spinner>
        </div>
        <table mat-table
               class="mat-custom-table"
               matSort
               matSortDisableClear
               [matSortActive]="sortBy.active"
               [matSortDirection]="sortBy.direction"
               [dataSource]="tableDataSource.data.length > 0 && tableDataSource.filteredData.length > 0 ? tableDataSource : emptyData"
               (matSortChange)="sortTable($event)">

            <!-- action column -->
            <!-- <ng-container *ngIf="rowActionIcon?.length"
                          [matColumnDef]="rowActionIcon">
                <th mat-header-cell
                    *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let element"
                    [id]="rowActionIcon"
                    (click)="emitRowAction(element)">
                    <button mat-button>
                        <mat-icon>remove_shopping_cart</mat-icon>
                    </button>
                </td>
            </ng-container> -->
            <ng-container *ngFor="let tableColumn of tableColumns; let i = index"
                          [matColumnDef]="tableColumn.dataKey">

                <!-- if sortable column header -->
                <ng-container *ngIf="tableColumn.isSortable; else notSortable">
                    <th mat-header-cell
                        *matHeaderCellDef
                        [ngSwitch]="tableColumn.dataKey"
                        [mat-sort-header]="tableColumn.dataKey"
                        [title]="tableColumn.title! | translate"
                        [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'"
                        aria-label="No value">
                        <span *ngIf="tableColumn.hideSelectAll || tableDataSource.data.length === 0">
                            <span *ngIf="!noDataFound">
                                <span *ngSwitchCase="'isSelected'">
                                    {{isAllSelected()}}
                                    <mat-checkbox [disabled]="isEmployee && !isImpersonating"
                                                  (change)="handleCheckbox('', $event, 'selectAll')"
                                                  [checked]="isAllSelected()"
                                                  aria-label="Select All"
                                                  class="hide-label">Select All</mat-checkbox>
                                </span>
                            </span>
                        </span>
                        <span *ngSwitchDefault
                              title="{{tableColumn.title! | translate}}">{{tableColumn.name | translate}}</span>
                    </th>
                </ng-container>
                <!-- else not sortable -->
                <ng-template #notSortable>
                    <th mat-header-cell
                        *matHeaderCellDef
                        [ngSwitch]="tableColumn.dataKey"
                        [class.text-right]="tableColumn.position == 'right'"
                        aria-label="No value">
                        <span *ngIf="!tableColumn.hideSelectAll">
                            <span *ngIf="!noDataFound">
                                <span *ngSwitchCase="'isSelected'">
                                    <mat-checkbox [disabled]="(isEmployee && !isImpersonating) || arrReadonly.length == 0"
                                                  (change)="handleCheckbox('', $event, 'selectAll')"
                                                  [checked]="isAllSelected()&&arrReadonly.length > 0"
                                                  aria-label="Select All"
                                                  class="hide-label">Select All</mat-checkbox>
                                </span>
                            </span>
                        </span>
                        <span *ngSwitchDefault>{{tableColumn.name | translate}}</span>
                    </th>
                </ng-template>

                <!-- column data -->
                <td mat-cell
                    *matCellDef="let element"
                    [ngSwitch]="tableColumn.type"
                    [class.text-right]="tableColumn.position === 'right'">
                    <ng-container *ngSwitchCase="'isSelected'">
                        <mat-checkbox [disabled]="(isEmployee && !isImpersonating)
                        || element.isDisabled || isReadOnlyRc(element) || (element.renewalStatusId == 5 && element.pageType != 'detail')"
                                      *ngIf="(element.pageInfo!=pendingConstantsLbl.active && element.pageInfo!=pendingConstantsLbl.history)"
                                      (change)="handleCheckbox(element, $event, 'rowCheck');element.isSelected = $event.checked"
                                      [checked]="(element.renewalStatusId == 5 && element.pageType != 'detail')? true :(element.isSelected == true || element.isSelected == false) ? element.isSelected : element.isUpsellAccepted"
                                      aria-label="Select"
                                      class="hide-label">Select</mat-checkbox>
                    </ng-container>

                    <ng-container *ngSwitchCase="'isDelete'">
                        <app-button-group [buttonConfig]="buttonItem_del"
                                          (emitClickEvent)="rowDelete(element)"></app-button-group>
                    </ng-container>

                    <ng-container *ngSwitchCase="'button'">
                        <app-button-group [buttonConfig]="requestAssistance"
                                          (emitClickEvent)="emitRowAction(element); buttonClickEvent($event, element)"></app-button-group>
                    </ng-container>

                    <ng-container *ngSwitchCase="'isCurrency'">
                        <ng-container [ngTemplateOutlet]="customAmountTemplate"
                                      [ngTemplateOutletContext]="{element:element}">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'extend'">
                        <ng-container *ngIf="tableColumn.dataKey === 'extendLink'"
                                      [ngTemplateOutlet]="extendTemplate"
                                      [ngTemplateOutletContext]="{element:element}">
                        </ng-container>
                    </ng-container>


                    <ng-container *ngSwitchCase="'isPaymentMethod'">
                        <ng-container [ngTemplateOutlet]="customThemeTemplate"
                                      [ngTemplateOutletContext]="{element:element, customTheme: tableColumn.isCustomTmpl}">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'isCustomer'">
                        <ng-container *ngIf="tableColumn.dataKey == 'customer' || tableColumn.dataKey == 'startDate' || tableColumn.dataKey == 'endDate'"
                                      [ngTemplateOutlet]="customCustomerTemplate"
                                      [ngTemplateOutletContext]="{element:element, customTmpl: tableColumn.isCustomTmpl}">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'isProduct'">
                        <ng-container *ngIf="tableColumn.dataKey == 'productDetails' || tableColumn.dataKey == 'startDate'"
                                      [ngTemplateOutlet]="customProductTemplate"
                                      [ngTemplateOutletContext]="{element:element}">
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'log-date'">
                        <ng-container [ngTemplateOutlet]="actvityLogDateTmpl"
                                      [ngTemplateOutletContext]="{element:element}">
                        </ng-container>
                    </ng-container>

                    <div *ngSwitchDefault
                         class="custom-table-mat-text custom-table-mat-{{tableColumn.dataKey}}">
                        <div *ngIf="!tableColumn.isLink; else link"
                             class="custom-table-mat-noLink">
                            <mat-icon *ngIf="element && element.contactType === 'primary' && tableColumn.dataKey === 'status'"
                                      class="contact-status">manage_accounts
                            </mat-icon>
                            {{element | dataPropertyGetter: tableColumn.dataKey}}
                        </div>

                        <ng-template #link>
                            <div class="custom-table-mat-link anchor-link"
                                 *ngIf="tableColumn.dataKey != 'activity'">
                                <div class="{{tableUseType}}"
                                     *ngIf="tableColumn.dataKey != 'invoiceNumber' ">
                                    <a
                                       (click)="routeToNavigation(element, element.uniqueId, tableColumn.dataKey); openPopupWizard(element)">
                                        {{element | dataPropertyGetter: tableColumn.dataKey}}
                                    </a>
                                </div>

                                <div class="{{tableUseType}}"
                                     *ngIf="tableColumn.dataKey == 'invoiceNumber' && element.invoiceNumber ">
                                    <app-button-group [buttonConfig]="buttonItem_invoice"
                                                      (click)="downloadInvoice(element)"
                                                      [invoiceNumber]="element.invoiceNumber"
                                                      [class]="(element.isInvoiceAvailable && isEmployee && !isImpersonating) ? 'disable-invoice' :
                                                      (!element.isInvoiceAvailable) ? 'is-disable' : 'is-link'"></app-button-group>
                                </div>

                            </div>
                        </ng-template>

                    </div>
                    <ng-container *ngIf="tableColumn.dataKey == 'activity'"
                                  [ngTemplateOutlet]="actvityLogTypeTmpl"
                                  [ngTemplateOutletContext]="{element:element}">
                    </ng-container>
                    <ng-container *ngIf="tableColumn.dataKey == 'addon'"
                                  [ngTemplateOutlet]="customSummaryTotalTmpl"
                                  [ngTemplateOutletContext]="{element:element}">
                    </ng-container>

                    <ng-template #actvityLogTypeTmpl>
                        <div class="">
                            <div *ngIf="element.fileName || element.acceptedBy; else noLink">
                                <a [class]="(isEmployee && !isImpersonating) ? 'is-disable' : 'is-link'"
                                   (click)="routeToNavigation(element, element.uniqueId, tableColumn.dataKey)">
                                    {{element | dataPropertyGetter: tableColumn.dataKey}}
                                    <mat-icon *ngIf="((element.fileName || element.acceptedBy) && clickedElementID !== element.uniqueId)"
                                              class="icon-circle ml-1 add-cricle">
                                        add_circle_outline
                                    </mat-icon>
                                    <mat-icon *ngIf="((element.fileName || element.acceptedBy) && clickedElementID === element.uniqueId) "
                                              class="icon-circle remove-circle ml-1">
                                        remove_circle_outline
                                    </mat-icon>
                                </a>
                            </div>
                            <ng-template #noLink>
                                <div>
                                    {{element | dataPropertyGetter: tableColumn.dataKey}}
                                </div>
                            </ng-template>
                        </div>
                        <div [class]="element.fileName || element.acceptedBy ? 'file-name' : 'is-disable'"
                             *ngIf="(clickedElementID === element.uniqueId)">
                            <span class="ml-2"
                                  *ngIf="element.fileName"><b>{{'fileNameLog' | translate}}:
                                </b>{{element.fileName}}</span>
                            <span class="ml-2"
                                  *ngIf="element.acceptedBy"><b>{{'accteptedByLog' | translate}}:
                                </b>{{element.acceptedBy}}</span>
                        </div>
                    </ng-template>




                    <!-- <ng-container *ngIf="tableColumn.dataKey == 'addon'"
                                  [ngTemplateOutlet]="customAddonTmpl"
                                  [ngTemplateOutletContext]="{element:element}">
                    </ng-container> -->
                </td>
            </ng-container>
            <!-- Second-header Row -->
            <!-- <span *ngIf="secondHeader">
                <tr mat-header-row
                    *matHeaderRowDef="['header-row-second-group']"
                    class="second-header-row"></tr>
            </span> -->
            <!-- End of Second-header Row -->
            <tr mat-header-row
                *matHeaderRowDef="displayedColumns">

            </tr>

            <ng-container matColumnDef="empty-row">
                <td mat-cell
                    [attr.colspan]="displayedColumns.length"
                    class="custom-table-mat-no-data"
                    *matCellDef="let element">{{'noDataFoundLabel' | translate}}
                </td>
            </ng-container>
            <!-- Second Header -->
            <!-- <ng-container matColumnDef="header-row-second-group">
                <span>
                    <th mat-header-cell
                        class="m-0 second-header"
                        *matHeaderCellDef
                        [attr.colspan]="2"
                        [style.text-align]="">
                        <span *ngIf="secondHeader">{{secondHeader}}</span>
                    </th>
                </span>
            </ng-container> -->
            <!-- End of Second Header -->
            <!-- [class]="cartItem == 'cart' ? 'yes-cart' : 'no-cart'" -->
            <tr mat-row
                *matRowDef="let row; columns: tableDataSource.data.length > 0 && tableDataSource.filteredData.length > 0
                ? displayedColumns
                : ['empty-row'];">
            </tr>
        </table>

        <!-- Pagination -->
        <div class="custom-table-mat-pagination">
            <mat-paginator #matPaginator
                           [showFirstLastButtons]="showFirstLastButtons"
                           *ngIf="isPageable"
                           [hidden]="!tableDataSource.data.length"
                           [pageSizeOptions]="paginationSizes"
                           (page)="pageChange($event)"
                           [length]="totalRecords"
                           [pageSize]="defaultPageSize">
            </mat-paginator>
        </div>
    </div>
</ng-container>
<ng-template #customThemeTemplate
             let-customTheme="customTheme"
             let-element="element">
    <div class="custom-table-mat-cell custom-table-custom-theme">
        <div>{{element.renewalMethod}}</div>
        <div>{{element.paymentMethod}}</div>
    </div>
</ng-template>

<ng-template #customCustomerTemplate
             let-custom="customTmpl"
             let-element="element">
    <div class="custom-table-mat-cell custom-table-mat-cell-customer">
        <ng-container *ngIf="!custom else customTemplate">
            <div>{{element.customer}}</div>
            <div>{{ 'contract' | translate }}: {{element.contractNumber}}</div>
            <div><span>{{ 'term' | translate }}:
                </span>{{ element.startDate | localizedDate : mediumDateFormat : element.countryCode : element.languageCode}}
                {{ 'termTo' | translate }}
                {{element.endDate | localizedDate: mediumDateFormat : element.countryCode : element.languageCode}}
            </div>
            <ng-container *ngIf="(element.renewalCategoryId== uniqueIdEnum.Active ||
                          element.renewalCategoryId== uniqueIdEnum.History); else noEditCustomerRef">
                <div *ngIf="element.customerReference">
                    {{ 'customerReference' | translate }}:
                    <span> {{element.customerReference}}</span>
                </div>
                <span></span>
            </ng-container>
            <ng-template #noEditCustomerRef>
                <div>
                    {{ 'customerReference' | translate }}:
                    <a (click)="updateCustomerReference(element)"
                       [class]="(isReadOnlyRc(element) || (isEmployee && !isImpersonating)) ? 'is-disable' : 'is-link'">
                        {{element.customerReference}}
                        <span *ngIf="!element.customerReference">({{ 'edit' | translate }})</span>
                    </a>
                </div>
            </ng-template>

            <ng-container>
                <ng-container *ngIf="element.renewalStatus== pendingConstantsLbl.pending &&
                              element.renewalMethod== pendingConstantsLbl.annual">
                    <!-- <div *ngIf="element.startDate < currentDate">
                        <ng-container *ngIf="element.portalStatus === pendingConstantsLbl.pendingAcceptance">
                            <mat-icon>star</mat-icon>
                            <span>{{element.portalStatus}}</span>
                        </ng-container>
                    </div>
                    <div *ngIf="element.startDate > currentDate"
                         [class]="element.startDate > currentDate ? 'status-color' : 'status'">
                        <ng-container *ngIf="element.portalStatus === pendingConstantsLbl.pendingAcceptance">
                            <mat-icon class="material-symbols-outlined">release_alert</mat-icon>
                            <span>{{element.portalStatus}}</span>
                        </ng-container>
                    </div> -->
                </ng-container>
                <ng-container *ngFor="let pStatus of status">
                    <ng-container
                                  *ngIf="pStatus.id === element.renewalStatusId && element.renewalStatusId === pendingConstantsLbl.pendingAcceptanceID">
                        <div class="status-icon"
                             [ngClass]="element.renewalStatusId==2 ? 'status-color': ''"
                             *ngIf="element.renewalStatusId==2">
                            <mat-icon [class]="pStatus.iconclass">{{pStatus.icon}} {{pStatus.iconclass}}</mat-icon>
                            <span>{{element.portalStatus}}</span>
                        </div>
                        <div class="status-icon"
                             *ngIf="element.renewalStatusId==1">
                            <mat-icon [class]="pStatus.iconclass">{{pStatus.icon}}</mat-icon>
                            <span>{{element.portalStatus}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pStatus.id === element.renewalStatusId">
                        <div class="status-icon"
                             *ngIf="element.renewalStatusId==7">
                            <mat-icon [class]="pStatus.iconclass">{{pStatus.icon}}</mat-icon>
                            <span>{{element.portalStatus}}
                                {{element.previousMonth | localizedDate: longDateFormat : element.countryCode : element.languageCode}}</span>
                        </div>
                    </ng-container>
                    <div class="status-icon"
                         *ngIf="(pStatus.id === element.renewalStatusId && element.renewalStatusId !== pendingConstantsLbl.pendingAcceptanceID && pStatus.id != 7)">
                        <mat-icon [class]="pStatus.iconclass">{{pStatus.icon}}</mat-icon>
                        <span>{{element.portalStatus}}</span>
                        <span
                              *ngIf="(pStatus.id === element.renewalStatusId && element.renewalStatusId !== pendingConstantsLbl.pendingAcceptanceID && pStatus.id == 11)">
                            {{element.scheduledBillDate | localizedDate: longDateFormat : element.countryCode : element.languageCode}}
                        </span>
                    </div>
                    <div class="status-icon-sch"
                         *ngIf="(pStatus.id === element.renewalStatusId && element.renewalStatusId !== pendingConstantsLbl.pendingAcceptanceID && pStatus.id == 11)">
                        <p class="d-flex">
                            <mat-icon class="cart-icon status-info">info_outline</mat-icon>
                            <span>
                                {{'modifyScheduleInfo' | translate}}
                                <a [class]="isEmployee && !isImpersonating ? 'is-disable' : 'is-link'"
                                   (click)="routeToURL('order',element)"> {{'confirmOrderPage' | translate}}</a>
                            </span>
                        </p>
                    </div>
                    <div class="status-icon"
                         *ngIf="(pStatus.id === element.renewalStatusId && element.renewalStatusId !== pendingConstantsLbl.pendingAcceptanceID && pStatus.id == 5)">
                        <mat-icon class="cart-icon status-info">info_outline</mat-icon>
                        <span>{{'cartInfoSummary_1' | translate}}
                            <a [class]="isEmployee && !isImpersonating ? 'is-disable' : 'is-link'"
                               (click)="routeToCart()"> {{'cartInfoSummary_2' | translate}}</a>.</span>
                    </div>
                </ng-container>

            </ng-container>
            <!-- <div class="d-flex"
                 *ngIf="element.previousMonth">
                <mat-icon>star_border</mat-icon>{{'previousAutoRenewal'| translate}}
                {{element.previousMonth | date: longDateFormat}}
            </div> -->
        </ng-container>
        <ng-template #customTemplate>
            <div class="incart">
                <div>
                    <span class="cart_rc">{{'reference' | translate}}:</span>
                    <span><a
                           (click)="getDetailsByRcId(element.renewalMethodId!,element.renewalContractNumber!, element.assureRenewalUniqueID!)">
                            {{ element.renewalContractNumber }}</a></span>
                </div>
                <div> <span>{{'customer' | translate}}:</span> {{ element.customer }}</div>
                <div>
                    <span>{{'contract' | translate}}:</span>
                    <span> {{ element.contractNumber }}</span>
                </div>
                <div>
                    <span>{{'customerReference' | translate}}:</span>
                    <span> {{ element.customerReference }}</span>
                </div>
                <div>
                    <span>{{'startDate' | translate}}:</span>
                    <span>
                        {{ element.startDate | localizedDate : mediumDateFormat : element.countryCode : element.languageCode}}</span>
                </div>
                <div>
                    <span>{{'endDate' | translate}}:</span>
                    <span>
                        {{ element.endDate | localizedDate : mediumDateFormat : element.countryCode : element.languageCode}}</span>
                </div>
            </div>
        </ng-template>

    </div>
</ng-template>

<ng-template #customAmountTemplate
             let-element="element">
    <div class="custom-table-mat-cell custom-table-mat-cell-actions">
        <p> {{element.currency ? element.currency : 'USD'}}
            <span *ngIf="element.subTotal || element.subTotal===0">
                {{element.subTotal | formatCurrency : element.currency : element.countryCode  : element.languageCode}}
            </span>
            <span *ngIf="element.amount || element.amount===0">
                {{element.amount | formatCurrency : element.currency : element.countryCode : element.languageCode}}
            </span>
        </p>
        <div class="mat-btn-tbl"
             *ngIf="(element.renewalCategoryId == uniqueIdEnum.Pending && element.isEligibleForAutoPay)">
            <a (click)="emitRowAction(element,'elb_auto')"
               [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
               class="arrow-more more-bold">{{buttonItem_one.label | translate}} <mat-icon
                          class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
        <div class="mat-btn-tbl"
             *ngIf="(element.renewalCategoryId == uniqueIdEnum.Pending && element.isEligibleforadditionalsupport)">
            <a (click)="emitRowAction(element,'elb_add')"
               [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
               class="arrow-more more-bold">{{additionaSupport_button.label | translate}} <mat-icon
                          class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>

        <div class="mat-btn"
             *ngIf="(element.renewalCategoryId == uniqueIdEnum.Pending && element.isEligibleForExtendedSupport)">
            <a (click)="emitRowAction(element,'elb_ext')"
               [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
               class="arrow-more more-bold">{{buttonItem_two.label | translate}} <mat-icon
                          class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>

        </div>
    </div>
</ng-template>

<ng-template #extendTemplate
             let-element="element">
    <div class="custom-table-mat-cell custom-table-mat-cell-actions ml-4">
        <a *ngIf="(element.pageInfo == pendingConstantsLbl.pending && element.extendedSupportDate)"
           [href]="extendedSupportRecommended"
           target="_blank"
           [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
           class="status-color">{{'upcomingExtendedSupport' | translate}}
            <mat-icon>library_add_check_outlined</mat-icon>
        </a>
        <br>
        <div class="mat-btn"
             *ngIf="((element.pageInfo == pendingConstantsLbl.pending) && element.extSupValByLine && element.extSupValByLine !== null)  && !validateKey">
            <a (click)="emitRowAction(element)"
               class="{{isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) || element.isDisabled === element.productNumber ? 'arrow-more more-bold anchor-disable' : 'arrow-more more-bold'}}">
                {{buttonItem_three.label}}
                <mat-icon class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
    </div>
</ng-template>

<ng-template #actvityLogDateTmpl
             let-element="element">
    <div class="custom-table-mat-cell custom-table-mat-cell-actions">
        {{element.dateTime | localizedDate: dateTimeFormatMM : element.countryCode : element.languageCode: 'act-log' }}
    </div>
</ng-template>

<ng-template #customProductTemplate
             let-element="element">
    <div class="custom-table-mat-cell custom-table-mat-cell-product">
        <div>{{element.productDetails}}</div>
        <div>{{element.startDate | localizedDate: dateFormat : element.countryCode : element.languageCode }} to
            {{element.endDate | localizedDate: dateFormat : element.countryCode : element.languageCode}}
        </div>
        <div>
            <span *ngIf="element.suid">{{'unique' | translate}}#: {{element.suid}}</span>
            <span *ngIf="element.suidName">
                {{'uniqueName' | translate}}:
                {{element.suidName }}
            </span>
        </div>
        <div *ngIf="element.productNumber">{{'SKU: '}}{{element.productNumber}}</div>
        <div *ngIf="element.lineNotes"
             class="formatted"><i>{{element.lineNotes}}</i></div>
        <div *ngIf="!element.isTechConnectAddOn && element.isAdditionalSupportAddOn; else techConnect">
            <a class="arrow-more more-bold"
               href="{{learnMorePrimeProtect}}"
               [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
               target="_blank">{{'learnMoreLink' | translate}} <mat-icon
                          class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
        <ng-template #techConnect>
            <div *ngIf="element.isTechConnectAddOn && element.isAdditionalSupportAddOn">
                <a class="arrow-more more-bold"
                   href="{{learnMoreTechConnectAddOn}}"
                   [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
                   target="_blank">{{'learnMoreLink' | translate}} <mat-icon
                              class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
            </div>
        </ng-template>
        <div *ngIf="learnMore == 'extend' && element.isExtendedSupportAddOn">
            <a class="arrow-more more-bold"
               href="{{learnMoreExtendedAddon}}"
               [class]="(isReadOnlyIgnoreCart(element) || (isEmployee && !isImpersonating) ) ? 'is-disable' : 'is-link'"
               target="_blank">{{'learnMoreLink' | translate}} <mat-icon
                          class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
    </div>
</ng-template>

<ng-template #customSummaryTotalTmpl
             let-element="element">
    <div class="custom-table-mat-cell custom-table-mat-cell-total">
        <div class="head"><span class="head-value">*{{'subTotal' | translate}}
                <span *ngIf="element.items">
                    ({{element.items}}
                    {{element.items !== 1 ? 'items' : 'item' | translate}})
                </span>: </span>
            <span class="head-value-text">{{element.currency}}
                {{element.subtotal  | formatCurrency : element.currency : element.countryCode : element.languageCode}}
            </span>
        </div>
        <div class="head"><span class="head-value">*{{'estimatedTax' | translate}}:</span> <span
                  class="head-value-text">
                {{element.currency}}
                {{element.estimatedTax | formatCurrency : element.currency : element.countryCode : element.languageCode}}</span>
        </div>
        <div class="head"><span class="head-value"><b>*{{'total' | translate}}:</b></span> <span
                  class="head-value-text"><b>{{element.currency}}
                    {{element.overAllTotal | formatCurrency : element.currency : element.countryCode : element.languageCode}}</b></span>
        </div>
        <div class="head"
             *ngIf="element.renewalMethodId === uniqueIdEnum.Annual"><span class="head-value">*{{'totalAs' | translate}}
                {{element.lateFeeDate | localizedDate: 'dd-MMM-yyyy' : element.countryCode : element.languageCode}}
            </span><span class="head-value-text">
                {{element.currency}}
                {{element.totalAsof | formatCurrency : element.currency : element.countryCode : element.languageCode }}
            </span>
        </div>
        <br />
        <div class="extra-text">
            <p>{{'taxSubjet' | translate}}</p>
            <p *ngIf="element.lateFee">{{'lateFeeText' | translate}}</p>
        </div>
    </div>

</ng-template>