import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../../services/currency.service';

@Pipe({
    name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {

    constructor(private currencyService: CurrencyService) { }

    transform(
        value: number,
        currencyCode: string = 'USD',
        countryCode?: any,
        languageCode?: any,
        display:
            | 'code'
            | 'symbol'
            | 'symbol-narrow'
            | string
            | boolean = 'symbol-narrow',
        digitsInfo: string = '1.2-2',
        locale: string = 'en-us',
    ): any | null {
        if (currencyCode === null) {
            currencyCode = 'USD'
        }
        return formatCurrency(
            value,
            this.currencyService.getLocaleByCurrency(countryCode?.toUpperCase(), languageCode?.toUpperCase()),
            getCurrencySymbol(currencyCode?.toUpperCase(), 'narrow'),
            currencyCode?.toUpperCase(),
            digitsInfo
        );
    }


}
