import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from 'src/app/shared/components/table/TableColumn';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';

export class cartConstants {

    cartSummaryInfo: TableColumn[] = [
        // {
        //     name: "renewalContract",
        //     dataKey: 'renewalContractNumber',
        //     position: 'left',
        //     isSortable: false,
        //     sortByField: 'renewalContractNumber',
        //     sortDirection: 'asc'
        // },
        {
            name: "supportRenewals",
            dataKey: 'customer',
            position: 'left',
            isSortable: false,
            type: 'isCustomer',
            isCustomTmpl: true

        },
        {
            name: "amount",
            dataKey: 'subTotal',
            position: 'left',
            isSortable: false,
            type: 'isCurrency'
        },
        {
            name: '',
            dataKey: 'isDelete',
            position: 'right',
            isSortable: false,
            type: 'isDelete'
        }
    ];

    addToCart_Checkout: ButtonGroupInterface[] = [
        // {
        //     label: "cancel",
        //     type: "button",
        //     classStyles: "bordered",
        //     usecase: "cancel",
        //     id: "cancel-btn"
        // },
        {
            label: "checkOut",
            type: "button",
            classStyles: "checkout_solid",
            usecase: "proceedToCheckout",
            id: "upload-po"
        }
    ];

    constructor(translate: TranslateService) {
    }
}