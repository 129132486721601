import { DatePipe } from '@angular/common';
import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, of, switchMap } from 'rxjs';
import { HeaderFooterDataConstants } from "src/app/core/constants/header-footer.constants";
import { LanguageConstants } from "src/app/core/constants/language.constants";
import { RoutingUrlConstants } from "src/app/core/constants/routing-url.constants";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { LocalStorageService } from "src/app/core/services/localstorage.service";
import { masterSelector } from 'src/app/core/state/user';
import { CartService } from 'src/app/modules/cart/services/cart.service';
import { CartData } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/pending';
import { RenewalsService } from "src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service";
import { OrdersPageDefaultData } from "src/app/modules/orders/sub-modules/orders-page-default-data.constants";
import { ImpersonateUserComponent } from 'src/app/shared/components/impersonate-user/impersonate-user.component';
import { RequestAssistanceComponent } from 'src/app/shared/components/request-assistance/request-assistance.component';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { UniqueID } from 'src/app/shared/enums/unique-id.enum';
import { ButtonGroupInterface } from "src/app/shared/interface/button-group.interface";
import { FormFieldsInterface } from "src/app/shared/interface/form-fields.interface";
import { ComponentModel } from 'src/app/shared/interface/popup.interface';
import { CommonService } from "src/app/shared/services/common.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { PopupService } from "src/app/shared/services/popup.service";
import { environment } from "src/environments/environment";
import { HeaderService } from "./services/header.service";
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.sass"],
    providers: [DatePipe],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
    isLoggedIn = false;
    toggleRenewalNavbar = false;
    languageValue: string = "English";
    myControl = new FormControl("");
    languageList = LanguageConstants;
    orcHome = RoutingUrlConstants.ORC_HOME;
    showSearch: boolean = false;
    autoCompleteField: FormFieldsInterface = {
        cssClass: "custom-cls",
        placeHolder: 'homeSearchPlaceholder',
        maxLength: "10",
        minLength: "5",
        type: "autoComplete",
        label: "Search the entire site",
        disabled: false
    };

    /**
     * postLogin - provides postLogin button config like signout and manageaccout
     */
    postLogin: ButtonGroupInterface[] = this.headerFooterDataConstants.postLoginBtnCnfg;
    headerMenuList = this.headerFooterDataConstants.headerMenuItems;
    profileMenu = this.headerFooterDataConstants.profileMenuList;
    profileMenuPreLogin = this.headerFooterDataConstants.profileMenuListPre;
    /**
     * preLogin - provides button configs like newuser and sigin
     */
    preLogin: ButtonGroupInterface[] = this.headerFooterDataConstants.preLoginBtnCnfg;
    pay_my_bill_link = environment.OT_PAY_MY_BILL;
    selectedDropdownOption: any;
    searchResult: any;
    searchResultKeys: any;
    searchObject: any = [];
    headForm = this._formBuilder.group({
        autoComplete: [""]
    });
    pageName = '';
    sampleContractMesgObject: any;
    bannerInformation: any = {};

    notificationsList: any = [];
    showNotificationList: any = [];
    requiredNotification: number = this.headerFooterDataConstants.showMaxNotifications;
    isEmployee: any;
    canImpersonate: any;
    detailsScreen: boolean = false;
    urlPath: any;
    selectedIndex: any;
    profileObj: any;
    profileFrontLabel: string = this.translate.instant('myAccount');
    displayNotificationHOME: boolean = false;
    displayNotificationPending: boolean = false;
    displayNotificationActive: boolean = false;
    displayNotificationHistory: boolean = false;
    renewalMethodType: any;
    isLoading = false;
    isShowSpinner = false;
    notificatioLoader = false;
    varMenuItemLabel: any;
    isImpersonating = false;
    cartData!: CartData;
    master$ = this.store.select(masterSelector.master);
    constructor(
        private authService: AuthenticationService,
        private router: Router,
        route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        public translate: TranslateService,
        private headerService: HeaderService,
        private popupService: PopupService,
        private _formBuilder: FormBuilder,
        private headerFooterDataConstants: HeaderFooterDataConstants,
        private commonService: CommonService,
        private notificationsService: NotificationService,
        private renewalService: RenewalsService,
        private cartService: CartService,
        public datepipe: DatePipe,
        private ordersPageDefaultData: OrdersPageDefaultData,
        private _snakBar: SnackBarService,
        private store: Store

    ) {
        this.languageList.filter((a) =>
            a.code == localStorageService.getLocalStorage("languageCode")
                ? (this.languageValue = a.label)
                : "English"
        );
        const segment = route.snapshot.url.map((x) => x.path);
        this.authService.isAuthenticated$.subscribe((loggedIn) => {
            if (!loggedIn && segment.length > 0)
                this.router.navigate([""], { replaceUrl: true });
            this.isLoggedIn = loggedIn;
        });

        if (this.isLoggedIn) {
            this.isEmployee = this.authService.isEmployee();
            this.postLogin[this.postLogin.length - 1].disabled = this.isEmployee && !this.commonService.isImpersonating();
            // const userObj = sessionStorage.getItem("profile");
            this.router.events.subscribe((event) => {
                this.notificationsList.length = 0;
                this.showNotificationList.length = 0;
                this.showSearch = false;
                this.searchObject = [];
                if (event instanceof NavigationEnd) {
                    const url = event.url.split('/');
                    this.pageName = url[3];
                    event.url == RoutingUrlConstants.ORC_HOME ?
                        (this.displayNotificationHOME = true,
                            !this.isEmployee || this.isImpersonating ? (this.getNotifcationData(), this.getCartRenewals()) : '') : this.displayNotificationHOME = false;

                    event.url == RoutingUrlConstants.ORC_PENDING_SUMMARY ?
                        (this.displayNotificationPending = true,
                            this.displayNotificationHOME = false,
                            !this.isEmployee || this.isImpersonating ? (this.getNotifcationData()) : '') : this.displayNotificationPending = false;

                    event.url == RoutingUrlConstants.ORC_ACTIVE_SUMMARY ?
                        (this.displayNotificationActive = true,
                            this.displayNotificationHOME = false,
                            !this.isEmployee || this.isImpersonating ? (this.getNotifcationData(), this.getCartRenewals()) : '') : this.displayNotificationActive = false;

                    event.url == RoutingUrlConstants.ORC_HISTORY_SUMMARY ?
                        (this.displayNotificationHistory = true,
                            this.displayNotificationHOME = false,
                            !this.isEmployee || this.isImpersonating ? (this.getNotifcationData(), this.getCartRenewals()) : '') : this.displayNotificationHistory = false;
                    this.selectedMenuItem(event.url);
                    //this.displayNotificationHOME ? this.getCartRenewals() : '';
                }
                window.scrollTo(0, 0);
            })

            this.profileFrontLabel = this.authService.getName()!;
            this.getSearchResults();
        }
    }
    getCartRenewals() {
        this.cartService.getCartRenewals().subscribe((e) => {
            this.cartData = e.getCartRenewals;
        });
    }

    getSearchResults() {
        return this.headerService.getSearchRecords("").subscribe((result) => {
            result.searchRenewalContracts.isSuccess ? this.searchResult = result.searchRenewalContracts.result :
                this._snakBar.showSnackBar(result.searchRenewalContracts.statusCode);
        });
    }

    move(array: any, oldIndex: number, newIndex: number) {
        if (newIndex >= array?.length) {
            newIndex = array?.length - 1;
        }
        array.splice(newIndex, 0, array?.splice(oldIndex, 1)[0]);
        return array;
    }

    getNotifcationData() {
        this.notificatioLoader = true;
        return this.headerService.geNotificationRecords().subscribe((result) => {
            if (result.notifications.isSuccess) {
                this.notificationsList = this.notificationsService.getNoticaionBannerInfo(result,
                    this.displayNotificationHOME, this.displayNotificationPending, this.displayNotificationActive,
                    this.displayNotificationHistory);
                this.showNotificationList.length = 0;
                for (var i = 0; i < this.requiredNotification; i++) {
                    this.showNotificationList.push(this.notificationsList[i]);
                };
            }
            else {
                this._snakBar.showSnackBar(result.notifications.statusCode);
            }
            this.notificatioLoader = false;
        });
    }

    ngOnInit(): void {
        this.authService.isAuthenticated$.subscribe((loggedIn) => {
            this.isLoggedIn = loggedIn;
        });
        this.headerFooterDataConstants.postLoginBtnCnfg[0].badget = Number(localStorage.getItem('cartCount'));
    }
    ngAfterContentChecked() {
        this.canImpersonate = this.isImpersonator();
        // setTimeout(() => {
        // const userObj = sessionStorage.getItem("profile");
        // if (userObj) {
        //     this.profileObj = JSON.parse(this.commonService.decryptData(userObj));
        //     this.profileFrontLabel = this.profileObj.name;
        //if (this.authService.isEmployee()!) {
        this.profileMenu.map(e => {
            if (e.name === "request-assistance" || e.name === "pay-my-bill") {
                e.isDisable = this.isEmployee;
            }
            if (e.name === "manage-account" && this.canImpersonate) {
                const impMenuItem = this.headerFooterDataConstants.impersonatorMenuItem;
                e.label = impMenuItem.label;
                e.name = impMenuItem.name;
                e.isDisable = impMenuItem.isDisable;
            }
            if (e.name === "impersonate-user" && this.canImpersonate && sessionStorage.getItem('impersonatedUser')) {
                const impStopMenuItem = this.headerFooterDataConstants.stopImpersonatingMenuItem;
                e.label = impStopMenuItem.label;
                const impersonatedUser = JSON.parse(sessionStorage.getItem('impersonatedUser') || '{}');
                this.varMenuItemLabel = `${impersonatedUser.firstName} ${impersonatedUser.lastName}`;
                this.isImpersonating = true;
                e.name = impStopMenuItem.name;
                e.isDisable = impStopMenuItem.isDisable;
            }
        })
        //}
        //     }
        // }, 1000);
    }

    onNotificationButtonClick(event: string) {
        let navaigateURL = "";
        const renewalMethodId = event.split("&$")[0];
        const rm = event.split("&$")[1];
        const rCategory = event.split("&$")[2];
        const rAssureID = event.split("&$")[3];
        const rMethod = event.split("&$")[4];
        const checkAutoInvoice = event.split("&$")[5];
        const routingPage = event.split("&$")[6];
        localStorage.setItem("renewalCategoryId", rCategory);
        localStorage.setItem("rc", rm);
        localStorage.setItem("renewalUniqueID", rAssureID);
        localStorage.setItem("rcID", renewalMethodId);
        if (routingPage && (routingPage == UniqueID.ViewDetailsButton.toString()
            || routingPage == UniqueID.RenewTodayButton.toString())) {
            navaigateURL = RoutingUrlConstants.ORC_PENDING_DETAILS
            this.router.navigateByUrl(navaigateURL + "/" + rm);
        }
        else if (routingPage && routingPage == 'payment') {
            const paymentMethodCode = event.split("&$")[7];
            const isPORequired = event.split("&$")[8];
            this.ordersPageDefaultData.methodTypes.filter(e => (e.id === Number(checkAutoInvoice))
                ? this.renewalMethodType = this.translate.instant(e.label) : '');

            const paymentOptions = this.renewalService.getPaymentOptions(UniqueID.HeaderPage, Number(paymentMethodCode),
                this.renewalMethodType, rm, Boolean(JSON.parse(isPORequired)), [rAssureID], false,
                event.split("&$")[11], this.renewalService.getPaymentType(event.split("&$")[12]));
            localStorage.setItem('paymentOptions', JSON.stringify(paymentOptions));
            localStorage.setItem("elbLink", "elb_AutoPay");
            localStorage.setItem("auto_summary", "true");
            localStorage.setItem("summaryInfo", "summary");
            localStorage.setItem("contractEntity", event.split("&$")[9]);
            localStorage.setItem("paymentContractEntity", event.split("&$")[10]);
            navaigateURL = RoutingUrlConstants.ORC_PAYMENT + '?methodType=' + encodeURIComponent(renewalMethodId)
            this.router.navigateByUrl(navaigateURL);
        }
        else if (routingPage && routingPage == UniqueID.RequestAssianceButton.toString()) {
            const requestAssistance = {
                type: 'renewal-request-assistance',
                company_name: event.split("&$")[7]
            }
            const popupData: ComponentModel = {
                componentName: RequestAssistanceComponent,
                data: requestAssistance
            };
            this.popupService.dialogWidth = 'large';
            this.popupService.openPopup(popupData);
            this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
                response && response.resType.requestAssistance.statusCode ?
                    this._snakBar.showSnackBar(response.resType.requestAssistance.statusCode) : '';

            });
        }
        else {
            navaigateURL = RoutingUrlConstants.ORC_PENDING_SUMMARY
            this.router.navigateByUrl(navaigateURL);
        }
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    }

    onNotificatonCloseClick(event: any) {
        const addNotification = this.notificationsList[this.showNotificationList.length];
        this.showNotificationList.splice(this.showNotificationList.length, 1);
        setTimeout(() => {
            this.showNotificationList.push(addNotification);
        }, 100);
    }

    requestAssistanceClick(e: any) {
        this.openPopupDialog();
    }

    login() {
        this.authService.login();
    }

    logout() {
        this.authService.logout();
    }

    onButtonClick(event: string) {
        event == "signin"
            ? this.login()
            : event == "new-user"
                ? (window.location.href = environment.OT_IAM_SIGNUP)
                : event == "cart"
                    ? (localStorage.removeItem("shoppingCart"), this.router.navigate([RoutingUrlConstants.ORC_CART]))
                    : undefined;
    }

    getSearchInputValue(event?: any) {
        if (event && event.length >= 3) {
            this.isShowSpinner = true;
            this.isLoading = true;
            this.searchObject = [];
            this.searchResultKeys = [
                ...new Set(
                    this.searchResult.map((list: { renewalCategoryId: any; }) => {
                        return list.renewalCategoryId;
                    })
                ),
            ];
            this.searchResultKeys = this.move(this.searchResultKeys, 0, 1);
            if (this.pageName === 'pending') {
                this.searchResultKeys = [1, 2, 3];

            }
            else if (this.pageName === 'history') {
                this.searchResultKeys = [3, 1, 2];
            }
            else if (this.pageName === 'active') {
                this.searchResultKeys = [2, 1, 3];
            }
            else {
                this.searchResultKeys = [1, 2, 3]; //For Home page notifications
            }
            for (let item of this.searchResultKeys) {

                const itemObject = this.searchResult
                    .filter((searchItem: { renewalCategoryId: any; }) => {
                        return searchItem.renewalCategoryId == item;
                    })
                    .map(
                        (record: {
                            renewalContractNumber: string;
                            contractNumber: string;
                            customerReference: string;
                            invoiceNumber: any;
                            customer: string;
                            startDate: any;
                            endDate: any;
                            countryCode: any;
                            languageCode: any;
                        }) => {
                            return ({
                                renewalContractNumber: (record.renewalContractNumber ?? ''),
                                contractNumber: (record.contractNumber ?? ''),
                                customerReference: (record.customerReference ?? ''),
                                invoiceNumber: (record.invoiceNumber ?? ''),
                                customer: (record.customer ?? ''),
                                startDate: (record.startDate ?? ''),
                                endDate: (record.endDate ?? ''),
                                countryCode: (record.countryCode ?? ''),
                                languageCode: (record.languageCode ?? ''),
                            });
                        }
                    );
                this.searchObject.push({
                    letter:
                        item == UniqueID.Pending
                            ? this.translate.instant('upcomingSupportRenewals')
                            : item == UniqueID.Active
                                ? this.translate.instant('activeSuppotRenewals')
                                : this.translate.instant('supportRenewalHistory'),
                    names: itemObject
                });
            }
            this.isShowSpinner = false;
            this.isLoading = false;
        }
        else {
            this.searchObject = [];
        }
    }

    selectedDropdown(obj: any) {
        this.searchObject = [];
        const rm = this.searchResult.filter(
            (a: { renewalContractNumber: string }) =>
                a.renewalContractNumber == obj.value.renewalContractNumber
        );
        localStorage.setItem("renewalUniqueID", rm[0].assureRenewalUniqueID);
        localStorage.setItem("renewalCategory", rm[0].renewalStatus);
        localStorage.setItem("renewalCategoryId", rm[0].renewalCategoryId);
        localStorage.setItem("contract", rm[0].renewalContractNumber);
        localStorage.setItem("rcID", rm[0].renewalMethodId);
        // this.renewalService.tabName = rm[0].renewalStatus;
        rm[0].renewalCategoryId == UniqueID.Pending ?
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([
                    RoutingUrlConstants.ORC_PENDING_DETAILS +
                    "/" +
                    rm[0].renewalContractNumber,
                ]);
            })
            :
            rm[0].renewalCategoryId == UniqueID.Active ?
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate([
                        RoutingUrlConstants.ORC_ACTIVE_DETAILS +
                        "/" +
                        rm[0].renewalContractNumber,
                    ]);
                }) :
                rm[0].renewalCategoryId == UniqueID.History ?
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                        this.router.navigate([
                            RoutingUrlConstants.ORC_HISTORY_DETAILS +
                            "/" +
                            rm[0].renewalContractNumber,
                        ]);
                    }) : '';

    }

    openPopupDialog() {
        const requestAssistance = {
            type: 'request-assistance'
        }
        const popupData: ComponentModel = {
            componentName: RequestAssistanceComponent,
            data: requestAssistance
        };
        this.popupService.dialogWidth = "large";
        this.popupService.openPopup(popupData);
        this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
            response && response.resType.requestAssistance.statusCode ?
                this._snakBar.showSnackBar(response.resType.requestAssistance.statusCode) : '';
        });
    }

    showSearchbar() {
        this.searchObject = [];
        this.showSearch = !this.showSearch;
    }

    menuClick(value?: string, index?: number) {
        this.selectedIndex = index;
        value === 'pending-renewals' ? this.urlPath = RoutingUrlConstants.ORC_PENDING_SUMMARY :
            value === 'active-contracts' ? this.urlPath = RoutingUrlConstants.ORC_ACTIVE_SUMMARY :
                value === 'renewal-history' ? this.urlPath = RoutingUrlConstants.ORC_HISTORY_SUMMARY :
                    value === 'reporting' ? this.urlPath = RoutingUrlConstants.ORC_REPORT : undefined;
        if (this.isLoggedIn) {
            this.router.navigate([this.urlPath]);
        } else {
            this.authService.login(this.urlPath);
        }
    }

    profileMenuOptionSelect(value: string) {
        value === 'sign-out' ? this.logout() :
            value === 'request-assistance' ? this.openPopupDialog() :
                value === 'manage-account' ? (window.open(environment.OT_IAM_MANAGE_ACCOUNT, "_blank")) :
                    value === 'help' ? (this.router.navigateByUrl(RoutingUrlConstants.ORC_HELP)) :
                        value === 'pay-my-bill' ? (window.open(this.pay_my_bill_link, "_blank")) :
                            value === 'sign-in' ? this.login() :
                                value === 'impersonate-user' ? this.impersonateUserPopup() :
                                    value === 'stop-impersonating' ? this.stopImpersonating() : undefined;
    }
    selectedMenuItem(currentURL?: string) {
        const routeDetailsURLs = currentURL?.slice(0, currentURL?.lastIndexOf('/'));
        (currentURL == RoutingUrlConstants.ORC_PENDING_SUMMARY
            || routeDetailsURLs == RoutingUrlConstants.ORC_PENDING_DETAILS) ?
            this.selectedIndex = 0 :
            (currentURL == RoutingUrlConstants.ORC_ACTIVE_SUMMARY
                || routeDetailsURLs == RoutingUrlConstants.ORC_ACTIVE_DETAILS) ?
                this.selectedIndex = 1 :
                (currentURL == RoutingUrlConstants.ORC_HISTORY_SUMMARY
                    || routeDetailsURLs == RoutingUrlConstants.ORC_HISTORY_DETAILS) ?
                    this.selectedIndex = 2
                    : (currentURL == RoutingUrlConstants.ORC_REPORT
                        || routeDetailsURLs == RoutingUrlConstants.ORC_REPORT) ?
                        this.selectedIndex = 3
                        : this.selectedIndex = undefined;
    }
    getMasters() {
        this.master$.pipe(
            switchMap((e) => {
                if (e?.isSuccess) {
                    this.profileMenu.map(m => {
                        this.canImpersonate = e.result.canImpersonate;
                        if (m.name === "manage-account" && this.canImpersonate) {
                            const impMenuItem = this.headerFooterDataConstants.impersonatorMenuItem;
                            m.label = impMenuItem.label;
                            m.name = impMenuItem.name;
                            m.isDisable = impMenuItem.isDisable;
                            sessionStorage.setItem(this.commonService.encryptData('canImpersonate'), this.commonService.encryptData(this.canImpersonate.toString()));
                        }
                    })
                    return of(this.canImpersonate);
                }
                else return EMPTY;
            }
            )).subscribe();
    }

    isImpersonator() {
        if (this.isEmployee) {
            if (this.commonService.isImpersonator() == null) {
                this.getMasters();
            } else {
                return this.commonService.isImpersonator();
            }
        }
        return false;
    }

    impersonateUserPopup() {
        const impersonateUserPopup: ComponentModel = {
            componentName: ImpersonateUserComponent,
            data: []
        };
        this.popupService.dialogWidth = 'medium';
        this.popupService.openPopup(impersonateUserPopup);
    }

    stopImpersonating() {
        sessionStorage.removeItem("impersonatedUser");
        localStorage.removeItem('cartCount');
        this.headerFooterDataConstants.postLoginBtnCnfg[0].badget = 0;
        this.router.navigate([RoutingUrlConstants.ORC_PENDING_SUMMARY])
            .then(() => {
                window.location.reload(); //Re-arranging the profile dropdown to default state
            })
    }
}
