import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ExtraOptions, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { UnauthorizedComponent } from './core/components/layout/components/unauthorized/unauthorized.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SignInRedirectCallbackComponent } from './modules/orc-pages/components/signin-redirect-callback.component';
import { SignOutRedirectCallbackComponent } from './modules/orc-pages/components/signout-redirect-callback.component';
import { TermsComponent } from './modules/orc-renewals/sub-modules/shared/components/terms/terms.component';
import { CustomErrorComponent } from './shared/components/customerror/custom-error.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'orc',
        pathMatch: 'full'
    },
    {
        path: 'orc/renewals',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
            import('./modules/orc-renewals/renewals.module').then(
                (m) => m.OrcRenewalsModule
            )
    },
    {
        path: 'orc/orders',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
            import('./modules/orders/orders.module').then(
                (m) => m.OrdersModule
            )
    },
    {
        path: 'orc/cart',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
            import('./modules/cart/cart.module').then(
                (m) => m.CartModule
            )
    },
    {
        path: 'orc/reporting',
        canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(next, state)],
        loadChildren: () =>
            import('./modules/report/report.module').then(
                (m) => m.ReportModule
            )
    },
    {
        path: 'orc',
        loadChildren: () =>
            import('./modules/orc-pages/orc-pages.module').then(
                (m) => m.OrcHomeLandingModule
            )
    },
    {
        path: 'signin-callback',
        component: SignInRedirectCallbackComponent
    },
    {
        path: 'signout-callback',
        component: SignOutRedirectCallbackComponent
    },

    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'orc/terms-conditions',
        component: TermsComponent
    },
    {
        path: 'orc/help',
        loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule)
    },
    {
        path: '**',
        component: CustomErrorComponent,
        data: { info: 'Data from Comp' }
    }
];

const routerOptions: ExtraOptions = {
    anchorScrolling: "enabled"
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
