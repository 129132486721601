import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { renewalParamRequest } from 'src/app/core/state/pending-summary/store/pending-summary.state';
import { UniqueID } from 'src/app/shared/enums/unique-id.enum';
import { BaseApiService } from 'src/app/shared/services/base-api.service';
import { MiddlewareUrls } from '../../pending/constants/middleware-urls';
import {
    AcitivityLogResponse,
    AddOnResponse,
    CartRenewals,
    CheckList, CheckOutRenewalResponse, CheckOutRequestParam, CheckOutResponse, CustomerReferenceRequest,
    CustomerReferenceResponse, DownloadInvoice, DownloadQuote, FitlerParams, GetCartRenewals, OrderRequestParam, OrderResponse, PaymentRequestParam, PendingRenewalsSummary,
    RemoveCartRenewals,
    RenewalContactResponse,
    RenewalDetails,
    RenewalUploadRequest,
    SearchParams,
    SearchRenewal,
    SortParams
} from '../../pending/interface/pending';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RenewalsService extends BaseApiService {

    tabName = '';

    scrollViewAction = '';

    constructor(http: HttpClient,private router: Router) {
        super(http);
    }

    summaryList(params: renewalParamRequest): Observable<PendingRenewalsSummary> {
        return this.getApiCall(MiddlewareUrls.getRenewals, params);
    }

    summaryDetails(params: SortParams): Observable<RenewalDetails> {
        return this.getApiCall(MiddlewareUrls.getRenewalDetailByRC, params);
    }

    updateUpsellAccepted(checkParams: CheckList) {
        return this.callPostRestApi(MiddlewareUrls.updateUpsellAccepted, checkParams);
    }

    uploadPO(checkParams: RenewalUploadRequest) {
        return this.callPostRestApi(MiddlewareUrls.uploadPO, checkParams);
    }

    requestAssistance(checkParams: RenewalUploadRequest) {
        return this.callPostRestApi(MiddlewareUrls.requestAssistance, checkParams);
    }

    uploadTaxExemptCertificate(checkParams: RenewalUploadRequest) {
        return this.callPostRestApi(MiddlewareUrls.uploadTaxExemptCertificate, checkParams);
    }

    checkOutRenewal(checkOutParams: CheckOutRequestParam): Observable<CheckOutResponse> {
        return this.callPostRestApi(MiddlewareUrls.checkOutRenewal, checkOutParams);
    }

    changePaymentMethod(paymentRequestParam: PaymentRequestParam): Observable<CheckOutResponse> {
        return this.callPostRestApi(MiddlewareUrls.changePaymentMethod, paymentRequestParam);
    }

    placeOrder(orderRequestParam: OrderRequestParam): Observable<OrderResponse> {
        return this.callPostRestApi(MiddlewareUrls.placeOrder, orderRequestParam);
    }

    getcheckOutRenewals(): Observable<CheckOutRenewalResponse> {
        return this.getApiCall(MiddlewareUrls.getcheckOutRenewals);
    }

    updateCustomerReference(param: CustomerReferenceRequest): Observable<CustomerReferenceResponse> {
        return this.callPostRestApi(MiddlewareUrls.updateCustomerReference, param);
    }

    getConfirmedRenewals(renewalUniqueID: any): Observable<CheckOutRenewalResponse> {
        let queryParams = {
            'renewalUniqueID': renewalUniqueID
        };
        return this.callPostRestApi(MiddlewareUrls.getConfirmedRenewals, queryParams); //changed to post call from get call
    }

    activityLogDetails(renewalUniqueID: string, renewalCategory: number): Observable<AcitivityLogResponse> {
        let queryParams = {
            'renewalUniqueID': renewalUniqueID,
            'renewalCategoryID': renewalCategory
        };
        return this.getApiCall(MiddlewareUrls.getActivityLogs, queryParams);
    }

    downloadQuote(rc: string, rcId: number): Observable<DownloadQuote> {
        let queryParams = {
            'renewalUniqueID': rc,
            'renewalCategoryID': rcId
        };

        return this.getApiCall(MiddlewareUrls.downloadQuoteAPI, queryParams);
    }

    downloadInvoice(rc: string, rcId: number): Observable<DownloadInvoice> {
        let queryParams = {
            'renewalUniqueID': rc,
            'renewalCategoryID': rcId
        };

        return this.getApiCall(MiddlewareUrls.downloadInvoiceAPI, queryParams);
    }

    searchRenewalsAPI(params?: SearchParams): Observable<SearchRenewal> {
        return this.getApiCall(MiddlewareUrls.searchRenewals, params);
    }

    getAddOns(params: string[]): Observable<AddOnResponse> {
        return this.callPostRestApi(MiddlewareUrls.getAddOns, params);
    }

    isAscCmpare(a: any, b: any) {
        const aStartDate = new Date(a.startDate).getTime();
        const bStartDate = new Date(b.startDate).getTime();
        const aEndDate = new Date(a.endDate).getTime();;
        const bEndDate = new Date(b.endDate).getTime();

        if (aStartDate < bStartDate || (aStartDate === bStartDate && aEndDate < bEndDate))
            return -1;
        else if (aStartDate > bStartDate || (aStartDate === bStartDate && aEndDate > bEndDate))
            return 1;
        else
            return 0;
    }

    isDescCompare(a: any, b: any) {
        const aStartDate = new Date(a.startDate).getTime();
        const bStartDate = new Date(b.startDate).getTime();
        const aEndDate = new Date(a.endDate).getTime();;
        const bEndDate = new Date(b.endDate).getTime();

        if (aStartDate > bStartDate || (aStartDate === bStartDate && aEndDate > bEndDate))
            return -1;
        else if (aStartDate < bStartDate || (aStartDate === bStartDate && aEndDate < bEndDate))
            return 1;
        else
            return 0;
    }

    addToCart(checkOutParams: CheckOutRequestParam): Observable<CartRenewals> {
        return this.callPostRestApi(MiddlewareUrls.addToCart, checkOutParams);
    }
    getCartRenewals(): Observable<GetCartRenewals> {
        return this.getApiCall(MiddlewareUrls.getCartRenewals);
    }
    removeFromCart(checkOutParams: CheckOutRequestParam): Observable<RemoveCartRenewals> {
        return this.callPostRestApi(MiddlewareUrls.removeFromCart, checkOutParams);
    }

    getReport(params: FitlerParams): Observable<RenewalDetails> {
        return this.callPostRestApi(MiddlewareUrls.getReport, params);
    }


    formatCurrencyToUSD(intValue: string, subTotal: string, intValueUSD: string) {
        let intValueOrig: number;
        intValueOrig = Number(intValue) !== 0 ? Number(intValue) : 1;
        return Number(subTotal) * (Number(intValueUSD) / intValueOrig);
    }
    getRenewalContacts(renewalUniqueID: string, renewalCategory: number): Observable<RenewalContactResponse> {
        let queryParams = {
            'renewalUniqueID': renewalUniqueID,
            'renewalCategoryID': renewalCategory
        };
        return this.getApiCall(MiddlewareUrls.getRenewalContacts, queryParams);
    }

    calculateValues(d: any, value: any) {
        return d.reduce((acc: any, key: any) => acc + Number(key[value]), 0);
    }

    getPaymentOptions(type?: number, paymentMethodCode?: any, renewalMethod?: any, rcId?: any, isPORequired?: any,
        renewalUniqueID?: any, inCart?: any, renewalMethodId?: any, updatePaymentType?: any) {
        let paymentOptions: any = {
            paymentMethodCode: paymentMethodCode,
            renewalMethod: renewalMethod,
            rcId: rcId,
            isPORequired: isPORequired,
            renewalUniqueID: renewalUniqueID,
            renewalMethodId: renewalMethodId,
            updatePaymentType: updatePaymentType
        };
        if (type == UniqueID.Pending || type == UniqueID.ConfirmPage) {
            paymentOptions = { ...paymentOptions, inCart: inCart };
        }
        return paymentOptions;
    }
    getPaymentType(chkUpdateAutoInvoice: any) {
        return chkUpdateAutoInvoice == "0" ? UniqueID.Annual : UniqueID.AutoInvoice;
    }
    summarySearchInputMethod(renewalCategoryId?: number,
        renewalMethodId?: number, pageIndex?: number, pageSize?: number) {
        let renewalParams: renewalParamRequest = {
            renewalCategoryId: renewalCategoryId,
            pageIndex: pageIndex,
            pageSize: pageSize
        }
        if (renewalCategoryId == UniqueID.Pending) {
            renewalParams = { ...renewalParams, renewalMethodId: renewalMethodId }
        }
        return renewalParams;
    }
    getLastIndexOfURL(url:string){
       return this.router.url.substring(url.lastIndexOf('/')+1);
    }
}
