import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MiddlewareUrls } from 'src/app/modules/orc-renewals/sub-modules/pending/constants/middleware-urls';
import { BaseApiService } from 'src/app/shared/services/base-api.service';

@Injectable()
export class HeaderService extends BaseApiService {

    constructor(http: HttpClient) {
        super(http);
    }

    getSearchRecords(searchValue: string): Observable<any> {
        let queryParams = {
            'searchParam': searchValue
        };
        return this.getApiCall(MiddlewareUrls.searchRenewals, queryParams);
    }
    geNotificationRecords(): Observable<any> {
        return this.getApiCall(MiddlewareUrls.notificationAPI);
    }
}