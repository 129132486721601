<div id="button-group"
     *ngIf="buttonConfig"
     [class]="(buttonConfig.disabled || isDisabled) ? 'not-allowed-pointer' : ''">
    <button class="button-group-lbl"
            [id]="buttonConfig.id"
            [disabled]="
            buttonConfig.disabled ||
            isDisabled ||
            (isEmployee &&
                !(isImpersonating && buttonConfig.id !== 'accept-terms-condition-btn') &&
                (buttonConfig.id === 'cancel-impersonate-user-btn' ||
                    buttonConfig.id === 'submit-impersonate-user-btn'
                    ? false
                    : true))
            "
            (click)="onButtonClick(buttonConfig.usecase)"
            [matTooltip]="buttonConfig.tooltip"
            [matBadge]="buttonConfig.badget"
            [matTooltipPosition]="'above'"
            *ngIf="buttonConfig.type === 'button'"
            [class]="buttonConfig.classStyles"
            mat-stroked-button>
        <mat-icon *ngIf="buttonConfig.icon">{{
      buttonConfig.icon
    }}</mat-icon>
        
        <span *ngIf="invoiceNumber">{{invoiceNumber}}</span>
        <span *ngIf="!invoiceNumber">{{ buttonConfig.label | translate}}</span>
    </button>
    <button (click)="onButtonClick(buttonConfig.usecase)"
            mat-icon-button
            [id]="buttonConfig.id"
            [disabled]="buttonConfig.disabled || (isEmployee && !isImpersonating && !isReportClick) || isDisabled"
            [matTooltip]="buttonConfig.tooltip"
            [matBadge]="buttonConfig.badget! > 0 ? buttonConfig.badget! : ''"
            [matTooltipPosition]="'above'"
            *ngIf="buttonConfig.type === 'justicon'"
            [class]="buttonConfig.classStyles">
        <mat-icon *ngIf="buttonConfig.icon"
                  [class]="buttonConfig.iconClass">
                  {{buttonConfig.icon}}
        </mat-icon>
    </button>
</div>