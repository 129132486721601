import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ButtonGroupInterface } from "src/app/shared/interface/button-group.interface";
import { FormFieldsInterface } from "src/app/shared/interface/form-fields.interface";
import { Selectoption } from "src/app/shared/interface/impersonate-user.interface";
import { ImpersonateUserService } from "src/app/shared/services/impersonate-user.service";
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';

@Component({
    selector: "app-impersonate-user",
    templateUrl: "./impersonate-user.component.html",
    styleUrls: ["./impersonate-user.component.sass"],
})
export class ImpersonateUserComponent {
    data: any | any[];

    impersonateUserConfig: FormFieldsInterface = {
        id: "impersonate-user",
        cssClass: "custom-cls",
        placeHolder: this.translate.instant("impersonateUserSearchPlaceholder"),
        maxLength: "10",
        minLength: "3",
        type: "autoComplete",
        disabled: false,
    };
    impersonateUserPopupBtnGroup: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("cancel"),
            type: "button",
            classStyles: "bordered",
            usecase: "cancel",
            disabled: false,
            id: "cancel-impersonate-user-btn",
        },
        {
            label: this.translate.instant("submit"),
            type: "button",
            classStyles: "solid",
            usecase: "submit",
            disabled: false,
            id: "submit-impersonate-user-btn",
        },
    ];

    impersonateUserForm = this.fb.group({
        impersonateUser: [""],
    });

    contactList: any = [];
    _contactList: any = [];

    selectedOption!: Selectoption;

    isLoading: boolean = false;
    isImpersonateUserSelected: boolean = false;
    impersonatedUser!: string;

    constructor(
        private fb: FormBuilder,
        private impersonateSvc: ImpersonateUserService,
        public dialogRef: MatDialogRef<any>,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.contactList = [];
    }

    getSearchInputValue(event: string) {
        this.isImpersonateUserSelected = false;
        if (event.length < 3) return;
        this.contactList = [{ letter: "Impersonation" }];
        const params = {
            searchTerm: event.toLowerCase(),
        };
        this.isLoading = true;
        this.impersonateSvc.getImpersonateContacts(params).subscribe((e) => {
            this._contactList = [];
            if (e.isSuccess) {
                e.result.map((e) => {
                    this._contactList.push({
                        value:
                            e.firstName +
                            " " +
                            e.lastName +
                            ", " +
                            e.email +
                            ", " +
                            e.telephone,
                        label:
                            e.firstName +
                            " " +
                            e.lastName +
                            "<br/>" +
                            e.email +
                            "<br/>" +
                            e.telephone,
                        item: `${e.firstName}~${e.lastName}~${e.email}~${e.idpuserid}`,
                    });
                });
                this.contactList = [
                    {
                        letter: "",
                        names: this._contactList,
                    },
                ];
            } else {
                this.contactList = [];
            }
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        });
    }

    selectedDropdown(obj: any) {
        this.isImpersonateUserSelected = true;
        this.impersonatedUser = obj.value.item;
    }

    selectImpersonateUser(e: string) {
        this.isLoading = true;
        if (e == "submit") {
            const arr = this.impersonatedUser.split('~');
            const params = {
                username: arr[3] || ''
            };
            this.impersonateSvc.getImpersonatedUserInfo(params).subscribe((e) => {
                const sessObj = {
                    "firstName": arr[0],
                    "lastName": arr[1],
                    "email": arr[2],
                    "lang": e.isSuccess ? e.result.language : '',
                    "countryCode": e.isSuccess ? e.result.countryCode : '',
                };
                sessionStorage.setItem("impersonatedUser", JSON.stringify(sessObj));
                this.isLoading = false;
                setTimeout(() => {
                    location.href = RoutingUrlConstants.ORC_PENDING_SUMMARY;
                })
            });
        } else {
            this.dialogRef.close(e);
        }
    }

    keyPress(event: KeyboardEvent) {
        return !this.isLoading;
    }
}
