import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ButtonGroupInterface } from "../../interface/button-group.interface";
import { CommonService } from "../../services/common.service";
@Component({
    selector: "app-button-group",
    templateUrl: "./button-group.component.html",
    styleUrls: ["./button-group.component.sass"],
    encapsulation: ViewEncapsulation.None
})
export class ButtonGroupComponent {
    /**
     * On Button Load if no config is given default config is as below.
     */
    @Input() buttonConfig: ButtonGroupInterface = {
        label: "Button",
        disable: false,
        color: "blue",
        type: "button",
        classStyles: "bordered",
        icon: "home",
        tooltip: "Button",
        badget: 3,
        usecase: "Sample",
        disabled: false,
        id: "sample-btn-id"
    };

    @Input() isDisabled = false;

    @Input() invoiceNumber:any;
    /**
     * Button on click event output the emit to parent component.
     */
    @Output() emitClickEvent = new EventEmitter<string>();
    @Input() isReportClick = false;
    isEmployee: boolean | undefined | null;
    isImpersonating = false;
    constructor(private commonService: CommonService) {
    }
    onButtonClick(clickType: string) {
        this.emitClickEvent.emit(clickType);
    }
    ngOnChanges() {
        this.isEmployee = this.commonService.isEmployee();
        this.isImpersonating = this.commonService.isImpersonating();
    }
}
