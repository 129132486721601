export const RoutingUrlConstants = {
    ORC_HOME: '/orc/home',
    ORC_LANDING: '/orc/landing',
    ORC_PENDING_SUMMARY: '/orc/renewals/pending/summary',
    ORC_PENDING_DETAILS: '/orc/renewals/pending/details',
    ORC_ACTIVE_SUMMARY: '/orc/renewals/active/summary',
    ORC_ACTIVE_DETAILS: '/orc/renewals/active/details',
    ORC_HISTORY_SUMMARY: '/orc/renewals/history/summary',
    ORC_HISTORY_DETAILS: '/orc/renewals/history/details',
    ORC_CONFIRM_ORDER: '/orc/orders/confirm-order',
    ORC_CONFIRM_PLACE_ORDER: '/orc/orders/confirm-place-order',
    ORC_PAYMENT: '/orc/orders/confirm-place-order/payment',
    ORC_TERMS_CONDITIONS: '/orc/terms-conditions',
    ORC_CART: '/orc/cart',
    ORC_HELP: '/orc/help',
    ORC_REPORT: '/orc/reporting',
    ORC_HELP_ARTICLE: '/orc/help/pending-support-renewals',
    ORC_HELP_ARTICLE_ONE: '/orc/help/active-support-contracts',
    ORC_HELP_ARTICLE_TWO: '/orc/help/history-renewals',
    ORC_HELP_ARTICLE_THREE: '/orc/help/auto-invoice',
    ORC_HELP_ARTICLE_FOUR: '/orc/help/languages',
    ORC_HELP_ARTICLE_FIVE: '/orc/help/addresses',
    ORC_HELP_ARTICLE_SIX: '/orc/help/customer-reference',
    ORC_HELP_ARTICLE_SEVEN: '/orc/help/activity-log',
    ORC_HELP_ARTICLE_EIGHT: '/orc/help/accept-pending-support-renewal',
    ORC_HELP_ARTICLE_NINE: '/orc/help/payment-method',
    ORC_HELP_ARTICLE_TEN: '/orc/help/available-payment-options',
    ORC_HELP_ARTICLE_ELEVEN: '/orc/help/terms-conditions',
    ORC_HELP_ARTICLE_TWELVE: '/orc/help/additional-support-options',
    ORC_HELP_ARTICLE_THIRTEEN: '/orc/help/technical-issues-feedback',
    ORC_HELP_ARTICLE_FOURTEEN: '/orc/help/contact-us',
    ORC_HELP_ARTICLE_FIFTEEN: '/orc/help/generic-faqs',
    ORC_HELP_ARTICLE_SIXTEEN: '/orc/help/notification-banner',
    ORC_HELP_ARTICLE_SEVENTEEN: '/orc/help/meaning-of-renewal-statuses',
    ORC_HELP_ARTICLE_EIGHTTEEN: '/orc/help/checkout-page',
    ORC_HELP_ARTICLE_NINETEEN: '/orc/help/how-tos',
    ORC_HELP_ARTICLE_TWENTY: '/orc/help/reporting',
    ORC_HELP_ARTICLE_TWENTY_ONE: '/orc/help/schedule-billing',
    ORC_HELP_ARTICLE_TWENTY_TWO: '/orc/help/contact-management',
    ORC_AVAILABLE_PAYMENT_TYPES: '/orc/help/available-payment-options'
}
export const ExternalUrlConstants = {
    ORC_SUPPORT_OFFERINGS: 'https://assets.ot.digital/img/image600x285.jpg',
    ORC_SUPPORT_OFFERINGS_1: '/orc/assets/images/tn_1',
    ORC_SUPPORT_OFFERINGS_2: '/orc/assets/images/tn_2',
    ORC_SUPPORT_OFFERINGS_3: '/orc/assets/images/tn_3',
}