import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';
@Component({
    selector: 'popup-data',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class PopupDataComponent implements OnInit {
    data: any;
    buttonConfig: ButtonGroupInterface = {
        color: "blue",
        type: "justicon",
        icon: "close",
        classStyles: '',
        usecase: '',
        id: ''
    };

    constructor(
        private router: Router,
        private translate: TranslateService,
        private route: ActivatedRoute,
        public dialogRef?: MatDialogRef<any>,
    ) {
    }
    ngOnInit(): void {

    }
    onButtonClick() {
        this.dialogRef?.close('closed');
    }
    redirectTo() {
        this.router.navigate([RoutingUrlConstants.ORC_AVAILABLE_PAYMENT_TYPES]);
        this.dialogRef?.close('closed');
    }
}
