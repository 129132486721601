import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    //access_token:any;
    isLoggedIn = false;
    email = "";
    language = "en";
    country = "US";
    private totalRequests = 0;
    private isRefresh = false;
    constructor(
        private _authService: AuthenticationService,
        private loadingService: LoaderService,
        private _snakBar: SnackBarService
    ) {
        this._authService.isAuthenticated$.subscribe((loggedIn) => {
            this.isLoggedIn = loggedIn;
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.totalRequests++;
        this.loadingService.setLoading(true);

        const isApiUrl = req.url.startsWith(environment.SERVER_URL);
        let accessToken = this._authService.accessToken!;
        if (isApiUrl && this.isLoggedIn && accessToken) {
            this.email = this._authService.getEmail();
            const impersonatedUser = JSON.parse(sessionStorage.getItem('impersonatedUser') || '{}');
            if (Object.keys(impersonatedUser).length > 0) {
                this.language = impersonatedUser.lang || this._authService.getLanguage();
                this.country = impersonatedUser.countryCode || this._authService.getCountry();
            } else {
                this.language = this._authService.getLanguage();
                this.country = this._authService.getCountry();
            }
            const headers = new HttpHeaders()
                .set('x-IAM-token', `${accessToken}`)
                .set('Email', `${this.email}`)
                .set('languageCode', `${this.language}`)
                .set('countryCode', `${this.country}`)
                .set('Access-Control-Allow-Origin', '*.opentext.com')
                .set('impersonationEmail', impersonatedUser.email || '');
            req = req.clone({ headers });
        }
        return next.handle(req).pipe(
            catchError((error) => {
                const respError = error as HttpErrorResponse;
                this._snakBar.showSnackBar(respError.status, false);
                if (respError.status === 401 && respError instanceof HttpErrorResponse) {
                    if (!this.isRefresh) {
                        this.isRefresh = true;
                        this._authService.refreshToken().then(res => {
                            this.isRefresh = false;
                            sessionStorage.setItem("access_token", res.access_token);
                            sessionStorage.setItem("refresh_token", res.refresh_token);
                            req.headers.set("x-IAM-token", res.access_token);
                            return next.handle(req);
                        })
                        console.error(respError.message);
                        this._authService.logout();
                    } else {
                        this.isRefresh = true;
                        return next.handle(req);
                    }
                }
                console.info(respError.message);
                return throwError(() => new Error(this._snakBar.interceptErrorMessage));
            }),
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests == 0) {
                    this.loadingService.setLoading(false);
                }
            })

        ) as Observable<HttpEvent<any>>

    }

}