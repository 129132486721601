import { ActionReducerMap, createReducer, on } from "@ngrx/store";
import { PendingRenewalsSummary } from "src/app/modules/orc-renewals/sub-modules/pending/interface/pending";
import { CheckListActionType, CheckListSummaryAction, pendingSummaryFetchAPISuccess } from "./pending-summary.actions";
import { CheckListSummaryState, initialCheckListState, initialPendingState } from "./pending-summary.state";

export const pendingSummaryReducer = createReducer(initialPendingState,
    on(pendingSummaryFetchAPISuccess, (state, { pendingSummaryAll }) => {
        return pendingSummaryAll;
    }));

export function CheckListSummaryReducer(
    state: CheckListSummaryState = initialCheckListState,
    action: CheckListSummaryAction | any
) {
    switch (action.type) {
        case CheckListActionType.CHECKLIST_ITEM:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}

export interface AppState {
    pendingState: PendingRenewalsSummary;
    checkListState: CheckListSummaryState;
}

export const reducerAction: ActionReducerMap<AppState> = {
    pendingState: pendingSummaryReducer,
    checkListState: CheckListSummaryReducer
};