import { DecimalPipe, formatCurrency } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { pendingConstantsLbl } from 'src/app/modules/orc-renewals/sub-modules/pending/constants/pending-constant';
import { Attachments, RenewalUploadRequest } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/pending';
import { RenewalsService } from 'src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service';
import { RequestAssistanceConstantData } from '../../constants/request-assistance.constants';
import { UniqueID } from '../../enums/unique-id.enum';
import { CommonService } from '../../services/common.service';
import { CurrencyService } from '../../services/currency.service';

@Component({
    selector: 'app-request-assistance',
    templateUrl: './request-assistance.component.html',
    styleUrls: ['./request-assistance.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class RequestAssistanceComponent implements AfterViewInit {
    /**
     * requestAssistanceForm holds whole form data
     */
    requestAssistanceForm!: FormGroup;
    /**
     * fieldObj hold form values
     */
    fieldObj: RequestAssistanceConstantData;
    data!: any;
    profileObject: any;
    renewalID: Array<string> = [];
    attachedFile!: Attachments[];
    retunResponse: any;
    multiRenewalUniqueID: any[] = [];
    showErrorMessage: any[] = [];
    validateFileMessage!: string;
    isLoading = false;
    displaySubject!: string;
    amountVal: boolean = false;
    type!: string;
    contractNumber!: any;
    originalPOAmount: any;
    inputCountryCode!: string;
    inputLanguageCode!: string;
    isImpersonating = false;
    constructor(
        public fb: FormBuilder,
        private commonService: CommonService,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<any>,
        private requestAssistanceConstData: RequestAssistanceConstantData,
        private renewalService: RenewalsService,
        private cdref: ChangeDetectorRef,
        private currencyService: CurrencyService,
        private pipe: DecimalPipe

    ) {
        this.fieldObj = requestAssistanceConstData;
        let profileObject = sessionStorage.getItem("id_token_claims_obj")!;
        this.profileObject = JSON.parse(profileObject);
        this.isImpersonating = this.commonService.isImpersonating();
    }

    ngAfterViewInit(): void {
        this.cdref.detectChanges();
    }

    ngOnInit() {
        this.type = this.data.type;
        this.inputCountryCode = this.data?.countryCode!;
        this.inputLanguageCode = this.data?.languageCode!;
        this.fieldObj.reasonConfig_Field.inputDisabled = false;
        const rcUID: string = localStorage.getItem('renewalUniqueID')!;
        if (rcUID && rcUID.includes(",")) {
            const rcIDS = rcUID.split(",");
            rcIDS.map(e => this.multiRenewalUniqueID.push(e))
        }
        else {
            this.multiRenewalUniqueID.push(rcUID);
        }
        localStorage.removeItem("disableCheckBox");
        this.requestAssistanceForm = this.fb.group({
            firstName: [''],
            lastName: [''],
            companyName: [this.data.company_name ? this.data.company_name : ''],
            uploadPO: [this.data.po_number ? this.data.po_number : ''],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            renewalContract: [''],
            //poAmount: [this.data.po_amount ? this.formatAmoutInCurrency(this.data.po_amount) : ''],
            poAmount: [this.data.po_amount ? this.data.po_amount : ''],
            poCurrency: [this.data.po_currency ? this.data.po_currency : ''],
            reasonPick: [''],
            subject: [''],
            description: [''],
            uploadReasonPick: ['']


        });
        // if (this.data.obj.length > 0) {
        //     this.requestAssistanceConstData.reasonConfig_Field.options?.push({
        //         label: this.translate.instant('removeScheduleBilling'),
        //         value: this.translate.instant('removeScheduleBilling')
        //     });
        // }
        if (this.isImpersonating) {
            const obj = this.commonService.getImpersonatedUserData();
            this.requestAssistanceForm.controls['firstName'].setValue(obj.firstName);
            this.requestAssistanceForm.controls['lastName'].setValue(obj.lastName);
            this.requestAssistanceForm.controls['email'].setValue(obj.email);
        } else {
            this.requestAssistanceForm.controls['firstName'].setValue(this.profileObject.given_name);
            this.requestAssistanceForm.controls['lastName'].setValue(this.profileObject.family_name);
            this.requestAssistanceForm.controls['email'].setValue(this.profileObject.email);
        }

        //this.requestAssistanceForm.controls['companyName'].setValue(localStorage.getItem('companyName'));
        this.requestAssistanceForm.controls['renewalContract'].setValue(localStorage.getItem('rc') ? localStorage.getItem('rc') : ' ');

        if (this.data.type == 'upload-PO') {
            // this.fieldObj.reasonConfig_Field.options = [
            //     {
            //         label: this.translate.instant('uploadDocument'),
            //         value: 'uploadDocument'
            //     }
            // ];
            this.requestAssistanceConstData.uploadConfig_Field.required = true;
            localStorage.getItem('rc')! ? this.contractNumber = localStorage.getItem('rc') : this.contractNumber = this.data.rcId;
            //this.fieldObj.reasonConfig_Field.inputDisabled = true;
            this.requestAssistanceForm.controls['uploadReasonPick'].setValue(this.translate.instant('uploadDocument'));
            this.displaySubject = this.contractNumber ? '#' + this.contractNumber + ' - ' +
                this.translate.instant('purchaseOrderRequest') + ' - ' +
                (this.data.po_number ? this.data.po_number : this.translate.instant('poNumber')) : this.translate.instant('purchaseOrderPONumber');
            this.requestAssistanceForm.controls['subject'].setValue(this.displaySubject);
            this.originalPOAmount = this.data.po_amount ? this.data.po_amount : '';
        };
        if (this.data.type == 'submit-tax-exemption') {
            // this.fieldObj.reasonConfig_Field.options = [
            //     {
            //         label: this.translate.instant('uploadDocument'),
            //         value: 'uploadDocument'
            //     }
            // ];
            this.requestAssistanceForm.controls['uploadReasonPick'].setValue(this.translate.instant('uploadDocument'));
            this.requestAssistanceForm.controls['subject'].setValue(`${localStorage.getItem('rc') ? '#' + localStorage.getItem('rc') + ' - ' : ''}${this.translate.instant('customerTaxExemptDocument')}`);
            this.requestAssistanceConstData.uploadConfig_Field.required = true;
        }
        if (this.data.type == this.requestAssistanceConstData.removeScheduledBill) {
            this.requestAssistanceForm.controls['uploadReasonPick'].setValue(this.translate.instant('removeScheduleBilling'));
            this.requestAssistanceForm.controls['subject'].setValue(this.translate.instant('customerAssistanceRequired')
                + " - " + this.translate.instant('removeRequestAssistanceSchedule'));
            this.requestAssistanceConstData.uploadConfig_Field.required = false;
        }
        else {
            this.fieldObj.reasonConfig_Field.options?.length! > this.fieldObj.requestAssistanceOptionsLength ?
                this.fieldObj.reasonConfig_Field.options?.splice(-1) : '';
        }
        this.data.type == 'request-assistance' ? (this.requestAssistanceForm.controls['subject']
            .setValue(this.translate.instant('customerAssistanceRequired')),
            this.requestAssistanceConstData.uploadConfig_Field.required = false) :
            (this.data.type == 'renewal-request-assistance') ? (this.requestAssistanceForm.controls['subject']
                .setValue(localStorage.getItem('rc') ? '#' + localStorage.getItem('rc')
                    + ' - ' + this.translate.instant('customerAssistanceRequired') : ''),
                this.requestAssistanceConstData.uploadConfig_Field.required = false) : undefined;

    }
    onButtonClick(e: string) {
        if (e == "submit") {
            const uploadPOData: RenewalUploadRequest = {
                renewalUniqueID: this.multiRenewalUniqueID,
                from: this.requestAssistanceForm.get('email')?.value,
                prefferedUserName: this.profileObject.preferred_username,
                fromDisplay: this.profileObject.name,
                subject: this.requestAssistanceForm.get('subject')?.value,
                attachments: this.attachedFile!,
                firstName: this.requestAssistanceForm.get('firstName')?.value,
                lastName: this.requestAssistanceForm.get('lastName')?.value,
                reason: this.requestAssistanceForm.get('reasonPick')?.value,
                companyName: this.requestAssistanceForm.get('companyName')?.value,
                description: this.requestAssistanceForm.get('description')?.value,
                renewalCategoryId: this.getRenewalMethodId(this.data?.renewalCategory)
            }
            if (this.data.type == 'upload-PO') {
                const poData = {
                    ...uploadPOData,
                    poNumber: this.requestAssistanceForm.get('uploadPO')?.value,
                    poAmount: this.originalPOAmount.toString().replaceAll(",", ''),//this.requestAssistanceForm.get('poAmount')?.value.toString().replace(",", ''),
                    poCurrency: this.requestAssistanceForm.get('poCurrency')?.value,
                    reason: this.requestAssistanceForm.get('uploadReasonPick')?.value,
                }
                const msgLen = this.validateUploadPOForm(poData);
                msgLen < 1 ? this.poUpload(poData, e) : undefined;

            } else if (this.data.type === 'renewal-request-assistance' || this.data.type === 'request-assistance') {
                let assistanceData;
                if (this.data.type === 'renewal-request-assistance') {
                    assistanceData = {
                        ...uploadPOData,
                        renewalContract: this.requestAssistanceForm.get('renewalContract')?.value
                    }
                }
                else {
                    uploadPOData.renewalUniqueID = [];
                    assistanceData = uploadPOData;
                }
                const msgLen = this.validateUploadPOForm(assistanceData);
                msgLen < 1 ? this.requestAssistance(assistanceData, e) : undefined;

            } else if (this.data.type === 'submit-tax-exemption') {
                const submitTaxData = {
                    ...uploadPOData,
                    reason: this.requestAssistanceForm.get('uploadReasonPick')?.value,
                }
                const msgLen = this.validateUploadPOForm(submitTaxData);
                msgLen < 1 ? this.uploadTaxExemptCertificate(submitTaxData, e) : undefined;
            }
            else if (this.data.type === this.requestAssistanceConstData.removeScheduledBill) {
                const { companyName, ...removedUnusedProps } = uploadPOData; //Removing company name from remove-scheduling-assistance
                const removeScheduleData = {
                    ...removedUnusedProps,
                    renewalUniqueID: this.data.obj,
                    reason: this.requestAssistanceForm.get('uploadReasonPick')?.value,
                    requestAssistanceSource: this.requestAssistanceConstData.removeScheduleFlag
                }
                const msgLen = this.validateUploadPOForm(removeScheduleData);
                msgLen < 1 ? this.requestAssistance(removeScheduleData, e) : undefined;
            }

        }
        else {
            this.dialogRef.close(this.retunResponse);
        }
    }
    changeSubject(e: any) {
        let poValue = '';
        e.target.value != '' ? poValue = e.target.value : poValue = this.translate.instant('poNumber');
        this.displaySubject = this.contractNumber ? '#' + this.contractNumber + ' - ' +
            this.translate.instant('purchaseOrderRequest') + ' - ' + poValue : this.translate.instant('purchaseOrderPONumber');
        this.requestAssistanceForm.controls['subject'].setValue(this.displaySubject);
    }

    uploadTaxExemptCertificate(uploadPOData: RenewalUploadRequest, value: string) {
        this.isLoading = true;
        this.renewalService
            .uploadTaxExemptCertificate(uploadPOData)
            .subscribe((response) => {
                this.retunResponse = { btnType: value, resType: response };
                this.dialogRef.close(this.retunResponse);
                this.isLoading = false;
            })
    }

    requestAssistance(uploadPOData: RenewalUploadRequest, value: string) {
        this.isLoading = true;
        this.renewalService
            .requestAssistance(uploadPOData)
            .subscribe((response) => {
                this.retunResponse = { btnType: value, resType: response };
                this.dialogRef.close(this.retunResponse);
                this.isLoading = false;
            });
    }

    poUpload(uploadPOData: RenewalUploadRequest, value: string) {
        this.isLoading = true;
        this.renewalService
            .uploadPO(uploadPOData)
            .subscribe((response) => {
                this.retunResponse = { btnType: value, resType: response };
                this.dialogRef.close(this.retunResponse);
                this.isLoading = false;
            })
    }

    validateUploadPOForm(uploadPOData: RenewalUploadRequest) {
        this.showErrorMessage.length = 0;
        uploadPOData.firstName == '' ?
            this.showErrorMessage.push(this.requestAssistanceConstData.firstNameConfig_Field.errorMessage) : '';
        uploadPOData.lastName == '' ?
            this.showErrorMessage.push(this.requestAssistanceConstData.lastNameConfig_Field.errorMessage) : '';
        uploadPOData.email == '' ?
            this.showErrorMessage.push(this.requestAssistanceConstData.emailConfig_Field.errorMessage) : '';
        (this.data.type == 'upload-PO' && uploadPOData.poNumber == '') ?
            this.showErrorMessage.push(this.requestAssistanceConstData.poNumberConfig_Field.errorMessage) : '';
        (this.data.type == 'upload-PO' && uploadPOData.poAmount == '') ?
            this.showErrorMessage.push(this.requestAssistanceConstData.poAmountConfig_Field.errorMessage) : '';
        (this.data.type == 'upload-PO' && uploadPOData.poCurrency == '') ?
            this.showErrorMessage.push(this.requestAssistanceConstData.poCurrencyConfig_Field.errorMessage) : '';
        (this.data.type == 'renewal-request-assistance' && uploadPOData.renewalContract == '') ?
            this.showErrorMessage.push(this.requestAssistanceConstData.renewalContractConfig_Field.errorMessage) : '';
        uploadPOData.reason == '' ?
            this.showErrorMessage.push(this.requestAssistanceConstData.uploadReasonConfig_Field.errorMessage) : '';
        uploadPOData.subject == '' ?
            this.showErrorMessage.push(this.requestAssistanceConstData.subjectConfig_Field.errorMessage) : '';
        ((!uploadPOData.attachments || uploadPOData.attachments?.length! < 1) && (this.data.type == 'upload-PO' || this.data.type == 'submit-tax-exemption')) ?
            this.showErrorMessage.push(this.requestAssistanceConstData.uploadConfig_Field.errorMessage) : '';
        this.validateFileMessage ? this.showErrorMessage.push(this.validateFileMessage) : '';
        (uploadPOData.poAmount != '' && this.data.type == 'upload-PO') ?
            this.validateAmount(uploadPOData.poAmount!) : '';
        return this.showErrorMessage.length;
    }
    validateAmount(amount: string) {
        if (amount && !amount.includes(".")) {
            if (amount.replace(".", "").length > Number(this.fieldObj.poAmountMaxDigits)) {
                this.showErrorMessage.push(`${this.translate.instant('poAmountMsg')} ${this.translate.instant('poAmountMsgInvalidDigits')}`);
            }
        }
        else if (amount && amount.includes(".")) {
            const splitAmount = amount.split(".");
            if (amount.replace(".", "").length < Number(this.fieldObj.poAmountConfig_Field.maxLength!)
                && (splitAmount[0].length > Number(this.fieldObj.poAmountMaxDigits)
                    || splitAmount[1].length > Number(this.fieldObj.poAmountMaxDecimals))) {
                this.showErrorMessage.push(`${this.translate.instant('poAmountMsg')} ${this.translate.instant('poAmountMsgInvalidDigits')}`);
            }
        }
    }

    getSelectedFile(file: any) {
        if (file) {
            const fileValidate = this.fileUploadValidator(this.requestAssistanceConstData.allowedFileExtension, file);
            if (fileValidate && file.size < this.requestAssistanceConstData.maxFileSize && file.size > 0) {
                const fileInfo = {
                    name: file.name,
                    content: (file.base64.split(',')[1])
                }
                this.attachedFile = [fileInfo];
                this.validateFileMessage = '';
            }
            else {
                !fileValidate ? this.validateFileMessage = this.translate.instant("invalidFileError")
                    : file.size > this.requestAssistanceConstData.maxFileSize ? this.validateFileMessage = this.translate.instant("invalidFileSize")
                        : file.size <= 0 ? this.validateFileMessage = this.translate.instant("fileSizeZero") : this.validateFileMessage = '';
                this.attachedFile = [];
            }

        }
        else {
            this.attachedFile = [];
        }
    }

    /**
    * Custom Validator for Upload File extensions
    * @param allowedExtensions any
    */
    fileUploadValidator(allowedExtensions: any, file: any) {
        const fileExt = file.name;
        // Get extension from file name
        const ext = fileExt.substring(fileExt.lastIndexOf('.') + 1);
        // Find extension file inside allowed extensions array
        if (allowedExtensions.includes(ext.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    getRenewalMethodId(type?: string) {
        switch (type) {
            case pendingConstantsLbl.pending:
                return UniqueID.Annual;
            case pendingConstantsLbl.active:
                return UniqueID.Active;
            case pendingConstantsLbl.history:
                return UniqueID.History
            default:
                return;
        }
    }
    formatAmountValue(event: any) {
        // if (event.target.value.replace(/[^\w\s]/gi, '') != this.originalPOAmount.replace(/[^\w\s]/gi, '')) {
        // let formatedAmount;
        this.originalPOAmount = event.target.value;
        // event.target.value ? formatedAmount = this.formatAmoutInCurrency(event.target.value.replaceAll(",", '')) : '';
        this.requestAssistanceForm.controls['poAmount'].setValue(event.target.value);
        // }
    }

    formatAmoutInCurrency(value: any) {
        return formatCurrency(value,
            this.currencyService.getLocaleByCurrency(this.data.countryCode, this.data.languageCode), '', '', '1.2-5');
    }

}