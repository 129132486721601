<ng-container *ngIf="fieldConfig"
              [ngSwitch]="fieldConfig.type">
    <div id="common-text-select-box"
         *ngSwitchCase="'textbox'">
        <mat-label *ngIf="fieldConfig.label">{{fieldConfig.label | translate}}
            <span *ngIf="fieldConfig.required"
                  class="mandatory-symbol">* </span>
        </mat-label>

        <mat-form-field [class]="fieldConfig.cssClass"
                        [id]="fieldConfig.id">
            <input *ngIf="!fieldConfig.isNumber else isNumber"
                   matInput
                   [formControl]="control"
                   [readonly]="fieldConfig.disabled ? fieldConfig.disabled : false"
                   [minlength]="fieldConfig.minLength ? fieldConfig.minLength :'0'"
                   [maxlength]="fieldConfig.maxLength ? fieldConfig.maxLength : ''"
                   [placeholder]="fieldConfig.placeHolder ? getTranslatedText(fieldConfig.placeHolder) : ''"
                   [title]="fieldConfig.label ? getTranslatedText(fieldConfig.label) : getTranslatedText(fieldConfig.placeHolder)" />

            <ng-template #isNumber>
                <input matInput
                       oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                       onpaste="return false;"
                       (change)="formatCurrency_TaxableValue($event)"
                       [formControl]="control"
                       [readonly]="fieldConfig.disabled ? fieldConfig.disabled : false"
                       [minlength]="fieldConfig.minLength ? fieldConfig.minLength :'0'"
                       [maxlength]="fieldConfig.maxLength ? fieldConfig.maxLength : ''"
                       [placeholder]="fieldConfig.placeHolder ? getTranslatedText(fieldConfig.placeHolder) : ''"
                       [title]="fieldConfig.label ? getTranslatedText(fieldConfig.label) : getTranslatedText(fieldConfig.placeHolder)" />
            </ng-template>

        </mat-form-field>
        <div *ngIf="control.errors"
             class="invalid-feedback">
            <div *ngIf="control.errors.required"></div>
        </div>
    </div>
    <div id="common-text-select-box"
         *ngSwitchCase="'email'">
        <mat-label *ngIf="fieldConfig.label">{{fieldConfig.label  | translate}}
            <span *ngIf="fieldConfig.required"
                  class="mandatory-symbol">* </span></mat-label>
        <mat-form-field [class]="fieldConfig.cssClass"
                        [id]="fieldConfig.id">
            <input matInput
                   [formControl]="control"
                   [readonly]="fieldConfig.disabled ? fieldConfig.disabled : false"
                   [minlength]="fieldConfig.minLength ? fieldConfig.minLength :'0'"
                   [maxlength]="fieldConfig.maxLength ? fieldConfig.maxLength : ''"
                   [placeholder]="fieldConfig.placeHolder ? getTranslatedText(fieldConfig.placeHolder) : ''"
                   [title]="fieldConfig.label ? getTranslatedText(fieldConfig.label) : getTranslatedText(fieldConfig.placeHolder)" />
        </mat-form-field>
        <div *ngIf="control.errors"
             class="invalid-feedback">
            <div *ngIf="control.errors.required"></div>
            <div *ngIf="control.errors.email"></div>
        </div>
    </div>

    <div id="common-radio"
         *ngSwitchCase="'radio'">
        <mat-radio-group [formControl]="control"
                         [class]="fieldConfig.cssClass">
            <mat-radio-button [disabled]="fieldConfig.disabled ? fieldConfig.disabled : false"
                              *ngFor="let item of fieldConfig.options"
                              [value]="item.value">{{item.label | translate}}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div id="common-textarea"
         *ngSwitchCase="'textarea'">
        <mat-label *ngIf="fieldConfig.label">{{fieldConfig.label  | translate}} <span *ngIf="fieldConfig.required"
                  class="mandatory-symbol">* </span></mat-label>
        <mat-form-field [class]="fieldConfig.cssClass">
            <textarea [rows]="fieldConfig.rows ? fieldConfig.rows : 2"
                      [cols]="fieldConfig.cols ? fieldConfig.cols : '5'"
                      [maxlength]="fieldConfig.maxLength ? fieldConfig.maxLength : '5'"
                      matInput
                      [disableControl]="fieldConfig.inputDisabled ? fieldConfig.inputDisabled : false"
                      [formControl]="control"
                      [placeholder]="fieldConfig.placeHolder ? getTranslatedText(fieldConfig.placeHolder) : ''"
                      [title]="fieldConfig.label ? getTranslatedText(fieldConfig.label) : getTranslatedText(fieldConfig.placeHolder)"></textarea>
        </mat-form-field>
    </div>
    <div id="common-checkbox"
         *ngSwitchCase="'checkbox'">
        <mat-checkbox [class]="fieldConfig.cssClass"
                      [formControl]="control">{{fieldConfig.label | translate}}</mat-checkbox>
    </div>
    <div id="common-text-select-box"
         *ngSwitchCase="'select'">
        <mat-label *ngIf="fieldConfig.label"
                   class="float-left width100">{{fieldConfig.label  | translate}} <span *ngIf="fieldConfig.required"
                  class="mandatory-symbol">* </span></mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="control"
                        [disableControl]="fieldConfig.inputDisabled ? fieldConfig.inputDisabled : false"
                        name="select"
                        [placeholder]="getTranslatedText(fieldConfig.placeHolder!)">
                <mat-option *ngFor="let item of fieldConfig.options"
                            (click)="selectionChange(item.value)"
                            [disabled]="fieldConfig.disabled ? fieldConfig.disabled : false"
                            [value]="item.value">
                    {{item.label | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div id="common-text-search-box"
         *ngSwitchCase="'search'">
        <mat-form-field [class]="fieldConfig.cssClass"
                        [id]="fieldConfig.id">
            <input matInput
                   appInputRestriction
                   [formControl]="control"
                   (input)="onBlurMethod($event)"
                   [readonly]="fieldConfig.disabled ? fieldConfig.disabled : false"
                   [minlength]="fieldConfig.minLength ? fieldConfig.minLength :'0'"
                   [maxlength]="fieldConfig.maxLength ? fieldConfig.maxLength : ''"
                   [placeholder]="fieldConfig.placeHolder ? getTranslatedText(fieldConfig.placeHolder) : ''"
                   title="Search">
            <mat-icon class="material-symbols-outlined"
                      (click)="searchResults()"> search</mat-icon>
        </mat-form-field>
    </div>
    <div id="common-text-autoComplete-box"
         *ngSwitchCase="'autoComplete'">
        <form [formGroup]="stateForm">
            <mat-form-field id="header-search-input"
                            appearance="fill"
                            [class]="fieldConfig.id === 'impersonate-user' ? 'ml-0' : ''">
                <mat-label *ngIf="fieldConfig.id !== 'impersonate-user'"
                           hidden="true">Search the entire site</mat-label>
                <input type="text"
                       appInputRestriction
                       placeholder="{{fieldConfig.placeHolder! | translate}} "
                       matInput
                       formControlName="stateGroup"
                       [matAutocomplete]="autoGroup"
                       (keydown)="_handleKeydown($event)"
                       (input)="onBlurMethod($event)"
                       title="search">
                <mat-icon class="material-symbols-outlined"
                          id="xl-icon-24">
                    search</mat-icon>
                <div class="overlay-backdrop"
                     *ngIf="isLoading">
                    <mat-progress-spinner color="primary"
                                          mode="indeterminate"
                                          diameter="50">
                    </mat-progress-spinner>
                </div>
                <!-- #docregion mat-autocomplete -->
                <mat-autocomplete #autoGroup="matAutocomplete"
                                  [class]="fieldConfig.id === 'impersonate-user' ? 'impersonating-autocomplete-panel' : 'global-search-autocomplete-panel'">
                    <mat-optgroup *ngFor="let group of autoSearch">
                        <cdk-virtual-scroll-viewport itemSize="50"
                                                     minBufferPx=100
                                                     maxBufferPx=250
                                                     class="example-viewport">
                            <mat-label *ngIf="fieldConfig.id !== 'impersonate-user'"
                                       class="search-autocomplete-label">
                                {{group.letter}}
                            </mat-label>
                            <mat-option *cdkVirtualFor="let name of group.names"
                                        [value]="getOptionLabel(name, fieldConfig.id)"
                                        (click)="onOptionSelection(group.letter, name)">
                                <span [innerHtml]="getOptionLabel(name)"></span>
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </mat-optgroup>
                    <div *ngIf="noDataFound">
                        <mat-option>
                            <span>{{'noResults' | translate}}</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <!-- #enddocregion mat-autocomplete -->
            </mat-form-field>

        </form>
    </div>
    <div id="common-text-select-box"
         *ngSwitchCase="'fileUpload'">

        <div class="row">
            <div class="col-md-12 col-lg-12">
                <mat-label *ngIf="fieldConfig.label"
                           class="file-upload-label">{{fieldConfig.label | translate}}
                    <span *ngIf="fieldConfig.required"
                          class="mandatory-symbol">* </span></mat-label>
            </div>
        </div>
        <div class="row">
            <!--<div [class]="fieldConfig.purchaseOrder ? 'col-md-8 col-lg-8' : 'col-md-6 col-lg-6'">-->
            <div [class]="fieldConfig.purchaseOrder ? 'col-md-6 col-lg-6' : 'col-md-6 col-lg-6'">
                <mat-form-field [class]="fieldConfig.cssClass"
                                [id]="fieldConfig.id"
                                [class]="fieldConfig.purchaseOrder ? 'po-file-upload-form-field' : 'file-upload-form-field'">
                    <input [hidden]="true"
                           (change)="onFileSelect($event)"
                           type="file"
                           accept="all/*"
                           #fileSelect
                           [title]="fieldConfig.label">
                    <input readonly
                           matInput
                           [(ngModel)]="filename"
                           [placeholder]="getTranslatedText(fieldConfig.placeHolder)"
                           [title]="fieldConfig.label ? getTranslatedText(fieldConfig.label) : getTranslatedText(fieldConfig.placeHolder)" />
                    <button *ngIf="this.base64File"
                            class="remove-btn"
                            (click)="this.base64File = null; this.filename = null; removeFile()"
                            matSuffix
                            type="button"
                            color="warn"
                            mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <!--<div [class]="fieldConfig.purchaseOrder ? 'col-md-4 col-lg-4' : 'col-md-6 col-lg-6'">-->
            <div [class]="fieldConfig.purchaseOrder ? 'col-md-6 col-lg-6' : 'col-md-6 col-lg-6'">
                <button mat-stroked-button
                        [class]="fieldConfig.disabled ? 'file-upload-btn-css is-disable' :'file-upload-btn-css' "
                        (click)="fileSelect.click();"
                        type="button"
                        [disabled]="fieldConfig.disabled">
                    <span *ngIf="fieldConfig.purchaseOrder">{{'uploadPo' | translate}}</span>
                    <span *ngIf="!fieldConfig.purchaseOrder">{{'browse' | translate}}</span>
                </button>
            </div>
        </div>
    </div>

    <div id="common-text-select-box"
         class="app-select-filter"
         *ngSwitchCase="'selectFilter'">
        <form [formGroup]="stateForm">
            <mat-form-field [class]="fieldConfig.cssClass"
                            [id]="fieldConfig.id">
                <mat-select formControlName="selectFilter"
                            (opened)="onDropdownSelect()"
                            (closed)="onDropdownSelect()"
                            (selectionChange)="onSelectOption($event)"
                            [disableControl]="fieldConfig.inputDisabled ? fieldConfig.inputDisabled : false"
                            name="select"
                            [placeholder]="fieldConfig.placeHolder!">
                    <mat-select-trigger>
                        <div class="custom-selected-value-label">
                            <div [innerHtml]="selectedOptionObj?.label"></div>
                        </div>
                    </mat-select-trigger>
                    <div class="selectFilter-input"
                         [class]="noResultLbl ? 'd-none' : ''">
                        <input matInput
                               #selectFilter
                               (input)="onBlurMethod($event)"
                               (keydown)="_handleKeydown($event)"
                               aria-label="Search the available options">
                    </div>
                    <div class="select-filter-group">
                        <ng-container>
                            <mat-option *ngFor="let group of stateGroups"
                                        [value]="group.value"
                                        (click)="optionSelection(group)">

                                <span [innerHTML]="group.label"></span>
                            </mat-option>
                        </ng-container>
                        <ng-container *ngIf="noResultLbl">
                            <mat-option value="noRecord"
                                        class="is-disable">
                                {{'noResults' | translate}}
                            </mat-option>
                        </ng-container>
                    </div>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</ng-container>

<!-- BELOW CODE IS FOR TESTING NEED IT FOR NOW AND WILL REMOVE IT ONCE IMPLEMENTATION IS DONE... -->
<!-- <form [formGroup]="formGroup">
    Text Box
    <app-form-fields [formControlName]="'textbox'" [fieldConfig]="textboxField"></app-form-fields>
    Checkbox
    <app-form-fields [formControlName]="'checkbox'" [fieldConfig]="checkboxField"></app-form-fields>
    Radio Group
    <app-form-fields [formControlName]="'radiobutton'" [fieldConfig]="radioField"></app-form-fields>
    Textarea
    <app-form-fields [formControlName]="'textarea'" [fieldConfig]="textAreaField"></app-form-fields>
    Select

br/>
  <p>Form Value</p>
<code>{{formGroup.value | json}}</code> -->


<!-- formGroup = this.formBuilder.group({
    textbox: "Kern",
    checkbox: false,
    radiobutton: "",
    textarea: "",
    selectIn: "",
  });
  /**
   * text box field config
   */
  textboxField: FormFieldsInterface = {
    label: "Textbox Label",
    cssClass: "custom-cls",
    placeHolder: "Enter the text",
    maxLength: "10",
    minLength: "5",
    type: "textbox",
    disabled: false,
    id: '',
  };
  radioField: FormFieldsInterface = {
    label: "Textbox Label",
    cssClass: "custom-cls",
    placeHolder: "Enter the text",
    maxLength: "10",
    minLength: "5",
    type: "radio",
    id: '',
    disabled: true,
    options:[
        {
            label: 'Google',
            value: 'goo'
        },
        {
            label: 'Microsoft',
            value: 'micro'
        },
        {
            label: 'opentext',
            value: 'open'
        },
    ]
  };
  checkboxField: FormFieldsInterface = {
    label: "Textbox Label",
    cssClass: "custom-cls",
    placeHolder: "Enter the text",
    maxLength: "10",
    minLength: "5",
    type: "checkbox",
    id: '',
    options:[
        {
            label: 'Oracle',
            value: 'oracle'
        },
        {
            label: 'JP Morgan',
            value: 'jpmc'
        },
        {
            label: 'Service Now',
            value: 'servicenow'
        },
    ]

  };
  textAreaField: FormFieldsInterface = {
    label: "Textbox Label",
    cssClass: "custom-cls",
    placeHolder: "Enter the text",
    maxLength: "10",
    minLength: "5",
    id: '',
    type: "textarea",
    rows:5,
    cols:25
  };
  selectField: FormFieldsInterface = {
    label: "Textbox Label",
    cssClass: "custom-cls",
    placeHolder: "Enter the text",
    maxLength: "10",
    minLength: "5",
    type: "select",
    disabled: true,
    defaultValue: "oracle",
    id: '',
    options:[
        {
            label: 'Oracle',
            value: 'oracle'
        },
        {
            label: 'JP Morgan',
            value: 'jpmc'
        },
        {
            label: 'Service Now',
            value: 'servicenow'
        },
    ]
  }; -->