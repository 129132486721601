import { Component, Input } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: './spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.sass']
})
export class SpinnerOverlayComponent {

    @Input() value = 100;

    @Input() diameter = 100;

    @Input() mode: any = "indeterminate";

    @Input() strokeWidth = 10;

    @Input() isOverlay = false;

    @Input() color = "primary";

    constructor(public loader: LoaderService) { }
}
