import { Component, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentModel, IComponent } from '../../interface/popup.interface';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
    /**
     * reference variable to display dynamic component
     */
    @ViewChild('dynamicLoadComponent') dynamicLoadComponent: ComponentRef<IComponent> | undefined;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ComponentModel,
        public dialogRef: MatDialogRef<PopupComponent>,
        private viewContainerRef: ViewContainerRef) {
    }

    /**
     * Setting up the dynamic component & data
     */
    ngOnInit() {
        if (this.data && this.data.componentName) {
            this.dynamicLoadComponent = this.viewContainerRef.createComponent<IComponent>(this.data.componentName);
            this.dynamicLoadComponent.instance.data = this.data.data;
        }
    }
}
