import { TranslateService } from '@ngx-translate/core';
import { TableColumn, TableStatus } from 'src/app/shared/components/table/TableColumn';
import { ButtonGroupInterface } from 'src/app/shared/interface/button-group.interface';
import { FormFieldsInterface } from 'src/app/shared/interface/form-fields.interface';

export class PendingTableDataConstants {

    constructor(public translate: TranslateService) { }

    autoSearch: FormFieldsInterface = {
        label: "search Label",
        cssClass: "custom-cls",
        maxLength: "500",
        minLength: "3",
        type: "search",
        disabled: true,
        id: ''
    };

    selectFilter: FormFieldsInterface = {
        label: "Select filter",
        cssClass: "custom-cls",
        maxLength: "50",
        minLength: "3",
        placeHolder: this.translate.instant("pleaseSelect"),
        type: "selectFilter",
        disabled: false,
        id: 'selectFilter'
    };

    downloadPdf = "https://www.opentext.com/assets/documents/en-US/pdf/opentext-extended-support-handbook-en.pdf";

    downloadImg = "https://www.opentext.com/assets/images/shared/opentext-image-worksmarter-ce-homepage-720x608-en.png";

    pendingSummaryInfo: TableColumn[] = [
        {
            name: '',
            dataKey: 'isSelected',
            type: 'isSelected',
            isSortable: false
        },
        {
            name: this.translate.instant("rc"),
            dataKey: 'renewalContractNumber',
            position: 'left',
            isSortable: true,
            isLink: true,
            title: this.translate.instant("sortByRC")
        },
        {
            name: this.translate.instant("customer"),
            dataKey: 'startDate',
            position: 'left',
            type: 'isCustomer',
            isSortable: true,
            sortByField: 'startDate',
            sortDirection: 'asc',
            title: this.translate.instant("sortCustomersByTerm")
        },
        {
            name: this.translate.instant("amount"),
            dataKey: 'currencyValue',
            position: 'left',
            isSortable: true,
            type: 'isCurrency',
            title: this.translate.instant("sortByAMount")
        },
        {
            name: this.translate.instant("payment"),
            dataKey: 'paymentMethod',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByPaymentMethod")
        }
    ];

    pendingSummaryCheckout: ButtonGroupInterface[] = [
        // {
        //     type: "justicon",
        //     classStyles: "icon",
        //     icon: "list_alt",
        //     usecase: "pending-renewal",
        //     id: "pending-summary-report-list-icon",
        //     disabled: true,
        //     iconClass: "disabled-cls"
        // },
        {
            label: this.translate.instant("addToCart"),
            type: "button",
            classStyles: "bordered",
            icon: "shopping_cart",
            usecase: "addToCart",
            disabled: false,
            id: "pending-summary-add-cart-btn"
        },
        {
            label: this.translate.instant("checkOut"),
            type: "button",
            classStyles: "solid",
            icon: "arrow_upward",
            usecase: "checkoutBtn",
            disabled: false,
            id: "pending-summary-checkout-btn"
        }
    ];

    pendingSupportContract: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("updatePrimaryContactDetails"),
            icon: "cached",
            type: "button",
            classStyles: "bordered icon-position",
            usecase: "update-primary-contact",
            id: "pending-summary-update-primary-btn",
            disabled: true
        },
        // {
        //     label: this.translate.instant("changeEndUserAddressBtn"),
        //     icon: "cached",
        //     type: "button",
        //     classStyles: "bordered",
        //     usecase: "pending-renewal",
        //     id: "pending-summary-change-enduser-btn",
        //     disabled: true
        // },
        {
            label: this.translate.instant("requestAddressChange"),
            icon: "cached",
            type: "button",
            classStyles: "bordered icon-position",
            usecase: "change-address",
            id: "pending-summary-change-address-btn",
            disabled: true
        },
        // {
        //     label: this.translate.instant("shareBtn"),
        //     icon: "download",
        //     type: "button",
        //     classStyles: "bordered",
        //     usecase: "pending-renewal",
        //     id: "pending-summary-share-btn",
        //     disabled: true
        // }
    ];


    statusInfo: TableStatus[] = [
        {
            id: 1,
            value: "Pending Acceptance",
            icon: "star"
        },
        {
            id: 2,
            value: "Pending Acceptance",
            icon: "release_alert",
            iconclass: "material-symbols-outlined"
        },
        {
            id: 3,
            value: "Pending Assistance",
            icon: "support_agent"
        },
        {
            id: 4,
            value: "Pending Update",
            icon: "contact_emergency"
        },
        {
            id: 5,
            value: "In Cart",
            icon: "shopping_cart"
        },
        {
            id: 6,
            value: "Read Only",
            icon: "preview"
        },
        {
            id: 7,
            value: "The Auto Pay process for this renewal will begin on",
            icon: "autopay",
            iconclass: "material-symbols-outlined"
        },
        {
            id: 8,
            value: "Requires Attention",
            icon: "add_card"
        },
        {
            id: 9,
            value: "Accepted",
            icon: "task_alt"
        },
        {
            id: 10,
            value: "Auto-pay Booked",
            icon: "published_with_changes"
        }
    ];

    pendingDetailsData: TableColumn[] = [
        {
            name: this.translate.instant('qty'),
            dataKey: 'quantity',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByQuantity")
        },
        {
            name: this.translate.instant('product'),
            dataKey: 'startDate',
            type: 'isProduct',
            position: 'left',
            isSortable: true,
            sortByField: 'startDate',
            sortDirection: 'asc',
            title: this.translate.instant("sortByProductName")
        },
        {
            name: this.translate.instant('amount'),
            dataKey: 'amount',
            position: 'left',
            isSortable: true,
            isCurrency: false,
            type: 'isCurrency',
            title: this.translate.instant("sortByAMount")
        },
        {
            name: '',
            dataKey: 'extendLink',
            type: 'extend',
            position: 'left',
            isSortable: false
        }
    ];

    AddOnPendingTableColumns: TableColumn[] = [
        {
            name: this.translate.instant("extendSupport"),
            dataKey: 'productDetails',
            type: 'isProduct',
            position: 'left',
            isSortable: false,
            title: this.translate.instant("sortByProductName")
        },
        {
            name: this.translate.instant("amount"),
            dataKey: 'amount',
            position: 'left',
            isSortable: false,
            type: 'isCurrency',
            title: this.translate.instant("sortByAMount")
        },
        {
            name: '',
            dataKey: 'isSelected',
            type: 'isSelected',
            position: 'right',
            isSortable: false,
            hideSelectAll: true
        }
    ];
    AddOnAddtionalTableColumns: TableColumn[] = [
        {
            name: this.translate.instant("primeProtectSupport"),
            dataKey: 'productDetails',
            type: 'isProduct',
            position: 'left',
            isSortable: false,
            title: this.translate.instant("sortByProductName")
        },
        {
            name: this.translate.instant("amount"),
            dataKey: 'amount',
            position: 'left',
            isSortable: false,
            type: 'isCurrency',
            title: this.translate.instant("sortByAMount")
        },
        {
            name: '',
            dataKey: 'isSelected',
            type: 'isSelected',
            position: 'right',
            isSortable: false,
            hideSelectAll: true
        },
    ];

    downloadInvoice: ButtonGroupInterface = {
        label: this.translate.instant("downloadInvoice"),
        type: "button",
        classStyles: "bordered",
        icon: "download",
        usecase: "downloadInvoice",
        disabled: true,
        id: "download-invoice"
    }

    downloadRenewalQuote: ButtonGroupInterface = {
        label: this.translate.instant("downloadRenewalQuote"),
        type: "button",
        classStyles: "bordered",
        icon: "download",
        usecase: "downloadRenewalQuote",
        disabled: true,
        id: "download-renewal-quote"
    }

    requestAssistance: ButtonGroupInterface = {
        label: this.translate.instant("requestAssistance"),
        type: "button",
        classStyles: "bordered",
        icon: "perm_identity",
        usecase: "requestAssistance",
        disabled: false,
        id: "request-assistance-btn"
    }

    submitTaxExemptionBtn: ButtonGroupInterface = {
        label: this.translate.instant("uploadTaxExemptBtn"),
        icon: "upload",
        type: "button",
        classStyles: "bordered",
        usecase: "submit-tax-exemption",
        id: "pending-summary-upload-tax-btn",
        disabled: false
    };

    pendingDetailsCheckout: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("addToCart"),
            type: "button",
            classStyles: "bordered",
            icon: "shopping_cart",
            usecase: "addToCart",
            id: "add-to-cart-btn",
        },
        {
            label: this.translate.instant("checkOut"),
            type: "button",
            classStyles: "bordered",
            icon: "arrow_forward",
            usecase: "checkoutBtn",
            id: "checkout-btn"
        }
    ];

    endUserInfo: ButtonGroupInterface = {
        label: '',
        type: "justicon",
        classStyles: "bordered",
        usecase: "endUser",
        disabled: false,
        icon: 'edit_square',
        iconClass: "material-symbols-outlined",
        id: "change-end-user-btn"
    };

    billToDetails: ButtonGroupInterface = {
        label: '',
        type: "justicon",
        classStyles: "bordered",
        usecase: "billto",
        disabled: false,
        icon: 'edit_square',
        iconClass: "material-symbols-outlined",
        id: "change-bill-addr-btn"
    };

    shipToDetails: ButtonGroupInterface = {
        label: '',
        type: "justicon",
        classStyles: "bordered",
        usecase: "shipto",
        disabled: false,
        icon: 'edit_square',
        iconClass: "material-symbols-outlined",
        id: "change-ship-addr-btn"
    };

    returnToSummary: ButtonGroupInterface = {
        label: this.translate.instant("returnToSummary"),
        type: "button",
        classStyles: "solid",
        usecase: "returnToSummary",
        id: "return-to-summary"
    };

    activityLogData: TableColumn[] = [
        {
            name: this.translate.instant('activityType'),
            dataKey: 'activityType',
            position: 'left',
            isSortable: true,
            title: this.translate.instant('sortByActivityType')
        },
        {
            name: this.translate.instant('initiator'),
            dataKey: 'initiator',
            position: 'left',
            isSortable: true,
            title: this.translate.instant('sortByInitiator')

        },
        {
            name: this.translate.instant('activity'),
            dataKey: 'activity',
            position: 'left',
            isSortable: true,
            isLink: true,
            type: 'activity-type',
            title: this.translate.instant('sortByActivity')
        },
        {
            name: this.translate.instant('date'),
            dataKey: 'dateTime',
            position: 'left',
            isSortable: true,
            type: 'log-date',
            title: this.translate.instant('sortByDate')
        }
    ];

    addOnPopupBtnGroup: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("cancel"),
            type: "button",
            classStyles: "bordered",
            usecase: "cancel",
            disabled: false,
            id: "cancel-add-on-btn"
        },
        {
            label: this.translate.instant("submit"),
            type: "button",
            classStyles: "solid",
            usecase: "submit",
            disabled: false,
            id: "save-add-on-btn"
        }
    ];
}