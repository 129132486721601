import { Component, Input } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass']
})
export class CardComponent {
  @Input() displayText: string | undefined;
  @Input() footerText = 'Learn more';
  @Input() iconPrefix: IconPrefix;
  @Input() iconName: IconName;


}
