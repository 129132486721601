import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FooterConstants } from "src/app/core/constants/footerlinks.constants";
import { HeaderFooterDataConstants } from "src/app/core/constants/header-footer.constants";
import { FooterLinkList } from "src/app/core/interface/footer.interface";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.sass"],
})
export class FooterComponent extends HeaderFooterDataConstants {
    /**
     * FooterLinkList carries the list of all footer links from const file
     */
    footerLinkList: FooterLinkList[];
    constructor(
        private footerConstants: FooterConstants,
        translate: TranslateService) {
        super(translate);
        this.footerLinkList = this.footerConstants.footerLinks;
    }
}
