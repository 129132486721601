import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.sass']
})
export class SubHeaderComponent {
  @Input() headerText: string | undefined;

}
