import {ControlContainer, ControlValueAccessor, FormControl, FormControlDirective} from '@angular/forms';
import {Component, Injector, Input, ViewChild} from '@angular/core';
@Component({
    template: ''
  })
export abstract class ControlValueAccessorConnector implements ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective | any;

  @Input()
  formControl: FormControl | any;

  @Input()
  formControlName: string | any;

  get control() {
    return this.formControl || this.controlContainer.control!.get(this.formControlName);
  }

  constructor(private injector: Injector) {
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  registerOnTouched(fn: any): void {
  }

  registerOnChange(fn: any): void {
  }

  writeValue(obj: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
