import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BaseApiService {

    constructor(private _http: HttpClient,
    ) { }

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Headers': 'Content-Type',
            // 'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Origin': '*.opentext.com'
        }),
    };

    getApiCall(url: string, options?: any) {
        return this.callGetRestApi(url, options);
    }

    callGetRestApi(url: string, options?: any) {
        return this._http.get<any>(environment.SERVER_URL + url, { params: options }).pipe(map((data: any) => {
            //handle api 200 response code here or you wanted to manipulate to response
            return data;
        }),
            catchError(this.handleError)
        );

    }

    callPostRestApi(url: string, body?: any) {
        return this._http.post(environment.SERVER_URL + url, body, this.httpOptions).pipe(map((data: any) => {
            return data;
        }),
            catchError(this.handleError)
        );
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Something bad happened; please try again later.';
        if (error.error instanceof ErrorEvent) {
            errorMessage = 'An error occurred:' + error.error.message;
            console.error(errorMessage);
        } else {
            errorMessage = `Backend returned code ${error.status}, body was: ${error.error}`;
            console.error(errorMessage);
        }
        return throwError(errorMessage);
    };


}
