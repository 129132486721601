import { Injectable } from '@angular/core';
import { HeaderFooterDataConstants } from 'src/app/core/constants/header-footer.constants';
import { NotificationBannerData } from '../interface/notification-banner.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    notificationResData: any; //Type will be defined once after api response is integrated

    notificationsList: any = [];
    showNotificationList: any = [];
    constructor(
        private headerFooterDataConstants: HeaderFooterDataConstants,
    ) {
    }
    public getNoticaionBannerInfo(result: any, displayHome: boolean,
        displayPending: boolean, displayActive: boolean, displayHistory: boolean) {
        let specificLocation;
        this.notificationsList = [];
        this.notificationResData = result.notifications.result;
        if ((displayHome || displayPending
            || displayActive || displayHistory)) {
            this.notificationResData.forEach((e: NotificationBannerData) => {
                if (e.displayLocations?.includes(",")) {
                    specificLocation = e.displayLocations.split(",");
                    specificLocation.forEach(location => {
                        if (location == this.headerFooterDataConstants.displayLocationHOME && displayHome
                            || location == this.headerFooterDataConstants.displayLocationPENDING && displayPending
                            || location == this.headerFooterDataConstants.displayLocationACTIVE && displayActive
                            || location == this.headerFooterDataConstants.displayLocationHISTORY && displayHistory) {
                            e.redirectTo ? this.withAction(e) : this.withOutAction(e);
                        }
                    });
                }
                else {
                    if (e.displayLocations == this.headerFooterDataConstants.displayLocationHOME && displayHome
                        || e.displayLocations == this.headerFooterDataConstants.displayLocationPENDING && displayPending
                        || e.displayLocations == this.headerFooterDataConstants.displayLocationACTIVE && displayActive
                        || e.displayLocations == this.headerFooterDataConstants.displayLocationHISTORY && displayHistory) {
                        e.redirectTo ? this.withAction(e) : this.withOutAction(e);
                    }
                }
            })
        }
        return this.notificationsList;
    }

    withOutAction(e: NotificationBannerData) {
        const bannerObject: any = {};
        if (e.notificationType == 'Warning') { bannerObject["icon"] = "warning"; bannerObject["type"] = "warning"; }
        else if (e.notificationType == 'Informative') { bannerObject["icon"] = "info"; bannerObject["type"] = "info"; }
        bannerObject["message"] = e.notificationText;
        bannerObject["showButton"] = true;
        bannerObject["button"] = {
            label: e.buttonText,
            type: "button",
            classStyles: "bordered",
            usecase:
                e.renewalMethodId! +
                "&$" +
                e.redirectTo! +
                "&$" +
                e.renewalCategoryId! +
                "&$" +
                e.renewalUniqueId! +
                "&$" +
                e.renewalMethod! +
                "&$" +
                e.chkUpdateAutoInvoice!,
            id: "notification-" + e.redirectTo!
        };
        return this.notificationsList.push(bannerObject);
    }
    withAction(e: NotificationBannerData) {
        const bannerObject: any = {};
        if (e.notificationType == 'Warning') { bannerObject["icon"] = "warning"; bannerObject["type"] = "warning"; }
        else if (e.notificationType == 'Informative') { bannerObject["icon"] = "info"; bannerObject["type"] = "info"; }
        bannerObject["message"] = e.notificationText;
        bannerObject["showButton"] = true;
        if (e.displayLocations == this.headerFooterDataConstants.displayLocationPENDING) {
            bannerObject["button"] = {
                label: e.buttonText,
                type: "button",
                classStyles: "bordered",
                usecase:
                    e.renewalMethodId! +
                    "&$" +
                    e.redirectTo! +
                    "&$" +
                    e.renewalCategoryId! +
                    "&$" +
                    e.renewalUniqueId! +
                    "&$" +
                    e.renewalMethod! +
                    "&$" +
                    e.chkUpdateAutoInvoice! +
                    "&$" +
                    "payment" +
                    "&$" +
                    e.paymentMethodCode! +
                    "&$" +
                    e.isPORequired! +
                    "&$" +
                    e.supportProvidedBy +
                    "&$" +
                    e.contractEntity! +
                    "&$" +
                    e.renewalMethodId! +
                    "&$" +
                    e.chkUpdateAutoInvoice!,
                id: "notification-" + e.redirectTo!
            }
        }
        else {
            bannerObject["button"] = {
                label: e.buttonText,
                type: "button",
                classStyles: "bordered",
                usecase:
                    e.renewalMethodId! +
                    "&$" +
                    e.redirectTo! +
                    "&$" +
                    e.renewalCategoryId! +
                    "&$" +
                    e.renewalUniqueId! +
                    "&$" +
                    e.renewalMethod! +
                    "&$" +
                    e.chkUpdateAutoInvoice! +
                    "&$" +
                    e.buttonId +
                    "&$" +
                    e.companyName,
                id: "notification-" + e.redirectTo!
            }
        }
        return this.notificationsList.push(bannerObject);
    }

}
