import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PendingSummaryEffect } from './store/pending-summary.effects';
import { reducerAction } from './store/pending-summary.reducer';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature('selectors', reducerAction),
        EffectsModule.forFeature([PendingSummaryEffect])
    ]
})
export class PendingSummaryModule { }