import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyService } from '../../services/currency.service';

@Pipe({
    name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private translateService: TranslateService,
        private currencyService: CurrencyService,
        public datePipe: DatePipe) { }

    transform(value: Date | string, format = 'mediumDate,', countryCode?: string, languageCode?: string, type?: string): any {
        if (value) {
            let localeDateFormat = this.currencyService.getDateFormatByCountryCode(countryCode?.toUpperCase(), languageCode?.toUpperCase()); //getLocaleByCurrency
            if (type == 'act-log') {
                localeDateFormat = localeDateFormat + " HH:mm";
            }
            return this.datePipe.transform(value, localeDateFormat)!;
        }
    }

}