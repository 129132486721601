import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.sass']
})
export class UnauthorizedComponent {
  isLoggedIn: boolean = false;

  constructor(private authService: AuthenticationService) {
    
  }

  logout() {
    this.authService.logout();    
  }

}
