export class MiddlewareUrls {

    //Account Urls
    public static getAccounts = 'account/getAccounts'
    public static getAccountAddress = 'account/getAccountAddress'
    public static updateAccountAddress = 'account/updateAccountAddress'
    public static getAccountContacts = 'account/getAccountContacts'
    public static updatePrimaryContact = 'account/updatePrimaryContact'
    public static getImpersonateContacts = "impersonation/get";
    public static getUserInfo = "userInfo";
    public static getRenewalContacts = 'account/getRenewalContacts';
    public static addRenewalContact = 'account/addRenewalContact';
    public static removeRenewalContact = 'account/removeRenewalContact';

    //Master url
    public static getMasters = "master/get"

    //Orders urls
    public static checkOutRenewal = "order/checkOutRenewal"
    public static placeOrder = "order/placeOrder"
    public static getcheckOutRenewals = "order/getcheckOutRenewals"
    public static getConfirmedRenewals = "order/confirmedRenewals"

    //Renewal urls
    public static getRenewals = 'renewal/getRenewals'
    public static getRenewalDetailByRC = 'renewal/getRenewalDetailByRC'
    public static updateUpsellAccepted = 'renewal/updateUpsellAccepted'
    public static changePaymentMethod = "renewal/changePaymentMethod"
    public static searchRenewals = "renewal/searchRenewals"
    public static getActivityLogs = 'renewal/getActivityLogs'
    public static updateCustomerReference = 'renewal/updateCustomerReference'
    public static uploadPO = 'renewal/poUpload'
    public static requestAssistance = 'renewal/requestAssistance'
    public static uploadTaxExemptCertificate = 'renewal/uploadTaxExemptCertificate'
    public static downloadQuoteAPI = 'renewal/downloadQuote'
    public static notificationAPI = 'renewal/getNotifications'
    public static getAddOns = "renewal/getAddOns"
    public static downloadInvoiceAPI = 'renewal/downloadInvoice'

    //Cart
    public static addToCart = "cart/addToCart"
    public static getCartRenewals = "cart/getCartRenewals";
    public static removeFromCart = "cart/removeFromCart";
    //Report
    public static getReport = "reporting";
}
