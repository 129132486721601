import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { filter, fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[clickOutSide]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {

  constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) { }
  documentClickedSubscription: Subscription | undefined  
  @Output() clickOutSide = new EventEmitter<void>();

  ngAfterViewInit(): void {
    this.documentClickedSubscription = fromEvent(this.document, 'click').pipe(
      filter((event) => {
        return !this.isInside(event?.target as HTMLElement);
      })
    ).subscribe(() => {
      this.clickOutSide.emit()

    })
  }

  isInside(element: HTMLElement): boolean {
    return element == this.element.nativeElement || this.element.nativeElement.contains(element);

  }

  ngOnDestroy(): void {
    this.documentClickedSubscription?.unsubscribe();
  }

}
