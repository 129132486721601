import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from 'src/app/shared/components/table/TableColumn';
import { PendingTableDataConstants } from './pending-table-data.constant';

export class DefaultTableDataConstants extends PendingTableDataConstants {

    constructor(translate: TranslateService) {
        super(translate)
    }

    defaultActiveSummaryInfo: TableColumn[] = [
        {
            name: this.translate.instant("rc"),
            dataKey: 'renewalContractNumber',
            position: 'left',
            isSortable: true,
            isLink: true,
            title: this.translate.instant("sortByRC")
        },
        {
            name: this.translate.instant("customer"),
            dataKey: 'startDate',
            position: 'left',
            isSortable: true,
            type: 'isCustomer',
            sortByField: 'startDate',
            sortDirection: 'asc',
            title: this.translate.instant("sortCustomersByTerm")
        },
        {
            name: this.translate.instant("previouslyBilledAmount"),
            dataKey: 'currencyValue',
            position: 'left',
            isSortable: true,
            type: 'isCurrency',
            title: this.translate.instant("sortByPreviouslyBilledAmount")
        },
        {
            name: this.translate.instant("status"),
            dataKey: 'portalStatus',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByStatus")
        },
        {
            name: this.translate.instant("invoiceNumber"),
            dataKey: 'invoiceNumber',
            position: 'left',
            isSortable: true,
            isLink:true,
            title: this.translate.instant("sortByInvoiceNumber")
        },
        {
            name: this.translate.instant("paymentMethod"),
            dataKey: 'paymentMethod',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByPaymentMethod")
        },
        {
            name: '',
            dataKey: 'requestAssistance',
            position: 'left',
            type: "button",
            isSortable: false
        }
    ]

    defaultHistorySummaryInfo: TableColumn[] = [
        {
            name: this.translate.instant("rc"),
            dataKey: 'renewalContractNumber',
            position: 'left',
            isSortable: true,
            isLink: true,
            title: this.translate.instant("sortByRC")
        },
        {
            name: this.translate.instant("customer"),
            dataKey: 'startDate',
            position: 'left',
            isSortable: true,
            type: 'isCustomer',
            sortByField: 'startDate',
            sortDirection: 'asc',
            title: this.translate.instant("sortCustomersByTerm")
        },
        {
            name: this.translate.instant("previouslyBilledAmount"),
            dataKey: 'currencyValue',
            position: 'left',
            isSortable: true,
            type: 'isCurrency',
            title: this.translate.instant("sortByPreviouslyBilledAmount")
        },
        {
            name: this.translate.instant("status"),
            dataKey: 'portalStatus',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByStatus")
        },
        {
            name: this.translate.instant("invoiceNumber"),
            dataKey: 'invoiceNumber',
            position: 'left',
            isSortable: true,
            isLink:true,
            title: this.translate.instant("sortByInvoiceNumber")
        },
        {
            name: this.translate.instant("paymentMethod"),
            dataKey: 'paymentMethod',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByPaymentMethod")
        },
        {
            name: this.translate.instant("acceptedBy"),
            dataKey: 'acceptedBy',
            position: 'left',
            isSortable: true,
            title: this.translate.instant("sortByWhoAcceptedTheRenewalContract")
        }
    ]

}