import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import { CommonService } from 'src/app/shared/services/common.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterConstants } from '../../constants/footerlinks.constants';
import { HeaderFooterDataConstants } from '../../constants/header-footer.constants';
import { ApiHttpService } from '../../services/httpclient.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderService } from './components/header/services/header.service';
import { LayoutRoutingModule } from './layout-routing.module';


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        ClickOutsideDirective
    ],
    imports: [
        CommonModule,
        LayoutRoutingModule,
        FontAwesomeModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ], exports: [
        HeaderComponent,
        FooterComponent
    ],
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'headers-support-dropdown' }
        },
        CommonService,
        ApiHttpService,
        TranslateService,
        FooterConstants,
        HeaderService,
        HeaderFooterDataConstants,
        NotificationService
    ]
})
export class LayoutModule { }
