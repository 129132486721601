export const pendingConstantsLbl = {
    "autoPay": "Auto-invoice",
    "annual": 'Annual',
    "pending": "Pending",
    "active": "Active",
    "history": "History",
    "pendingAcceptance": "Pending acceptance",
    "pendingUpdate": "Pending update",
    "addonTable": "addonTable",
    "additionalAddonTable": "additionalAddonTable",
    "otAgreementLink": "https://www.opentext.com/agreements",
    "otAgreementLinkText": "www.opentext.com/agreements",
    "otLogo": "../../assets/images/orc-logo.png",
    "pendingAcceptanceID": 2,
    "pendingUpdateID": 4
}
