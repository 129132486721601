export const LanguageConstants = [
    {
        id: 1,
        code: "en-US",
        label: "English"
    },
    {
        id: 2,
        code: "fr-FR",
        label: "French"
    },
    {
        id: 3,
        code: "de-GE",
        label: "German"
    },
    {
        id: 4,
        code: "ja-JP",
        label: "Japanese"
    },
    {
        id: 5,
        code: "pt-BR",
        label: "Portuguese"
    },
    {
        id: 6,
        code: "es-ES",
        label: "Spanish"
    },
];
