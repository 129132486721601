import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { AddressChange } from 'src/app/modules/orc-renewals/sub-modules/shared/components/enums/default-enums';
import { AccountDetails, UpdateChangeAddressRequest } from 'src/app/modules/orc-renewals/sub-modules/shared/interface/accounts';
import { AccountsService } from 'src/app/modules/orc-renewals/sub-modules/shared/services/accounts.service';
import { SharedComponentConstantData } from '../../constants/shared-data.constants';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Component({
    selector: 'app-change-address',
    templateUrl: './change-address.component.html',
    styleUrls: ['./change-address.component.sass']
})
export class ChangeAddressComponent extends SharedComponentConstantData {

    data: any;

    customerType = '';

    selectAddress = '';

    accountNumber = '';

    isLoading = false;

    changeAddressData: any | any[];

    addressChangeForm = this.fb.group({
        changeAddress: [''],
        availableAddress: ['']
    });

    searchObject: any[] = [];

    isDropdownVisible = false;

    private _searchObject: any[] = [];

    constructor(
        private fb: FormBuilder,
        private account: AccountsService,
        public dialogRef: MatDialogRef<any>,
        translate: TranslateService,
        private _snakBar: SnackBarService,
        private router: Router,
    ) {
        super(translate);
    }

    ngOnInit() {
        this.selectAddress = this.data?.changeAddress!;
        this.customerType = this.data?.customerType!;
        this.changeAddressData = this.data[0] || this.data.data[0];
        if (this.selectAddress) {
            this.addressChangeForm.patchValue({
                changeAddress: this.selectAddress
            })
            this.changeAddressConfig.inputDisabled = false;
            this.changeAddressRequest(this.changeAddressData, this.customerType)
        }
    }

    searchInputMethod(event: any) {
        this._searchObject = this.account.searchInputMethod(event, this._searchObject);
    }

    changeAddressRequest(list: any, customerType: string) {
        this.searchObject = [];
        this.customerType = customerType;
        this.isLoading = true;
        const param = {
            accountNumber: list.endUserCode!,
            customerType: customerType
        }
        this.account.getAccountAddress(param).subscribe(e => {
            const result = e.accountAddress.result;
            result.map((e: AccountDetails, i: number) => {
                this.searchObject.push({
                    value: e.accountNumber,
                    label: this.getlabel(e)
                });
            });
            this._searchObject = this.searchObject;
            this.isLoading = false;
        });
    }

    generateListItem(text: string) {
        return '<div>' + text + '</div>';
    }

    getlabel(e: AccountDetails) {
        // const endUserCode = e.displayName.split('-')[1];
        let list = '<div class="label-text">';
        // list += this.generateListItem(endUserCode);
        list += this.generateListItem(e.displayName ?? '');
        list += this.generateListItem(e.address1 ?? '');
        list += this.generateListItem(e.address2 ?? '');
        list += this.generateListItem(e.address3 ?? '');
        list += this.generateListItem(e.city ?? '');
        list += this.generateListItem(e.postalCode ?? '');
        list += this.generateListItem(e.country ?? '');
        list += this.generateListItem(e.regionDescription ?? '');
        list += '</div>'
        return list;
    }

    selectedOptionDropdown(value: string) {
        this.isDropdownVisible = false;
        this.addressChangeForm.controls['availableAddress'].setValue('');
        switch (value) {
            case AddressChange.endUser:
                this.changeAddressRequest(this.changeAddressData, AddressChange.endUser);
                break;
            case AddressChange.shipTo:
                this.changeAddressRequest(this.changeAddressData, AddressChange.shipTo);
                break;
            case AddressChange.billTo:
                this.changeAddressRequest(this.changeAddressData, AddressChange.billTo);
                break;
            default:
                this.isDropdownVisible = false;
                break;
        }
        setTimeout(() => {
            this.addressChangeForm.controls['availableAddress'].setValue('');
            this.isDropdownVisible = true;
        });
    }

    openDropdown(e: any) {
        if (e === '') return this.searchObject = this._searchObject;
        else return this.searchObject;
    }

    selectedDropdown(e: any) {
        this.accountNumber = e?.value;
    }

    updateAddress() {
        this.isLoading = true;
        const param: UpdateChangeAddressRequest = {
            renewalUniqueID: [this.changeAddressData.assureRenewalUniqueID],
            accountNumber: this.accountNumber,
            customerType: this.customerType
        }
        this.account.updateAccountAddress(param).subscribe(e => {
            if (e.updateAccountAddress.isSuccess) {
                if (e.updateAccountAddress.statusCode == 200) {
                    this._snakBar.showSnackBar(e.updateAccountAddress.statusCode, true);
                    this.dialogRef.close(e.updateAccountAddress);
                    setTimeout(() => {
                        // this.router.navigateByUrl(RoutingUrlConstants.ORC_PENDING_SUMMARY);
                        location.href = RoutingUrlConstants.ORC_PENDING_SUMMARY;
                    })
                }
                else {
                    this._snakBar.showSnackBar(e.updateAccountAddress.statusCode, true);
                }
            }

            this.isLoading = false;
        });
    }

    changeAddress(e: any) {
        if (e == 'submit') {
            this.updateAddress();
        }
        else {
            this.dialogRef.close(e);
        }
    }
}
