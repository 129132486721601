import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { authModuleConfig } from '../constants/auth-module-config.constants';
import { otAuthConfig } from '../constants/oidc.constants';
import { AuthGuard } from '../guards/auth.guard';
import { AuthGuardWithForcedLogin } from '../services/auth-guard-with-forced-login.service';
import { AuthInterceptorService } from '../services/auth-interceptor.service';
import { AuthenticationService } from '../services/auth.service';

export function authAppInitializerFactory(authService: AuthenticationService) {
    return () => authService.runInitialLoginSequence();
}

@NgModule({
    imports: [
        HttpClientModule,
        OAuthModule.forRoot()
    ],
    providers: [
        AuthenticationService,
        AuthGuard,
        AuthGuardWithForcedLogin
    ]
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [

                { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthenticationService], multi: true },
                { provide: AuthConfig, useValue: otAuthConfig },
                { provide: OAuthModuleConfig, useValue: authModuleConfig },
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
