import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HeaderFooterDataConstants } from 'src/app/core/constants/header-footer.constants';
import { CartData, GetCartRenewals, PendingSummary } from 'src/app/modules/orc-renewals/sub-modules/pending/interface/pending';
import { RenewalsService } from 'src/app/modules/orc-renewals/sub-modules/shared/services/renewals.service';
import { ComponentModel } from 'src/app/shared/interface/popup.interface';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PopupDataComponent } from '../../orc-renewals/sub-modules/pending/components/popup/popup.component';
import { OrdersPageDefaultData } from '../../orders/sub-modules/orders-page-default-data.constants';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    cartDetails!: GetCartRenewals;
    constructor(
        private renewalService: RenewalsService,
        private popupService: PopupService,
        private headerFooter: HeaderFooterDataConstants,
        private orcPageConst: OrdersPageDefaultData,

    ) {
    }

    getCartRenewals() {
        return this.renewalService.getCartRenewals().pipe(
            map((data: { getCartRenewals: CartData; }) => {
                this.cartDetails = data;
                localStorage.setItem('cartCount', String(this.cartDetails.getCartRenewals.count));
                this.headerFooter.postLoginBtnCnfg[0].badget = this.cartDetails.getCartRenewals.count;

                return this.cartDetails;
            }
            ))
    }
    showInformationModel(popupData: any) {
        const dailogData: ComponentModel = {
            componentName: PopupDataComponent,
            data: popupData
        };
        this.popupService.dialogWidth = 'medium';
        this.popupService.openPopup(dailogData);
        this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
        });

    }
    validateCartData(cartData?: any, selectedData?: PendingSummary[], pageType?: string) {
        let checkType: boolean = true;
        if (cartData && cartData.length > 0 && (pageType == 'summary' || pageType == 'detail')) {
            cartData.forEach((obj: { paymentMethodCode: number | undefined; currency: string | undefined; supportProvidedBy: any; }) => {
                let samePaymentCode = selectedData!.every(({ paymentMethodCode }) => obj.paymentMethodCode === paymentMethodCode);
                let sameCurrency = selectedData!.every(({ currency }) => obj.currency === currency);
                let samecontractEntity = selectedData!.every(({ supportProvidedBy }) => obj.supportProvidedBy === supportProvidedBy);
                if (cartData.length == 1 && obj.paymentMethodCode == this.orcPageConst.paymentMethods[2].id) {
                    this.showInformationModel('ERROR CART INFO');
                    checkType = false;
                    throw 'break';
                }
                else if (samePaymentCode && sameCurrency && samecontractEntity) {
                    checkType = true;
                }
                else {
                    this.showInformationModel('ERROR CART INFO');
                    checkType = false;
                    throw 'break';
                }
            });
        }
        else if (selectedData!.length > 0 && (pageType == 'summary' || pageType == 'cart')) {
            const samePaymentList = this.samePaymentMethod(selectedData!);
            const sameCurrency = this.isSameCurrency(selectedData!);
            const multiCreditCard = this.isCreditCard(selectedData!);
            const sameContractingEntity = this.sameContractingEntity(selectedData!);
            if ((samePaymentList && sameCurrency && sameContractingEntity) && !multiCreditCard) {
                checkType = true;
            }
            else {
                this.showInformationModel('ERROR CART INFO');
                checkType = false;
                throw 'break';
            }
        }
        return checkType;
    }
    samePaymentMethod(list: any[]) {
        const sameList = list.map(e => e.paymentMethodCode!);
        return sameList.every((e: string) => e === sameList[0]);
    }
    isSameCurrency(list: any[]) {
        const currency = list.map(e => e.currency!);
        return currency.every((e: string) => e === currency[0]);
    }
    sameContractingEntity(list: any[]) {
        const supportProvidedBy = list.map(e => e.supportProvidedBy!);
        return supportProvidedBy.every((e: string) => e === supportProvidedBy[0]);
    }
    isCreditCard(list: any[], type?: string) {
        const payment = list.map(e => e.paymentMethodCode!);
        return list.length > 1 && payment.every((e: number) => e === this.orcPageConst.paymentMethods[2].id);
    }

    singleCreditCardInCart(cartData: CartData) {
        let creditCardData = false;
        if (cartData && cartData.result.length > 0) {
            cartData.result.forEach((obj) => {
                if (cartData.result.length == 1 && obj.paymentMethodCode == this.orcPageConst.paymentMethods[2].id) {
                    creditCardData = true;
                }
                else {
                    creditCardData = false;
                }
            });
        }
        return creditCardData;
    }
}
