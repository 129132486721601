import { Injectable } from '@angular/core';
import { ApiHttpService } from './../../core/services/httpclient.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    schemaData: any;
    constructor(private ApiHttpService: ApiHttpService) {
    }

    /**
     * for encrypt data
     *
     * @param data
     * @returns
     */
    encryptData(data: any) {
        return data ? btoa(data) : '';
    }
    /**
     * for decrypt data
     *
     * @param data
     * @returns
     */
    decryptData(data: any) {
        return data ? atob(data) : '';
    }

    getAPI(url: string) {
        return this.ApiHttpService.get(url)
    }

    public isEmployee() {
        const userObj = sessionStorage.getItem("id_token_claims_obj");
        if (userObj) {
            const userObject: any = JSON.parse(userObj!);
            return userObject.userType === 'employee' ? true : false;
        } else {
            return;
        }
    }

    public isImpersonator() {
        const canImpersonate = sessionStorage.getItem(this.encryptData('canImpersonate'));
        if (canImpersonate == null) {
            return null;
        } else {
            return canImpersonate === this.encryptData('false') ? false : true;
        }
    }

    public isImpersonating() {
        return sessionStorage.getItem('impersonatedUser') == null ? false : true;
    }

    public getImpersonatedUserData() {
        return JSON.parse(sessionStorage.getItem('impersonatedUser') || '{}');
    }
}