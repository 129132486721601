import { result } from "src/app/shared/interface/master.interface";

export const userFeatureKey = 'user';

export const masterFeatureKey = 'master';

export interface UserState {
    isLoggedIn: boolean
    userProfile: UserProfile | null

};

export interface UserProfile {
    id: string;
    creation: number;
    realm: string;
    country: string;
    email: string;
    family_name: string;
    given_name: string;
    language: string;
    name: string;
    orgID: string;
    preferred_username: string;
    remote_id: string;
    sub: string;
    timeZone: string;
    updated_at: number;
    userType: string;
}


export const initialState: UserState = {
    isLoggedIn: false,
    userProfile: null
};

export interface MasterState {
    masters: Master | null;
}

export interface Master {
    isSuccess: boolean;
    result: result;
    displayMessage: string;
    errorMessages: string;
}

export const initialMasterState: MasterState = {
    masters: null
};