import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {
    getLocaleByCurrency(countryCode: string, languageCode: string) {
        switch (countryCode) {
            case "US":
                return 'en-US';
            case 'AU':
                return "en-AU";
            case 'BE':
                return "nl-BE";
            case "CA":
                return 'en-CA';
            case "CH":
                return languageCode == "FR" ? "it-CH" :
                    languageCode == "DE" ? "de-CH" : "it-CH";
            case "CZ":
                return 'cs-CZ';
            case "DE":
                return 'de-DE';
            case "DK":
                return 'da-DK';
            case "ES":
                return 'es-ES';
            case "FI":
                return 'fi-FI';
            case "FR":
                return 'fr-FR';
            case "GB":
                return "en-GB";
            case "IT":
                return "it-IT";
            case "JP":
                return 'ja-JP';
            case "NL":
                return 'nl-NL';
            case "NO":
                return 'nn-NO';
            case "SG":
                return 'zh-SG';
            case "RU":
                return 'ru-RU';
            case "SE":
                return 'sv-SE';
            default:
                return 'en-US';
        }
    }
    getDateFormatByCountryCode(countryCode: any, languageCode: any) {
        switch (countryCode) {
            case "US":
                return 'MM/dd/yy';
            case 'AU':
                return "dd/MM/yyyy";
            case 'BE':
                return "dd/MM/yy";
            case "CA":
                return 'yyyy-MM-dd';
            case "CH":
                return languageCode == "FR" ? "dd.MM.yy" :
                    languageCode == "DE" ? "dd.MM.yy" : "dd.MM.yy";
            case "CZ":
                return 'dd.MM.yy';
            case "DE":
                return 'dd.MM.yy';
            case "DK":
                return 'dd/MM/yy';
            case "ES":
                return 'dd/MM/yy';
            case "FI":
                return 'dd.MM.yyyy';
            case "FR":
                return 'dd/MM/yyyy';
            case "GB":
                return "dd/MM/yyyy";
            case "IT":
                return "dd/MM/yy";
            case "JP":
                return 'yyyy/MM/dd';
            case "NL":
                return 'dd-MM-yy';
            case "NO":
                return 'MM/dd/yyyy';
            case "SG":
                return 'dd/MM/yy';
            case "RU":
                return 'MM/dd/yyyy';
            case "SE":
                return 'yyyy-MM-dd';
            default:
                return 'MM/dd/yyyy';
        }
    }

}