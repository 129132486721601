<div class="request-assistance-wrapper">
    <div class="change-address-header">
        <h3 class="h4 heading-regular mb-4 mb-md-6 mb-lg-8">{{'requestAssistance' | translate}}</h3>
        <p>{{'requestAssistanceInstruction' | translate}}</p>
    </div>

    <div class="confirm-place-order-form-fields">

        <form [formGroup]="requestAssistanceForm"
              id="uploadPOForm"
              class="confirm-place-order-form">
            <div *ngIf="showErrorMessage.length > 0">
                <p class="invalidText mb-1 fw-bold">{{'requestAssistanceErrorIntro' | translate}}: </p>
                <ul>
                    <li class="invalidText mb-1"
                        *ngFor="let error of showErrorMessage">{{error}}</li>
                </ul>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <app-form-fields [formControlName]="'firstName'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.firstNameConfig_Field"></app-form-fields>
                </div>
                <div class="col">
                    <app-form-fields [formControlName]="'lastName'"
                                     class="app-form-fields ge"
                                     [fieldConfig]="fieldObj.lastNameConfig_Field"></app-form-fields>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12"
                     *ngIf="data.type != 'remove-scheduling-assistance'">
                    <app-form-fields [formControlName]="'companyName'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.companytNameConfig_Field"></app-form-fields>
                </div>
                <div class="col">
                    <app-form-fields [formControlName]="'email'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.emailConfig_Field"></app-form-fields>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12"
                     *ngIf="type !== fieldObj.requestAssistance && type !== fieldObj.uploadPO && type !== fieldObj.submitTaxExemption && type !== fieldObj.removeScheduledBill">
                    <app-form-fields [formControlName]="'renewalContract'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.renewalContractConfig_Field"></app-form-fields>
                </div>
                <div class="col-md-12 col-lg-12"
                     *ngIf="type === fieldObj.uploadPO">
                    <app-form-fields [formControlName]="'uploadPO'"
                                     class="app-form-fields"
                                     (change)="changeSubject($event)"
                                     [fieldConfig]="fieldObj.poNumberConfig_Field"></app-form-fields>
                </div>
                <div class="col"
                     *ngIf="type === fieldObj.uploadPO">
                    <app-form-fields [formControlName]="'poAmount'"
                                     class="app-form-fields"
                                     (amountValue)="formatAmountValue($event)"
                                     [fieldConfig]="fieldObj.poAmountConfig_Field"
                                     [cntCode]="inputCountryCode"
                                     [lngCode]="inputLanguageCode"></app-form-fields>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12"
                     *ngIf="type === fieldObj.uploadPO">
                    <app-form-fields [formControlName]="'poCurrency'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.poCurrencyConfig_Field"></app-form-fields>
                </div>
                <div class="col"
                     *ngIf="type !== fieldObj.uploadPO && type !== fieldObj.submitTaxExemption && type !== fieldObj.removeScheduledBill">
                    <app-form-fields [formControlName]="'reasonPick'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.reasonConfig_Field"></app-form-fields>

                </div>
                <div class="col"
                     *ngIf="type === fieldObj.uploadPO || type === fieldObj.submitTaxExemption || type === fieldObj.removeScheduledBill">
                    <app-form-fields [formControlName]="'uploadReasonPick'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.uploadReasonConfig_Field"></app-form-fields>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <app-form-fields [formControlName]="'subject'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.subjectConfig_Field"></app-form-fields>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <app-form-fields [formControlName]="'description'"
                                     class="app-form-fields"
                                     [fieldConfig]="fieldObj.descriptionConfig_Field"></app-form-fields>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <app-form-fields class="app-form-fields"
                                     (fileObject)="getSelectedFile($event)"
                                     [fieldConfig]="fieldObj.uploadConfig_Field"></app-form-fields>
                </div>
            </div>

        </form>

        <div class="row mt-3">
            <div class="col-md-12 col-lg-12">
                <div *ngFor="let btn of fieldObj.requestAssistancePopupBtnGroup;"
                     class="button-container">
                    <app-button-group class="app-form-buttons"
                                      (emitClickEvent)="onButtonClick($event)"
                                      [buttonConfig]="btn"></app-button-group>
                </div>
            </div>
        </div>

    </div>
</div>

<div *ngIf="isLoading">
    <app-spinner-overlay></app-spinner-overlay>
</div>