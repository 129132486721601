import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { snackBarConfig } from "../../constants/snack-bar.constants";
import { SnackbarComponent } from "./snack-bar.component";

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {

    interceptErrorMessage: string = this.translate?.instant("commonErrorMessage");
    constructor(private snackBar: MatSnackBar,
        private translate: TranslateService
    ) { }

    showSnackBar(statusCode: any, type?: boolean) {
        const message = this.getMessage(statusCode)
        this.snackBar.openFromComponent(SnackbarComponent, {
            data: message,
            duration: snackBarConfig.duration,
            verticalPosition: snackBarConfig.verticalPosition as MatSnackBarVerticalPosition,
            horizontalPosition: snackBarConfig.horizontalPosition as MatSnackBarHorizontalPosition,
            panelClass: statusCode == 200 ? 'success-snack-bar' : 'error-snack-bar'
        });
        //When snackbar and other pop-ups popped up simultaneoulsy, removing class to avoid disabling the other functionality.
        type ? document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock') : '';
    }
    getMessage(code: number) {
        return code == 200 ? this.translate?.instant("apiSuccessMessage") : this.translate?.instant("commonErrorMessage");
    }
}
