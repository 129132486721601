import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupComponent } from '../components/popup/popup.component';
import { ComponentModel } from '../interface/popup.interface';

@Injectable({
    providedIn: 'root'
})
export class PopupService {
    dialogWidth: any;
    /**
     * DialogRef variable for popup (Mat dialog)
     */
    dialogRef!: MatDialogRef<any>;
    constructor(private dialog: MatDialog) { }

    /**
     * This method expects a data parameter and opens a mat popup dialog box.
     */
    openPopup(data: ComponentModel) {

        this.dialogRef = this.dialog.open(PopupComponent, {
            data: data,
            disableClose: true,
            width:
                this.dialogWidth === 'x-small'
                    ? '300px'
                    : this.dialogWidth === 'small'
                        ? '640px'
                        : this.dialogWidth === 'medium'
                            ? '768px'
                            : this.dialogWidth === 'large'
                                ? '1200px'
                                : this.dialogWidth === 'e-large'
                                    ? '90%'
                                    : '500px',
        });
    }
    closePopup() {
        this.dialogRef && this.dialogRef.close();
    }
}
