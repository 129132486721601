import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MasterState, UserState, masterFeatureKey, userFeatureKey } from "./user.state";

const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

const selectMasterState = createFeatureSelector<MasterState>(masterFeatureKey);

const selectUserEmail = createSelector(
    selectUserState,
    (userState) => userState.userProfile?.preferred_username
);

const selectLanguage = createSelector(
    selectUserState,
    (userState) => userState.userProfile?.language
);
const selectName = createSelector(
    selectUserState,
    (userState) => userState.userProfile?.name
);

const selectIsLoggedIn = createSelector(
    selectUserState,
    (userState) => userState.isLoggedIn
);

const selectMasterDetail = createSelector(
    selectMasterState,
    (masterState) => masterState.masters
);

export const UserSelectors = {
    email: selectUserEmail,
    language: selectLanguage,
    userName: selectName,
    isLoggedIn: selectIsLoggedIn
};
export const UserInfo = createSelector(
    selectUserState,
    (userState) => userState.userProfile
);

export const masterSelector = {
    master: selectMasterDetail
}
