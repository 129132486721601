import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "custom-error",
    templateUrl: "./custom-error.component.html",
    styleUrls: ["./custom-error.component.sass"],
    encapsulation: ViewEncapsulation.None
})
export class CustomErrorComponent {
    constructor(
        private translate: TranslateService,
    ) { }
}
