import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { CurrencyService } from '../services/currency.service';

@Directive({
    selector: '[currencyFormat]'
})
export class CurrencyFormatDirective implements OnInit {
    currencyChars = new RegExp('[\.,]', 'g');
    @Input('currencyFormat') currencyFormat: any;
    constructor(public el: ElementRef,
        public renderer: Renderer2,
        private decimalPipe: DecimalPipe,
        private currencyService: CurrencyService) { }

    ngOnInit() {
        this.format(this.el.nativeElement.value); // format any initial values
    }

    @HostListener('change', ["$event.target.value"]) onChange(e: string) {
        this.format(e);
    };

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        this.format(event.clipboardData!.getData('text/plain'));
    }

    format(val: string) {
        // 1. test for non-number characters and replace/remove them
        const numberFormat = parseInt(String(val).replace(this.currencyChars, ''));
        // console.log(numberFormat); // raw number
        let locale = this.currencyService.getLocaleByCurrency(this.currencyFormat.countryCode, this.currencyFormat.languageCode);
        //console.log(val, this.currencyFormat.countryCode, this.currencyFormat.languageCode, locale);
        // 2. format the number (add commas)
        const usd = this.decimalPipe.transform(numberFormat, '1.0', locale);

        // 3. replace the input value with formatted numbers
        this.renderer.setProperty(this.el.nativeElement, 'value', usd);
    }

}

