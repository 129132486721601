import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingUrlConstants } from 'src/app/core/constants/routing-url.constants';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-logout-callback',
    template: ''
})
export class SignOutRedirectCallbackComponent implements OnInit {
    constructor(private readonly _authService: AuthenticationService, 
                private readonly _router: Router) {}
    
    ngOnInit() {        
         this._authService.logout().then(_ => {
             this._router.navigate([RoutingUrlConstants.ORC_LANDING], {replaceUrl: true});
         });       
    }
}