<div class="notification-banner-main-wrapper"
     [class]="bannerInfo.type == 'danger' ? 'danger-bg' : bannerInfo.type == 'success' ? 'success-bg' :bannerInfo.type == 'warning' ? 'warning-bg' : 'info-bg'"
     *ngIf="bannerInfo && showBanner">
    <div [class]="bannerInfo.showButton ? 'notification-banner-main-section': ''">
        <mat-icon class="material-symbols-outlined notification-banner-info-icon"
                  *ngIf="bannerInfo.icon"
                  [class]="bannerInfo.type == 'danger' ? 'danger-icon' : bannerInfo.type == 'success' ? 'success-icon' :bannerInfo.type == 'warning' ? 'warning-icon' : 'info-icon'">
            {{bannerInfo.icon}}</mat-icon>
        <p [class]="bannerInfo.showButton ? 'notification-banner-message': ''">{{bannerInfo.message | translate}}</p>
        <app-button-group class="notification-banner-close-icon"
                          id="close-btn"
                          *ngIf="bannerInfo.showButton"
                          (emitClickEvent)="onNotificationButtonCloseClick($event)"
                          [buttonConfig]="closeButtonConfig"></app-button-group>
        <app-button-group class="notification-banner-view-btn"
                          id="notification-btn"
                          *ngIf="bannerInfo.showButton"
                          (emitClickEvent)="onNotificationButtonClick($event)"
                          [buttonConfig]="bannerInfo.button!"></app-button-group>
    </div>
    <br style="clear: both;" *ngIf="bannerInfo.usecase!=='scheduleBanner'"/>
</div>