<ng-container *ngIf="this.loader.getLoading()">
    <div class="spinner-overlay">
        <div class="spinner-overlay-center">
            <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
        </div>
    </div>
</ng-container>
<ng-template #progressSpinner>
    <mat-progress-spinner [diameter]="diameter"
                          [mode]="mode"
                          [color]="color"
                          [strokeWidth]="strokeWidth"
                          [value]="value">
    </mat-progress-spinner>
</ng-template>