import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { CommonService } from "src/app/shared/services/common.service";
import { MasterService } from "src/app/shared/services/master.service";
import { AuthenticationService } from "../../services/auth.service";
import * as UserActions from './user.actions';
import { UserProfile } from "./user.state";

@Injectable()
export class UserEffects {
    isLoggedIn = false;
    constructor(
        private actions$: Actions,
        private commonService: CommonService,
        private master: MasterService,
        private authService: AuthenticationService
    ) { }

    // Define the side effect for updating the user profile from session storage
    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.UpdateUserProfile),
            startWith(UserActions.UpdateUserProfile()),
            mergeMap(() => {
                const userProfile: UserProfile = JSON.parse(sessionStorage.getItem('id_token_claims_obj') || '{}');
                return of(UserActions.UpdateUserProfileSuccess({ userProfile }));
            })
        )
    );

    masterData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.masterGet),
            startWith(UserActions.masterGet()),
            mergeMap(() => {
                this.authService.isAuthenticated$.subscribe((loggedIn) => {
                    this.isLoggedIn = loggedIn;
                });
                if (!this.isLoggedIn) return EMPTY;
                return this.master.getMasters().pipe(map((data) => {
                    return UserActions.masterGetSuccess({ masters: data.masters })
                }));
            })
        )
    );
}