import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const otAuthConfig: AuthConfig = {
    issuer: environment.OT_IAM_ISSUER_URL,
    loginUrl: environment.OT_IAM_API_URL + 'oauth/v3/authorization',
    tokenEndpoint: environment.OT_IAM_API_URL + 'oauth/v3/token',
    userinfoEndpoint: environment.OT_IAM_API_URL + 'person/v3/userInfo',
    postLogoutRedirectUri: window.location.origin + '/signout-callback',
    logoutUrl: environment.OT_IAM_LOGOUT_URL,
    redirectUriAsPostLogoutRedirectUriFallback: false,
    preserveRequestedRoute: false,
    revocationEndpoint: environment.OT_IAM_API_URL + 'oauth/v3/revoke',
    clientId:  environment.OT_IAM_CLIENT_ID,
    responseType: 'code',
    redirectUri: window.location.origin + '/signin-callback',
    //silentRefreshRedirectUri: window.location.origin + '/assets/silent-refresh.html',
    scope: environment.OT_IAM_SCOPE, // Ask offline_access to support refresh token refreshes
    //useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
    //silentRefreshTimeout: 5000, // For faster testing
    timeoutFactor: 0.95, // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false,
    oidc: true,
    useIdTokenHintForSilentRefresh: true,
    strictDiscoveryDocumentValidation: false

}

