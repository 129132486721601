import { Action, createReducer, on } from "@ngrx/store";
import * as UserActions from "./user.actions";
import { MasterState, UserState, initialMasterState, initialState } from "./user.state";



const userProfileReducer = createReducer(initialState,
    on(UserActions.UpdateUserProfile, (state) => initialState),
    on(UserActions.UpdateUserProfileSuccess, (state, result) => {
        return {
            ...state,
            isLoggedIn: Object.keys(result.userProfile).length === 0 ? false : true,
            userProfile: result.userProfile
        }
    }),
);

const masterReducer = createReducer(initialMasterState,
    on(UserActions.masterGet, (state) => initialMasterState),
    on(UserActions.masterGetSuccess, (state, res) => {
        return {
            ...state,
            masters: res.masters
        }
    }),
);



export function reducer(state: UserState | undefined, action: Action) {
    return userProfileReducer(state, action);
}

export function MasterReducer(state: MasterState | undefined, action: Action) {
    return masterReducer(state, action);
}