<ul *ngIf="(breadCrumbList && breadCrumbList.length >= 2 && !pageNotFound)"
    class="breadcrumb-component-ul">
    <ng-container *ngFor="let bread of breadCrumbList; let i = index; let lastItem = last;">
        <li *ngIf="!lastItem">
            <a [href]="bread.route">
                <span *ngIf="bread.label == homeLabel">
                    <mat-icon class="material-symbols-outlined icon-custom-cls-lng">
                        Home</mat-icon>
                </span>
                <span *ngIf="bread.label != homeLabel">
                    <span class="crumb-label">/</span> {{bread.label | translate}}
                </span>
            </a>
        </li>
    </ng-container>
    <li><span>
            <span class="stic-lbl">/</span> {{lastItem.label | translate}}
        </span>
    </li>
</ul>