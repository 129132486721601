import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddressChange } from 'src/app/modules/orc-renewals/sub-modules/shared/components/enums/default-enums';
import { NotificationBannerInterface } from 'src/app/shared/interface/notification-banner.interface';
import { ButtonGroupInterface } from '../interface/button-group.interface';
import { FormFieldsInterface } from '../interface/form-fields.interface';


@Component({
    template: ''
})

export class SharedComponentConstantData {

    extnSpprtRecmend = 'https://www.opentext.com/assets/documents/en-US/pdf/opentext-extended-support-factsheet-en.pdf';
    learnMorePrime = 'https://www.opentext.com/assets/documents/en-US/opentext-factsheet-gcs-programs-prime-protect-en.pdf';
    learnMoreTechConnect = "https://www.opentext.com/assets/documents/en-US/opentext-factsheet-tech-connect-en.pdf";
    learnMoreExtend = 'https://www.opentext.com/assets/documents/en-US/pdf/opentext-extended-support-factsheet-en.pdf';
    fileExtension = ".pdf";
    contentType = "'application/pdf";
    customerReferenceConfig: FormFieldsInterface = {
        label: "",
        cssClass: "customerReference",
        placeHolder: this.translate.instant("enterCustomerReference"),
        type: "textbox",
        maxLength: "50"
    };

    changeAddressConfig: FormFieldsInterface = {
        label: "",
        cssClass: "change-address",
        placeHolder: this.translate.instant("pleaseSelect"),
        type: "select",
        options: [
            {
                label: this.translate.instant("changeEndUserAddress"),
                value: AddressChange.endUser
            },
            {
                label: this.translate.instant("changeShipToAddress"),
                value: AddressChange.shipTo
            },
            {

                label: this.translate.instant("changeBillToAddress"),
                value: AddressChange.billTo
            }
        ]
    }

    avilableAddressConfig: FormFieldsInterface = {
        label: "",
        cssClass: "available-address",
        placeHolder: this.translate.instant("pleaseSelect"),
        type: "selectFilter"
    }

    customerRefPopupBtnGroup: ButtonGroupInterface[] = [
        {
            label: this.translate.instant("cancel"),
            type: "button",
            classStyles: "bordered",
            usecase: "cancel",
            disabled: false,
            id: "cancel-customer-ref-btn"
        },
        {
            label: this.translate.instant("submit"),
            type: "button",
            classStyles: "solid",
            usecase: "submit",
            disabled: false,
            id: "save-customer-ref-btn"
        }
    ];

    bannerInformation: NotificationBannerInterface = {
        icon: "info",
        type: "info",
        message: this.translate.instant("sampleBanner"),
        showButton: true,
        button: {
            label: this.translate.instant("viewDetails"),
            type: "button",
            classStyles: "bordered",
            usecase: "notification-details",
            id: "notification-view-details-btn",
        },
    };
    closeBtnConfig: ButtonGroupInterface = {
        type: "justicon",
        classStyles: "bordered",
        icon: "close",
        usecase: "close-banner",
        id: "notification-close-btn",
    };
    buttonItem_eligibleAutoPay: ButtonGroupInterface = {
        label: this.translate.instant("eligibleAutoPay"),
        type: "button",
        classStyles: "bordered",
        usecase: "pending-renewal",
        disabled: false,
        id: "download-renewal-quote"
    }
    buttonItem_eligibleExtendedSupport: ButtonGroupInterface = {
        label: this.translate.instant("eligibleExtendedSupport"),
        type: "button",
        classStyles: "bordered",
        usecase: "pending-renewal",
        disabled: false,
        id: "download-renewal-quote"
    }
    buttonItem_eligibleForAdditionalSupport: ButtonGroupInterface = {
        label: this.translate.instant("eligibleForAddtionalSupport"),
        type: "button",
        classStyles: "bordered",
        usecase: "pending-renewal",
        disabled: false,
        id: "additonal-renewal-quote"
    }

    buttonItem_purchaseExtendedSupport: ButtonGroupInterface = {
        label: this.translate.instant("purchaseExtendedSupport"),
        type: "button",
        classStyles: "bordered",
        usecase: "pending-renewal",
        disabled: false,
        id: "extended-renewal-quote"
    }

    buttonItem_delete: ButtonGroupInterface = {
        label: this.translate.instant("removeFromCart"),
        type: "button",
        classStyles: "bordered",
        usecase: "delete",
        disabled: false,
        icon: "delete_outline",
        id: "delete-btn"
    }

    buttonItem_inc: ButtonGroupInterface = {
        label: '',
        type: "button",
        classStyles: "solid invoice-btn",
        usecase: "download",
        disabled: false,
        icon: "download_invoice",
        id: "invoice-btn"
    }

    buttonItem_requestAssistance: ButtonGroupInterface = {
        type: "justicon",
        classStyles: "bordered",
        icon: "person",
        iconClass: "material-symbols-outlined",
        usecase: "request-assistance",
        disabled: false,
        id: "request-assistance"
    }

    constructor(public translate: TranslateService) { }

}
