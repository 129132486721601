<div class="container container-fluid" 
    *ngIf="!download_report">
        <div class="sub-header pt-2 mb-2">
            <div class="close-icon">
                <span><mat-icon class="icon-change">highlight_off</mat-icon></span>
                <span *ngIf="report" 
                    class="ml-3">{{'errorReport' | translate}}</span>
                <span *ngIf="method" 
                    class="ml-3">{{'errorRenewalMethod' | translate}}</span>
                <span *ngIf="maxRowsErr" 
                    class="ml-3">{{'maxRowsError' | translate}}</span>
                <span *ngIf="confirm" 
                    class="ml-3">{{'paymentTermStartDateWindow' | translate}}</span>
                <span *ngIf="outsideScheduleDate" 
                    class="ml-3">{{'OutsideDateWindow' | translate}}</span>
                <span *ngIf="expired" 
                    class="ml-3">{{'expiredDateWindow' | translate}}</span>
            </div>
            <span class="close"> 
                <app-button-group [buttonConfig]="buttonConfig"
                                class="btn-focus"
                                [isReportClick]="true"
                                (emitClickEvent)="onButtonClick()">
                </app-button-group>
            </span>
        </div>
        <p class="sub-text"
            *ngIf="report">{{'errorReportDesc' | translate}}</p>
        <p class="sub-text"
            *ngIf="method">{{'errorRenewalMethodDesc' | translate}}</p>
        <div *ngIf="maxRowsErr">
            <p class="sub-text mb-1" 
                *ngIf="data == 'summary'">{{'summaryReportCreationErrorDesc' | translate}}</p>
            <p class="sub-text mb-1" 
                *ngIf="data == 'detail'">{{'detailReportCreationErrorDesc' | translate}}</p>
            <p class="sub-text mb-1">{{'reportCreationErrorDesc' | translate}}</p>
        </div>
        <div *ngIf="confirm">
            <p class="sub-text mb-1">{{'paymentTermStartDateMismatched' | translate}}</p>
            <p class="sub-text mb-1">{{'scheduleLearnMoreText' | translate}}</p>
            <a class="arrow-more more-bold sub-text mb-3" (click)="redirectTo('pay')">{{'viewTutorials' | translate}}
                <mat-icon class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
        <div *ngIf="outsideScheduleDate">
            <p class="sub-text mb-1" *ngIf="data.rcId.length == 1; else multipleOutsideDate">
                {{data.rcId[0]}} {{'scheduleBillOutsideDateSingleRc' | translate}}</p>
            <p class="sub-text mb-1"><ng-template #multipleOutsideDate><p class="sub-text mb-1">{{'scheduleBillOutsideDateMultipleRc' | translate}} </p></ng-template></p>
            <p class="sub-text mb-1">{{'scheduleLearnMoreText' | translate}}</p>
            <a class="arrow-more more-bold sub-text mb-3" (click)="redirectTo('pay')">{{'viewTutorials' | translate}}
                <mat-icon class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
        <div *ngIf="expired">
            <p class="sub-text mb-1" *ngIf="data.rcId.length == 1; else multipleExpired">
                {{data.rcId[0]}} {{'scheduleBillExpiredDateSingleRc' | translate}}</p>
            <p class="sub-text mb-1"><ng-template #multipleExpired><p class="sub-text mb-1">{{'scheduleBillExpiredDateMultipleRc' | translate}} </p></ng-template></p>
            <p class="sub-text mb-1">{{'scheduleLearnMoreText' | translate}}</p>
            <a class="arrow-more more-bold sub-text mb-3" (click)="redirectTo('pay')">{{'viewTutorials' | translate}}
                <mat-icon class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
        <div *ngIf="passedScheduleDate" class="CCpayment-schedule-error">
            <p class="sub-text mb-1 CCpayment-schedule-error-heading">{{'scheduledOrderCCErrorTitle' | translate}}</p>
            <p class="sub-text mb-1 CCpayment-schedule-error-desc">{{data.rcId[0]}}&nbsp;{{'scheduledOrderCCErrorDesc' | translate}}</p>
            <p class="sub-text mb-1">{{'scheduledOrderCCErrorLinkText' | translate}}</p>
            <a class="arrow-more more-bold sub-text mb-3" (click)="redirectTo('pay')">{{'viewTutorials' | translate}}
                <mat-icon class="material-symbols-outlined arrow-right">arrow_right_alt</mat-icon></a>
        </div>
</div>

<div class="card card-primary-bottom box-shadow"
     *ngIf="download_report">
    <div class="card-body card-body-lg p-2">
        <div>
            <h3 class="h4 heading-regular mb-4 mb-md-6 mb-lg-8">{{'downloadReport' | translate}}</h3>
            <p class="card-text mt-3">{{'downloadReportDesc' | translate}}</p>
            <div *ngIf="isLoading"
                 class="loader">
                <mat-progress-spinner color="primary"
                                      mode="indeterminate"
                                      diameter="50">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</div>