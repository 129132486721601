<div class="intro">
    <fa-icon *ngIf="iconName"
             [icon]="[iconPrefix, iconName]"
             size="2x">
    </fa-icon>
    <p class="h4"
       *ngIf="headerText">{{headerText}}</p>
    <p class="pb-1 mb-0"
       *ngIf="content">
        {{content}}
    </p>
</div>